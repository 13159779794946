import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { selectDate } from '../../redux/actions/actions_misc';





class DatePickerCreater extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedDate: moment().toDate(),
            page: null,
            isFutureDateAllowed: false
        };
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    componentDidMount() {
        const date = moment().toDate();
        this.props.selectDate(date, 'history');
        if (this.props.page === "modal") this.setState({ isFutureDateAllowed: true });
    }

    onChangeDate(date) {

        this.setState({
            selectedDate: date
        });

        switch (this.props.page) {
            case "report_page":
                this.props.selectDate(date, 'history');
                break;
            case "alarm_page":
                this.props.selectDate(date, 'alarm');
                break;
            case 'modal':
                this.props.selectDate(date, 'papers');
                break;
            default:
                this.props.selectDate(date, 'history');
        }
    }



    render() {
        if (this.state.isFutureDateAllowed) {
            return (
                <DatePicker
                    dateFormat="yyyy/MM/dd"
                    selected={this.state.selectedDate}
                    onChange={this.onChangeDate}
                    className="date-picker-modal"
                />
            )
        } else {
            return (


                <div className="datepicker-container">

                    <div className="dropdown-basic-title-data">

                        <div className="dropdown-basic-title"> Date </div>
                        <DatePicker
                            dateFormat="yyyy/MM/dd"
                            selected={this.state.selectedDate}
                            onChange={this.onChangeDate}
                            className="date-picker"
                            maxDate={new Date()}
                        />
                    </div>
                    <div className="dropdown-basic-icon-container">
                        <i style={{ color: "#0079bf" }} className="icon-action-event" />
                    </div>
                </div>


            )
        }

    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectDate }, dispatch);
}

export default connect(null, mapDispatchToProps)(DatePickerCreater);