import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchAllResources, clearSearchResources, } from '../../redux/actions/actions_resources';


class SearchBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			term: '',
			placeHolder: 'Search for vehicles or id etc',
			location: "home",

		}
		this.onInputChange = this.onInputChange.bind(this);
	}

	onInputChange(event) {
		this.setState({ term: event.target.value });
		const searchTerm = event.target.value;
		if (searchTerm.length > 0) {
			if (this.state.location === "home") {
				this.props.searchAllResources(searchTerm);
			}
		} else {
			this.props.clearSearchResources();
		}
	}

	componentDidMount() {
		switch (this.props.location) {
			case "home":
				this.setState({
					placeHolder: "Search for Vehicles by Name",
					location: "home"
				});
				break;
			default: 
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.trackedResourceId) {
			if (nextProps.singleResourceLocation) {
				this.setState({
					term: nextProps.singleResourceLocation.title
				});
			}
		}
	}

	render() {

		return (
			<form className="navbar-form" role="search">
				<div style={{ fontSize: "22px" }} className="input-search">
					<i className="icon-action-search"></i>
					<input
						className="form-controll"
						placeholder={this.state.placeHolder}
						name="search"
						value={this.state.term}
						id="srch-term"
						onChange={this.onInputChange}
						type="text" />
					<i className="icon-navigation-expand-more"></i>
				</div>
			</form>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ clearSearchResources, searchAllResources }, dispatch);
}

function mapStateToProps({ singleResourceLocation, trackedResourceId }) {
	return { singleResourceLocation, trackedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);