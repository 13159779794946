import { CREATE_NEW_ALARM , GET_AVAILABLE_ALARMS_FOR_USER , SET_SINGLE_ALARM_STATE, GET_ALL_VEHICLES, GET_CURRENT_ALARM_SETTINGS , SELECT_ALARM_TYPE ,GET_SPECIFIC_ALARM_PATH, GET_SPECIFIC_ALARM_DETAIL, GET_SERVER_TIME, GET_CURRENT_ALARM, GET_ALARMS } from '../../assets/constants/action_types';
import { BASE_URL } from '../../assets/constants/app_constants';

import { fetchAndReturnDataAlarm, fetchAndReturnData, patchDataAndReturnResponse, putDataAndReturnResponse } from '.';
import { formatDateForPath } from '../../utils/functions';



export function getSeverityAlarms(severity, searchTerm, date , alarmType) {
    const url = createGetSeverityAlarmsApi(severity, searchTerm, date , alarmType);
    return fetchAndReturnData(url, GET_ALARMS);
}


export function getAlarmsForNotification(){
    var url = `${BASE_URL}resource/api/alarmevent/`;
    return fetchAndReturnData(url, GET_ALARMS);
}



export function getNextSeverityAlarms(url) {
    return fetchAndReturnData(url, GET_ALARMS);
}

export function getDetailsSpecificAlarm(alarm_id) {
    const url = `${BASE_URL}resource/api/alarmevent/${alarm_id}/`;
    return fetchAndReturnData(url, GET_SPECIFIC_ALARM_DETAIL);
}


export function getPathSpecificAlarm(id, time) {
    var normalTime = new Date(time);
    var  from  = normalTime.setMinutes(normalTime.getMinutes() - 5);
    var  to    = normalTime.setMinutes(normalTime.getMinutes() + 10);
    var formatted_from = formatDateForPath( new Date(from), 'alarm');
    var formatted_to = formatDateForPath(new Date(to), 'alarm');

    const url = `${BASE_URL}resource/api/path/?id=${id}&from=${formatted_from}&to=${formatted_to}`;
    console.log('called for alarm path ' , url);
    return fetchAndReturnData(url, GET_SPECIFIC_ALARM_PATH);

}


export function getServerTime() {
    const url = createGetCurrentServerTimeApi();
    return fetchAndReturnData(url, GET_SERVER_TIME);
}

export function getCurrentAlarm(currentTime) {
    const url = createGetCurrentAlarmsAPi(currentTime);
    return fetchAndReturnData(url, GET_CURRENT_ALARM);
}

export function selectAlarmType(alarmType){
    return {
        type: SELECT_ALARM_TYPE , 
        payload : alarmType
    }
}


export function createGetSeverityAlarmsApi(severity, resourceId,  date , alarmType ) {

    var url = `${BASE_URL}resource/api/alarmevent/?resource_id=${resourceId}`;
    if(alarmType && alarmType !== "-1") url = `${url}&alarm_type=${alarmType}`
   
    if (date) {
        var mon = date.getUTCMonth() + 1;
        var year = date.getUTCFullYear();
        var day = date.getUTCDate();
        if(mon<10)mon=`0${mon}`
        if(day<10)day=`0${day}`
        var formattedDate = `${day}-${mon}-${year}`;
        url = url + `&after_date=${formattedDate}`;
    }
    console.log('alarm url ' , url);
    return url;
}


export function getAvailableAlarmsForUser(resource_id) {
    const url = `${BASE_URL}billing/api/alarms/?resource_id=${resource_id}`;
    return fetchAndReturnData(url, GET_AVAILABLE_ALARMS_FOR_USER);
}

export function getCurrentAlarmStates(alarm_id) {
    const url = `${BASE_URL}resource/api/alarm/?alarm_type=${alarm_id}`;
    return fetchAndReturnDataAlarm(url, GET_CURRENT_ALARM_SETTINGS, alarm_id);
}

export function setAlarmState(alarm_type, status, threshold, severity, resource_id) {

    var url = `${BASE_URL}resource/api/alarm/update?alarm_type=${alarm_type}`;

    if (status) url = url + `&status=${status}`;
    if (threshold) url = url + `&threshold=${threshold}`;
    if (severity) url = url + `&severity=${severity}`;
    if (resource_id) url = url + `&resource_id=${resource_id}`;

    return patchDataAndReturnResponse(url, SET_SINGLE_ALARM_STATE);
}


export function creteNewAlarm(data){
    var url = `${BASE_URL}resource/api/alarm/`;
    return putDataAndReturnResponse(url , data , CREATE_NEW_ALARM )
}


export function getAllVehicles() {
    const url = `${BASE_URL}resource/api/getallvehicles/`;
    return fetchAndReturnData(url, GET_ALL_VEHICLES);
}







export function createGetCurrentServerTimeApi() {
    const url = `${BASE_URL}resource/api/get_server_time/`;
    return url;

}

export function createGetCurrentAlarmsAPi(serverTime) {
    const url = `${BASE_URL}resource/api/alarmevent/?cur_time=${serverTime}`;
    return url;
}





