import React, { Component } from 'react';
import { connect } from 'react-redux';
import { } from '../../redux/actions/actions_auth';
import { bindActionCreators } from 'redux';
import { setTopbarTitle } from '../../redux/actions/actions_misc';
import BillingTable from '../tables/table_billiing';


class BillingPage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            total_due: 0,
            total_paid: 0,
            total_payable: 0
        };
    }

    componentDidMount() {
        this.props.setTopbarTitle("Billing");
    }

    componentWillReceiveProps(nextProps) {


        if (nextProps.allBills) {


            const tableData = nextProps.allBills;
            var [total_due,total_paid,total_payable] = [0,0,0]
            var singleData = null;


            for(var i=0;i<tableData.results.length ; i++){
                singleData = tableData.results;
                total_payable = total_payable + singleData[i].bill_amount;
                if(singleData[i].status_text !== "PAID")total_due = total_due + singleData[i].due_amount;
            }
            
            total_paid = total_payable - total_due;
            this.setState({total_due,total_paid,total_payable });


        }
    }


    render() {

        return (
            <div className="billing-page-container">


                <div className="billing-page-topbar">
                    <div></div>

                    <SummaryItem title="Due Amount" class="text-red" amount={this.state.total_due} />
                    <SummaryItem title="Total Payable Till Now" class="text-black" amount={this.state.total_payable} />
                    <SummaryItem title="Total Paid Till Now" class="text-green" amount={this.state.total_paid} />

                    <div></div>
                </div>

                <div className="billing-page-mainbar">
                    <BillingTable />
                </div>
            </div>
        )
    }
}


function SummaryItem(props) {
    return (
        <div className="payment-summary-container">
            <div className="payment-summary-title">
                {props.title}
            </div>
            <div className="payment-summary-amount">
                <div className={props.class}> <i className="icon-editor-attach-money-bdt" /> {props.amount} </div>
            </div>
        </div>
    )

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTopbarTitle  }, dispatch);
}

function mapStateToProps({ allBills }) {
    return { allBills };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage)
