import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getAllBills, createSslPayment } from '../../redux/actions/actions_auth';
import { setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import car from '../../assets/icons/car_icon.png'


class BillingTable extends Component {



    componentDidMount() {
        this.props.getAllBills();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.createSSLResponse) {
            const status = nextProps.createSSLResponse.status;
            if (status) {
                this.props.setLoadingSpinnerState(false, "Loading ...");
                window.open(nextProps.createSSLResponse.response.GatewayPageURL, "_blank");
            } else {
                this.props.setLoadingSpinnerState(false, "Loading ...");
            }
        }

    }


    payWithSSL(id, amount) {
        const data = new FormData();
        data.append("bill_id", id);
        data.append("bill_amount", amount);
        this.props.createSslPayment(data);
        this.props.setLoadingSpinnerState(true, "Loading ...");
    }

    renderTableData() {

        if (this.props.allBills) {
            const tableData = this.props.allBills.results;
            
            return tableData.map(singleData => (

                <tr key={singleData.id} className="billing-table-row">
                    <td style={{color:"#0079bf"}} component="th">
                        INV - {singleData.id}
                    </td>

                    <td align="center"><i  className="icon-editor-attach-money-bdt"/> {singleData.due_amount}</td>



                    {singleData.status === 1 ?
                        <td align="center">  <div style={{ color: `#dc143c`, background: `#dc143c1A` }} className="due-status"> {singleData.status_text} </div></td> :
                        <td align="center">  <div style={{ color: `#0079bf`, background: `#0079bf1A` }} className="due-status"> {singleData.status_text} </div></td>
                    }

                    <td align="center">{singleData.bill_type_text}</td>
                    {singleData.period ? <td align="center">{ (singleData.period.substring(0, 10)).split("").reverse().join("")}</td> : <td align="center">-</td>}

                    <td style={{ display: "grid", gridTemplateColumns: "auto 1fr", gridColumnGap: "10px" }} className="text-black">
                        <img alt ="temp" style={{ height: "30px", width: "15px", margin: "0px ,10px" }} src={car} />  {`   ${singleData.resource_title}`}
                    </td>


                    {singleData.status === 1 ?
                        <td align="center">
                            <button className="btn-pay" onClick={() => this.payWithSSL(singleData.id, singleData.bill_amount)} >PAY</button>
                        </td> : <td>
                        {/* <button className="btn-pay" >VIEW</button> */}
                        </td>
                    }

                </tr>
            ))
        }
        return <td></td>


    }


    render() {
        return (


            <div className="table-bill">
                <table className="table table-hover">
                    <thead >
                        <tr >
                            <th>   Invoice No</th>
                            <th>   Amount</th>
                            <th style={{textAlign:"center"}}>   Status </th>
                            <th>   Type   </th>
                            <th>   Due Date </th>
                            <th>   Vehicle  </th>
                            <th>   Actions  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </div>


        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAllBills, createSslPayment, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ allBills, createSSLResponse }) {
    return { allBills, createSSLResponse };
}

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillingTable));
export default connect(mapStateToProps, mapDispatchToProps)(BillingTable);