import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalStateShareLocation, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { createFuelEntry } from '../../redux/actions/actions_resources';
import '../../assets/styles/input_boxes.scss';


class ModalShareLocation extends Component {



    constructor(props) {
        super(props);
        this.state = { copySuccess: '' }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedResourceId) {
            this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id });
        }

    }


    copyToClipboard = (e) => {
        this.textArea.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccess: 'Copied!' });
    };


    render() {
        return (
            <div className="modal display-block">
                <div className="modal-locationshare-container">
                    <div className="modal-title-cross">
                        <div className="modal-title">
                            Location Sharing Link
                           </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateShareLocation(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>


                    <div className="modal-location-share-url">
                        <form>
                            <input style={{
                                border: "1px solid #eee",
                                padding: "10px",
                                fontSize: "14px",
                                outline: "0",
                                width: " 100%"
                            }}
                                ref={(textarea) => this.textArea = textarea}
                                value={this.props.sharableLocationUrl}
                            />
                        </form>
                        {
                            document.queryCommandSupported('copy') &&
                            <div style={{ display:"grid" , alignItems:"center" , justifyItems:"center" , fontSize:"28px"}}>
                                <button onClick={this.copyToClipboard}><i className="icon-maps-layers"/></button>
                                <div style={{fontSize:"8px"}}> {this.state.copySuccess} </div>
                            </div>
                        }

                    </div>
                </div >
            </div >

        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoadingSpinnerState, setModalStateShareLocation, createFuelEntry }, dispatch);
}

function mapStateToProps({
    selectedResourceId,
    createFuelEntryResponse,
    sharableLocationUrl

}) {
    return {
        selectedResourceId,
        createFuelEntryResponse,
        sharableLocationUrl
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalShareLocation);