import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getNextSeverityAlarms, getSeverityAlarms, getDetailsSpecificAlarm, getPathSpecificAlarm } from '../../redux/actions/actions_alarm';
import { setModalStateAlarmReplay, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { selectResourceId } from '../../redux/actions/actions_resources';

import { getTimeAgoFromUtcTime } from '../../utils/functions';
import Loader from 'react-loader-spinner';
import car from '../../assets/icons/car_icon.png'

class AlarmTable extends Component {


	constructor(props) {
		super(props);
		this.state = {
			selectedDate: null,
			alarmResults: [],
			nextAlarms: null,
			selectedResourceId: null,
			selectedAlarmType: null
		};
	}



	onReplayClicked(singleData) {
		this.props.setModalStateAlarmReplay(true);
		this.props.getDetailsSpecificAlarm(singleData.id, singleData.iso_time);
		this.props.selectResourceId(singleData.tracker, "alarm_replay");
	}




	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedDate && this.state.selectedDate !== nextProps.selectedDate.date) {
			this.setState({ selectedDate: nextProps.selectedDate.date }, () => this.getAlarms())
		}

		if (nextProps.alarms.results) {
			this.props.setLoadingSpinnerState(false);
			if (nextProps.alarms.results.length > 0 && nextProps.alarms.results !== this.state.alarmResults) {
				// var tempArr = this.state.alarmResults.concat(nextProps.alarms.results)
				// this.setState({ alarmResults: tempArr, next: nextProps.alarms.next })
				this.setState({ alarmResults: nextProps.alarms.results, next: nextProps.alarms.next })
			}
		}

		if (nextProps.selectedResourceId && nextProps.selectedResourceId.resource_id !== this.state.selectedResourceId) {
			if (nextProps.selectedResourceId.intention !== "alarm_replay") {
				this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id }, () => this.getAlarms())
			}
		}

		

		if (nextProps.selectedAlarmType && nextProps.selectedAlarmType !== this.state.selectedAlarmType) {
			this.setState({ selectedAlarmType: nextProps.selectedAlarmType }, () => this.getAlarms());
		}
	}


	getAlarms() {
		this.setState({ alarmResults: [] }, () => {
			this.props.setLoadingSpinnerState(true);
			if (this.state.selectedResourceId) this.props.getSeverityAlarms(null, this.state.selectedResourceId, this.state.selectedDate, this.state.selectedAlarmType);
		})
	}


	getSeverity(severity) {
		switch (severity) {
			case 1:
				return <td className="text-red"><b>  Emergency  </b></td>
			case 2:
				return <td className="text-blue"><b>   Security </b></td>
			case 3:
				return <td className="text-orange"><b>  Major  </b></td>
			case 4:
				return <td className="text-yellow"><b>  Minor  </b></td>
			default:
				return <td className="text-red"><b>  Emergency  </b></td>

		}

	}

	renderTableData() {
		const tableData = this.state.alarmResults;
		if (tableData.length > 0) {
			var arr = []
			var i = 0;
			tableData.map(singleData => (
				arr.push(
					<tr key={i++} className="billing-table-row">

						<td style={{ display: "grid", gridTemplateColumns: "auto 1fr", gridColumnGap: "10px"  , borderRight: "1px solid #37474f1A"}} className="text-black">
							<img alt ="none" style={{ height: "30px", width: "15px", margin: "0px ,10px" }} src={car} />  {`   ${singleData.title}`}
						</td>
						<td className="text-blue">
							{singleData.type_text}
						</td>
						{/* {this.getSeverity(singleData.severity)} */}
						<td >
							{getTimeAgoFromUtcTime(singleData.iso_time)}
						</td>

						<td style={{ display: "grid", justifyContent: "center"  , alignItems:"start"}} className="center-td">
							<button onClick={() => this.onReplayClicked(singleData)} ><i className="icon-social-visibility text-blue replay-icon" /></button>
						</td>
					</tr>)
			))
			if (this.state.next) arr.push(<tr key={999}>
				<td colSpan={5}>
					<button className="btn-load-more" onClick={() => { this.props.getNextSeverityAlarms(this.state.next); this.props.setLoadingSpinnerState(true); }}> load more </button>
				</td>
			</tr>)
			return arr;
		} else {
			return <tr>
				<td className="table-item-no-data" colSpan={5}> No Data Found </td>
			</tr>
		}

	}



	render() {
		if (this.state.alarmResults) {
			return (
				<div className="table-alarm">
					<table className="table table-hover">
						<thead >
							<tr >
								<th style={{borderRight: "1px solid #37474f1A"}}>  Vehicle   </th>
								<th>  Title     </th>
								{/* <th>  Severity   </th> */}
								<th>  Time      </th>
								<th style={{ display: "grid", justifyContent: "center" }}>  View Replay   </th>
							</tr>
						</thead>
						<tbody  >
							{this.renderTableData()}
						</tbody>
					</table>
				</div>
			)
		} else {
			return (
				<div className="loader-spinner-anywhere" >
					<Loader
						type="Puff"
						color="#00BFFF"
						height="100"
						width="100"
					/>
				</div >
			)
		}
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setLoadingSpinnerState, getNextSeverityAlarms, getSeverityAlarms, selectResourceId, getDetailsSpecificAlarm, setModalStateAlarmReplay, getPathSpecificAlarm }, dispatch);
}

function mapStateToProps({ selectedResourceId, selectedAlarmType, selectedDate, alarms }) {

	return { selectedResourceId, selectedAlarmType, selectedDate, alarms };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmTable);