import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkDateGap, formatDate } from '../../utils/functions';



import MaintenanceTable from '../tables/table_maintenance';

import { getAllResources, selectPaper } from '../../redux/actions/actions_resources';
import { getAllVehicles } from '../../redux/actions/actions_resources';
import { setTopbarTitle, setLoadingSpinnerState, setModalStateMaintenanceEntry, setModalStateVehiclePaperView } from '../../redux/actions/actions_misc';
import DropDownResources from '../dropdowns/dropdown_resources';
class PapersPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resources: null,
            vehicles: null,
            selectedResourceId: null
        };
    }


    componentDidMount() {
        this.props.setTopbarTitle("Maintenance");
        this.props.getAllResources();
        this.props.getAllVehicles();
        this.props.setLoadingSpinnerState(true, "getting vehicle details ");
    }

    componentWillReceiveProps(nextProps) {
        nextProps.ownedResources ? this.setState({ resources: nextProps.ownedResources }) : console.log('resources data not available yet')
        nextProps.allVehicles ? this.setState({ vehicles: nextProps.allVehicles }) : console.log('vehicles data not available yet')
        if (nextProps.selectedResourceId) this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id });
    }



    renderVehiclePaperCards() {
        if (this.state.selectedResourceId && this.state.vehicles[this.state.selectedResourceId]) {
            this.props.setLoadingSpinnerState(false, "maintenane table data loading finished");
            return (
                <div className="paperinfo-cards-container">
                    <PaperCard data={this.state.vehicles[this.state.selectedResourceId]} paper={this.state.vehicles[this.state.selectedResourceId].fitness_date} title="Fitness" functions={this.props} />
                    <PaperCard data={this.state.vehicles[this.state.selectedResourceId]} paper={this.state.vehicles[this.state.selectedResourceId].insurance_date} title="Insurance" functions={this.props} />
                    <PaperCard data={this.state.vehicles[this.state.selectedResourceId]} paper={this.state.vehicles[this.state.selectedResourceId].tax_token_date} title="Tax Token" functions={this.props} />
                    <PaperCard data={this.state.vehicles[this.state.selectedResourceId]} paper={this.state.vehicles[this.state.selectedResourceId].reg_date} title="Registration" functions={this.props} />
                </div>
            )
        }
    }


    render() {
        return (

            <div className="papers-page-container">

                <div className="papers-page-topbar">
                    <DropDownResources />
                </div>

                <div>
                    {this.state.vehicles && this.state.selectedResourceId ? this.renderVehiclePaperCards() : <div></div>}
                </div>

                <div className="tablename-btn-container">
                    <div className="header-text"> Maintenance Log</div>

                    <div></div>
                    <div className="btn-icon-txt-blue-filled" onClick={() => this.props.setModalStateMaintenanceEntry(true)}>
                        <i className="icon-content-add" />
                        <div> Maintenance Entry</div>
                    </div>

                </div>

                <div className="papers-page-mainbar">
                    <MaintenanceTable />
                </div>
            </div>


        )
    }
}


function PaperCard(props) {
    if (props.paper) {

        var day = parseInt(props.paper.substring(8, 10))
        var month = parseInt(props.paper.substring(5, 7))
        var year = parseInt(props.paper.substring(0, 4))

        var gap = checkDateGap(parseInt(props.paper.substring(8, 10)),
            parseInt(props.paper.substring(5, 7)),
            parseInt(props.paper.substring(0, 4)));


        var className = "valid-paper";
        var textStatus = "Valid";
        var iconName = "icon-action-verified-user";
        var dateColor = "text-green"


        if (gap < 10) {
            if (gap > 0) {
                className = "warning-paper";
                textStatus = `Expires in ${gap} days`;
                iconName = 'icon-action-info';
                dateColor = "text-orange";
            } else {
                className = "expired-paper";
                textStatus = "Expired";
                iconName = "icon-content-report-gmailerrorred";
                dateColor = "text-red";
            }
        }




        return (
            <div className="paperinfo-cards">
                <div className="paperinfo-title">
                    <h4>{props.title}</h4>
                </div>

                <div className="paperinfo-date">
                    <p>Expiry Date </p> <p className={dateColor}> {formatDate(day, month, year)} </p>
                </div>

                <div className={className}>
                    <div><i className={iconName} /></div>
                    <div>{textStatus}</div>
                </div>

                <div className="papercard-button-group">
                    <div className="btn-icon-txt-white-outlined-left" onClick={() => { props.functions.setModalStateVehiclePaperView(true); props.functions.selectPaper({ paperName: props.title, mode: "view" }) }}>
                        <i className="icon-content-sort" />
                        <div> Details </div>
                    </div>

                    <div className="btn-icon-txt-white-outlined-right" onClick={() => { props.functions.setModalStateVehiclePaperView(true); props.functions.selectPaper({ paperName: props.title, mode: "edit" }) }} >
                        <i className="icon-image-edit" />
                        <div> Update </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="paperinfo-cards-nodata">
                <div className="paperinfo-title">
                    <h4>{props.title}</h4>
                </div>
                <p className="text-red"> No Data Avialable </p>
                <div className="papercard-button-group">
                    <div className="btn-icon-txt-white-outlined-left" onClick={() => { props.functions.setModalStateVehiclePaperView(true); props.functions.selectPaper({ paperName: props.title, mode: "view" }) }}>
                        <i className="icon-content-sort" />
                        <div> Details </div>
                    </div>

                    <div className="btn-icon-txt-white-outlined-right" onClick={() => { props.functions.setModalStateVehiclePaperView(true); props.functions.selectPaper({ paperName: props.title, mode: "edit" }) }} >
                        <i className="icon-image-edit" />
                        <div> Update </div>
                    </div>
                </div>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTopbarTitle, selectPaper, setModalStateVehiclePaperView, setModalStateMaintenanceEntry, getAllResources, getAllVehicles, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ ownedResources, allVehicles, selectedResourceId }) {
    return { ownedResources, allVehicles, selectedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(PapersPage);