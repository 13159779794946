import React, { Component } from 'react';
import dingigl from 'dingi-gl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalStateAlarmReplay, getReversedAddressFromLatLng, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { getPathSpecificAlarm } from '../../redux/actions/actions_alarm';

import { getTimeAgoFromUtcTime } from '../../utils/functions'
import _ from 'lodash';

import { setupMap, calculateFitBounds, fitBoundMap } from '../maps/map_service';
import { showTrailingPath, hideTrailingPath } from '../maps/path_manager';
import { showSingleTrackedVehicle } from '../maps/icon_manager';

var dynamicCoOrdinatesArr = []




class ModalAlarmReplay extends Component {

    constructor(props) {
        super(props);
        this.state = {

            selectedResourceId: null,
            singleAlarmDetails: null,

            replayLocationArray: [],
            replayLocationArrayLength: 1,

            alarmPathIndex: -1,
            playStatus: false,

            playBtnText: "Play",
            playBtnIcon: "icon-av-play",
            playBtnColor: "text-blue",

            reverseGeocodedAddress: "",


            min_lon: 90.01,
            max_lon: 90.03,
            min_lat: 23.0,
            max_lat: 23.03
        };
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillUnmount() { clearInterval(this.interval); }
    componentDidMount() { setupMap(this); }
    handleClose() { this.props.setModalStateAlarmReplay(false); }


    addMarker(locationCoOrdinates) {
        var marker = new dingigl.Marker().setLngLat(locationCoOrdinates)
        marker.addTo(this.map);
    }

    componentWillReceiveProps(nextProps) {
        const singleAlarmDetails = nextProps.specificAlarmDetail;
        console.log(singleAlarmDetails)



        if (singleAlarmDetails && this.state.singleAlarmDetails !== singleAlarmDetails) {
            this.setState({ singleAlarmDetails }, () => {
                setupMap(this);
                this.props.getReversedAddressFromLatLng(singleAlarmDetails.lat, singleAlarmDetails.lng);                
                this.addMarker([singleAlarmDetails.lng, singleAlarmDetails.lat]);
            })
        }

        if (nextProps.specificAlarmPath && this.state.selectedResourceId) {
            if (nextProps.specificAlarmPath[this.state.selectedResourceId] && nextProps.specificAlarmPath[this.state.selectedResourceId].length > 0) {
                this.setState({ replayLocationArray: nextProps.specificAlarmPath[this.state.selectedResourceId], replayLocationArrayLength: nextProps.specificAlarmPath[this.state.selectedResourceId].length }, () => {
                    this.setState({ alarmPathIndex: -1 })
                    this.calculateMinLatLngToBoundMap()
                })
            }
        }

        if (nextProps.reverseGeocodedAddress && nextProps.reverseGeocodedAddress !== this.state.reverseGeocodedAddress) {
            this.setState({ reverseGeocodedAddress: nextProps.reverseGeocodedAddress });
        }

        if (nextProps.selectedResourceId && nextProps.selectedResourceId.resource_id !== this.state.selectedResourceId) {
            this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id }, () => {
                this.props.getPathSpecificAlarm(this.state.selectedResourceId, singleAlarmDetails.iso_time);
            });
        }
    }

    calculateMinLatLngToBoundMap() {
        var coOrdinatesArray = []
        _.map(this.state.replayLocationArray, resourceLocation => { coOrdinatesArray.push([resourceLocation.location.lon, resourceLocation.location.lat]) });
        calculateFitBounds(this, coOrdinatesArray);
    }



    showReplay() {


        fitBoundMap(this);

        this.interval = setInterval(() => {
            this.setState({ alarmPathIndex: (this.state.alarmPathIndex + 1) % this.state.replayLocationArrayLength }, () => {

                if (this.state.alarmPathIndex === 0) {
                    dynamicCoOrdinatesArr = [];
                    hideTrailingPath(this);
                }

                if (this.state.alarmPathIndex === this.state.replayLocationArrayLength - 1) {
                    this.pauseReplay()
                } else {
                    var data = this.state.replayLocationArray[this.state.alarmPathIndex];
                    if (this.map.isStyleLoaded()) showSingleTrackedVehicle(this, data);
                    dynamicCoOrdinatesArr.push([data.location.lon, data.location.lat]);
                    if (this.map.isStyleLoaded()) showTrailingPath(this, dynamicCoOrdinatesArr);
                }
            })
        }, 200);
    }

    pauseReplay() {
        if (this.state.playStatus) {
            this.setState({ playStatus: false, playBtnText: "Play", playBtnIcon: "icon-av-play", playBtnColor: "text-blue" }, () => {
                clearInterval(this.interval);
            })
        } else {
            this.setState({ playStatus: true, playBtnText: "Pause", playBtnIcon: "icon-av-pause", playBtnColor: "text-red" }, () => {
                this.showReplay()
            })
        }
    }




    render() {

        const singleAlarmDetails = this.state.singleAlarmDetails;
        var mapNeeded = true;
        if (singleAlarmDetails) {
            var value = null;
            switch (this.props.specificAlarmDetail.type) {
                case 1:
                    value = `${this.props.specificAlarmDetail.value} Km/Hr`;
                    break;
                case 11:
                    value = (this.props.specificAlarmDetail.value === "true") ? "On" : "Off";
                    break;
                case 14:
                    value = this.props.specificAlarmDetail.value ? "Open" : "Close";
                    break;

                case 20:
                    mapNeeded = false;
                    switch (JSON.parse(this.props.specificAlarmDetail.value).paper) {
                        case "tax_token":
                            value = "Tax Token Expiration";
                            break;
                        case "registration":
                            value = "Registration Paper Expiration"
                            break;
                        case "insurance":
                            value = "Insurance Paper Expiration"
                            break;
                        default:
                            value = "Fitness Paper Expiration";
                    }
                    break;
                default: value = null;

            }

            var driver = this.props.specificAlarmDetail.driver ? this.props.specificAlarmDetail.driver : "No driver assigned"
            return (
                <div className="modal">


                    <div className="map-modal">
                        <div id="container" ref={el => (this.mapContainer = el)} className="map-alarm-replay absolute top right left bottom" >

                            <div className="modal-replay-details">
                                <div className="alarm-replay-info">

                                    <AlarmTitleCross severity={this.props.specificAlarmDetail.severity_text} time={getTimeAgoFromUtcTime(this.props.specificAlarmDetail.iso_time)} onClose={this.handleClose} />
                                    <TypeValue value={value} type={this.props.specificAlarmDetail.type_text} />
                                    <Type title={this.props.specificAlarmDetail.title} />
                                    <License license={this.props.specificAlarmDetail.license} />
                                    <Driver driver={driver} />
                                    {mapNeeded ? <Location title={`Near ${this.state.reverseGeocodedAddress}`} /> : <Location title="No location Data available" />}
                                    <AlarmImage image={this.props.specificAlarmDetail.image_url} />


                                </div>

                                {mapNeeded ? <div className={`btn-replay-play-pause ${this.state.playBtnColor}`} onClick={() => this.pauseReplay()}> <i className={this.state.playBtnIcon} /> {this.state.playBtnText} </div>
                                    : <div></div>}

                            </div>
                        </div>

                    </div>
                </div>
            );
        }
        return (
            <div className="modal display-block">
                <div className="modal-replay-details">
                    <button onClick={this.handleClose}>close</button>
                </div>

                <div className="map-modal">
                    <div id="container" ref={el => (this.mapContainer = el)} className="map absolute top right left bottom" >
                    </div>
                </div>
            </div>
        );
    }
}


function AlarmImage(props) {
    if (props.image) {
        return <img alt ="none" className="alarm-replay-image" src={props.image} />
    }
    return <div></div>
}


function AlarmTitleCross(props) {
    return (
        <div className="alarm-replay-title-time-cross">
            <div className="alarm-replay-title">
                <i className="icon-av-stop" /> {props.severity}
            </div>
            <div className="alarm-replay-time">
                {props.time}
            </div>

            <button className="alarm-replay-close" onClick={props.onClose}><i className="icon-navigation-close" /></button>
        </div>
    )
}



function Location(props) {
    return (
        <div className="alarm-replay-vehicletitle">
            <i className="icon-maps-place" />   {props.title}
        </div>
    )
}

function Type(props) {
    return (
        <div className="alarm-replay-vehicletitle">
            <i className="icon-maps-car" />  {props.title}
        </div>
    )
}

function License(props) {
    return (
        <div className="alarm-replay-license">
            <i className="icon-maps-car" /> {props.license}
        </div>
    )
}

function Driver(props) {
    return (
        <div className="alarm-replay-driver">
            <i className="icon-social-person-outline" />
            {props.driver}
        </div>
    )
}


function TypeValue(props) {
    return (
        <div className="alarm-replay-type-value">
            <div className="alarm-replay-type">
                {props.type}

            </div>
            {props.value ? <div className="alarm-replay-value">
                ( <b className="text-red"> {props.value} </b>  )
                                 </div> : <div></div>}
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getReversedAddressFromLatLng, setLoadingSpinnerState, getPathSpecificAlarm, setModalStateAlarmReplay }, dispatch);
}

function mapStateToProps({ reverseGeocodedAddress, modalState, specificAlarmDetail, specificAlarmPath, selectedResourceId }) {
    return { reverseGeocodedAddress, modalState, specificAlarmDetail, specificAlarmPath, selectedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlarmReplay);

