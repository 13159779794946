import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getApiReadyDateFromMomentDate } from '../../utils/functions';

import { setModalStateMaintenanceEntry, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { createMaintenanceEntry, createMaintenanceEntryType, getMaintenanceTypes } from '../../redux/actions/actions_resources';

import DatePickerCreater from '../misc/date-picker';
import DropDownMaintenanceTypes from '../dropdowns/dropdown_maintenance_types';




class ModalMaintenanceEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maintenanceType: "",
            description: "",
            cost: "0",
            type: null,
            nextDate: ""
        }
        this.submitData = this.submitData.bind(this);
    }


  

    submitData() {
        if (this.validateData()) {
            var maintenanceData = {
                cost: this.state.cost,
                details: this.state.description,
                next_date: this.state.nextDate,
                type: this.state.type,
                resource: this.state.resource
            }
            this.props.setLoadingSpinnerState(true);
            this.props.createMaintenanceEntry(maintenanceData);
        } else {
            alert("Please select a maintenance type")
        }
    }


    validateData() {
        if (!this.state.type) {
            if (this.state.maintenanceType) {
                this.props.setLoadingSpinnerState(true);
                this.props.createMaintenanceEntryType(this.state.maintenanceType, 60);
            }
            return false;
        }
        else {
            return true;
        }
    }



    componentDidMount() {
        this.props.getMaintenanceTypes();
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.createMaintenanceEntryTypeResponse && this.state.type !== nextProps.createMaintenanceEntryTypeResponse.id) {
            this.setState({ type: nextProps.createMaintenanceEntryTypeResponse.id }, () => {
                this.submitData();
            });
        }

        if (nextProps.createMaintenanceEntryResponse) {
            if (nextProps.createMaintenanceEntryResponse.status === "success") {
                this.setState({
                    description: "",
                    cost: "0"
                });
            }
            this.props.setLoadingSpinnerState(false);
            this.props.setModalStateMaintenanceEntry(false);
        }

        if (nextProps.selectedDate) {
            this.setState({ nextDate: getApiReadyDateFromMomentDate(nextProps.selectedDate.date) });
        }

        if (nextProps.selectedMaintenanceEntryType) {
            this.setState({ type: nextProps.selectedMaintenanceEntryType });
        }

        if (nextProps.selectedResourceId) {
            this.setState({ resource: nextProps.selectedResourceId.resource_id });
        }
    }

    render() {
        return (
            <div className="modal">
                <div className="modal-maintenance-container">
                    <div className="modal-title-cross">
                        <div className="modal-title">
                            Maintenance Entry
                        </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateMaintenanceEntry(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>

                    <div className="dropdown-with-title">
                        <span className="title-over-dropdown"> Select Maintenance Type</span>
                        <div className="dropdown-with-icon-in-modal">
                            <i className="icon-action-settings icon-dropdown-box" />
                            <DropDownMaintenanceTypes page="modal" />
                        </div>
                    </div>


                    <form>

                        <span className="title-over-dropdown">  Or add a new Maintenance entry type</span>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-action-settings icon-text-input-box" />
                            <input className="text-shadow" placeholder="Maintenance Type" type="text" value={this.state.maintenanceType} onChange={(e) => this.setState({ maintenanceType: e.target.value })} />
                        </div>



                        <span className="title-over-dropdown">  Maintenance Details </span>
                        <div className="text-with-icon-in-modal-large">
                            <i className="icon-content-sort icon-text-input-box-large" />
                            <textarea rows="5" className="text-shadow" placeholder="Maintenance Details " type="text" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
                        </div>


                        <span className="title-over-dropdown">  Maintenance cost</span>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-editor-attach-money-bdt icon-text-input-box" />
                            <input className="text-shadow" placeholder="Maintenance Cost" type="text" value={this.state.cost} onChange={(e) => this.setState({ cost: e.target.value })} />
                        </div>
                    </form>


                    <span className="title-over-dropdown"> Next Service Date</span>
                    <div className="dropdown-with-title">
                        <div className="dropdown-with-icon-in-modal">
                            <i className="icon-action-event icon-dropdown-box" />
                            <DatePickerCreater page="modal" />
                        </div>
                    </div>

                    <div className="btn-icon-txt-blue-filled" onClick={this.submitData}>
                        <i className="icon-content-add btn-icon" />
                        <div> ADD</div>
                    </div>

                </div>
               
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoadingSpinnerState, createMaintenanceEntry, createMaintenanceEntryType, getMaintenanceTypes, setModalStateMaintenanceEntry }, dispatch);
}

function mapStateToProps({
    maintenanceEntryModalState,
    maintenanceEntryTypes,
    createMaintenanceEntryTypeResponse,
    selectedDate,
    selectedResourceId,
    selectedMaintenanceEntryType,
    createMaintenanceEntryResponse

}) {
    return {
        maintenanceEntryModalState,
        maintenanceEntryTypes,
        createMaintenanceEntryTypeResponse,
        selectedDate,
        selectedResourceId,
        selectedMaintenanceEntryType,
        createMaintenanceEntryResponse
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMaintenanceEntry);