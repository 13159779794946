import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getSingleResourceHistory,
  setTrendType
} from "../../redux/actions/actions_resources";
import {
  setTopbarTitle,
  setLoadingSpinnerState,
  setModalStateTrendView
} from "../../redux/actions/actions_misc";
import MapReport from "../maps/map_report";
import DatePickerCreater from "../misc/date-picker";
import DropDownResources from "../dropdowns/dropdown_resources";
import { formatDateFromIsoDate } from "../../utils/functions";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReportPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resource_id: null,
      date: null,
      titles: [
        "Distance ",
        "Active Time",
        "Average Speed",
        "Total Stops",
        "Over Speeding",
        "Power Cut",
        "Harsh Driving",
        "Displacement ",
        "Vibration "
      ],
      suffixes: [
        "Km",
        "Minutes",
        "Km",
        "times",
        "times",
        "times",
        "times",
        "times",
        "times"
      ],

      locationDataSet: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedResourceId) {
      if (this.state.resource_id !== nextProps.selectedResourceId.resource_id) {
        this.setState(
          { resource_id: nextProps.selectedResourceId.resource_id },
          () => this.fetchResourceHistory()
        );
      }
    }

    if (this.state.date !== nextProps.selectedDate) {
      this.setState({ date: nextProps.selectedDate }, () =>
        this.fetchResourceHistory()
      );
    }

    if (
      nextProps.historySingleResource &&
      nextProps.historySingleResource !== this.state.historySingleResource
    ) {
      this.setState(
        { historySingleResource: nextProps.historySingleResource },
        () => {
          this.generateLocationDataSet();
        }
      );
      this.props.setLoadingSpinnerState(
        false,
        "fetching report data succesful"
      );
    }
  }

  generateLocationDataSet() {
    var tempArr = [];
    //console.log('report is  ' , this.state.historySingleResource);
    if (
      this.state.historySingleResource.report &&
      this.state.historySingleResource.report.path
    ) {
      var pathData = this.state.historySingleResource.report.path;

      for (var i = 0; i < pathData.features.length; i++) {
        var item = pathData.features[i];
        var dataTemplate = {
          time: item.properties.timestamp,
          lat: item.geometry.coordinates[1],
          lng: item.geometry.coordinates[0]
        };
        tempArr.push(dataTemplate);
      }
    }

    this.setState({ locationDataSet: tempArr });
  }

  componentDidMount() {
    this.props.setTopbarTitle("Report");
  }

  fetchResourceHistory() {
    if (this.state.resource_id && this.state.date) {
      this.props.getSingleResourceHistory(
        this.state.resource_id,
        this.state.date.date
      );
      this.props.setLoadingSpinnerState(true, "fetching report data");
    }
  }

  render() {
    var summary = this.props.historySingleResource.summary;
    var report = this.props.historySingleResource.report;

    var title = "Car";
    var formatDate = "";
    if (this.state.date) {
      formatDate = formatDateFromIsoDate(this.state.date.date);
    }
    if (this.props.ownedResources && this.state.resource_id) {
      title = this.props.ownedResources[this.state.resource_id].title;
    }

    return (
      <div className="report-page-container">
        <div className="report-page-topbar">
          <DropDownResources />
          <DatePickerCreater page="report_page" />
        </div>
        <div className="report-page-mainbar">
          <div className="report-page-mainbar-map">
            <MapReport historyLocations={report ? report.path : null} />
          </div>

          <div className="report-info-container">
            <div className="report-info-topbar">
              <div style={{ fontSize: "20px", margin: "10px" }}>
                {" "}
                <b>Report</b>
              </div>
              <div style={{ fontSize: "12px", margin: "10px" }}>
                {" "}
                for {title} on {formatDate}{" "}
              </div>

              <ExcelFile
                filename={`Report_${title}_${formatDate}`}
                element={
                  <button className="btn-download-report">
                    <i className="icon-action-new-releases" /> Download Location
                    Data
                  </button>
                }
              >
                <ExcelSheet data={this.state.locationDataSet} name="Locations">
                  <ExcelColumn label="Time" value="time" />
                  <ExcelColumn label="Latitude" value="lat" />
                  <ExcelColumn label="Longitude" value="lng" />
                </ExcelSheet>
              </ExcelFile>
            </div>
            <div className="report-info-mainbar">
              <SingleItemReportSummary
                icon="icon-action-range"
                color="text-orange"
                trendType="travelled_distance"
                functions={this.props}
                suffix={this.state.suffixes[0]}
                title={this.state.titles[0]}
                data={summary ? summary.travelled_distance : "N/A"}
              />
              <SingleItemReportSummary
                icon="icon-action-schedule-start"
                color="text-magenta"
                trendType="average_speed"
                functions={this.props}
                suffix={this.state.suffixes[2]}
                title={this.state.titles[2]}
                data={summary ? summary.average_speed : "N/A"}
              />
              <SingleItemReportSummary
                icon="icon-maps-local-gas-station"
                color="text-blue"
                trendType="total_stops"
                functions={this.props}
                suffix={this.state.suffixes[3]}
                title={this.state.titles[3]}
                data={summary ? summary.total_stops : "N/A"}
              />
              <SingleItemReportSummary
                icon="icon-navigation-arrow-up"
                color="text-deep-green"
                trendType="total_overspeeding_alert"
                functions={this.props}
                suffix={this.state.suffixes[4]}
                title={this.state.titles[4]}
                data={summary ? summary.total_overspeeding_alert : "N/A"}
              />
              <SingleItemReportSummary
                icon="icon-action-power-settings-new"
                color="text-black"
                trendType="total_power_cut_alert"
                functions={this.props}
                suffix={this.state.suffixes[5]}
                title={this.state.titles[5]}
                data={summary ? summary.total_power_cut_alert : "N/A"}
              />
              <SingleItemReportSummary
                icon="icon-maps-car-multi"
                color="text-red"
                trendType="total_harsh_driving_alert"
                functions={this.props}
                suffix={this.state.suffixes[6]}
                title={this.state.titles[6]}
                data={summary ? summary.total_harsh_driving_alert : "N/A"}
              />

              <SingleItemReportSummary
                icon="icon-maps-car-vibration"
                color="text-magenta"
                trendType="total_vibration_alert"
                functions={this.props}
                suffix={this.state.suffixes[8]}
                title={this.state.titles[8]}
                data={summary ? summary.total_vibration_alert : "N/A"}
              />
              <SingleItemReportSummary
                icon="icon-action-info"
                color="text-blue"
                trendType="total_displacement_alert"
                functions={this.props}
                suffix={this.state.suffixes[7]}
                title={this.state.titles[7]}
                data={summary ? summary.total_displacement_alert : "N/A"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SingleItemReportSummary(props) {
  var tempClass = "dashboard-item-data " + props.color;
  return (
    <div
      className="report-summary-container"
      onClick={() => {
        props.functions.setModalStateTrendView(true);
        props.functions.setTrendType(props.trendType);
      }}
    >
      <div className="dashboard-item-title">{props.title}</div>

      <div className={tempClass}>
        {" "}
        {props.data} {props.suffix} <i className={props.icon} />{" "}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTopbarTitle,
      getSingleResourceHistory,
      setLoadingSpinnerState,
      setModalStateTrendView,
      setTrendType
    },
    dispatch
  );
}

function mapStateToProps({
  ownedResources,
  selectedResourceId,
  selectedDate,
  historySingleResource
}) {
  return {
    ownedResources,
    selectedResourceId,
    selectedDate,
    historySingleResource
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPage);
