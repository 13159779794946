import axios from 'axios';
import { store } from '../store';
import { API_TOKEN} from '../../assets/constants/app_constants';


axios.interceptors.request.use(function (config) {
    const token = `jwt ${store.getState().jwtToken}`;
    //console.log('found token is ' , token );
    config.headers.Authorization =  token;
    return config;
});


export const fetchAndReturnData = async (url, actionType) => {
    const token = `jwt ${store.getState().jwtToken}`;
    try {
        let response = await axios.get(url, {
            headers: { 'Authorization': token }
        });
        return {
            type: actionType,
            payload: response.data
        };
    } catch (error) {
        if (!error.response) {
            throw error;
        } else {
            throw error;
        }
    }
}


export const fetchDingiApiData = async (url, actionType) => {
    try {
        let response = await axios.get(url, {
            headers: {'x-api-key': API_TOKEN}
        });
        return {
            type: actionType,
            payload: response.data
        };
    } catch (error) {
        if (!error.response) {
            throw error;
        } else {
            throw error;
        }
    }
}



//because this response is special and the ac=rray can be empty so we are using a different parameter
export const fetchAndReturnDataAlarm = async (url, actionType, alarm_id) => {
    const token = `jwt ${store.getState().jwtToken}`;
    try {
        let response = await axios.get(url, {
            headers: { 'Authorization': token }
        });
        return {
            type: actionType,
            payload: {
                data: response.data,
                alarm_id: alarm_id
            }
        };
    } catch (error) {
        if (!error.response) {
            //alert("No Internet");
            throw error;
        } else {
            throw error;
        }
    }
}


export const patchDataAndReturnResponse = async (url, actionType) => {
    const token = `jwt ${store.getState().jwtToken}`;
    try {

        let response = await axios.patch(url, {
            headers: { 'Authorization': token }
        });

        return {
            type: actionType,
            payload: response.status
        };
    } catch (error) {
        if (!error.response) {
            //alert("No Internet");
            throw error;
        } else {
            throw error;
        }
    }
}

export const putAuthDataAndReturnResponse = async (url, data, actionType) => {
    //const token = `jwt ${store.getState().jwtToken}`;
    try {
        let response = await axios.post(url, data, {
            // headers: { 'Authorization': JWT_TOKEN } 
        });
        const response_details = {
            status: "success",
            response: response.data
        }
        return {
            type: actionType,
            payload: response_details
        };
    } catch (error) {
        console.log(url);
        console.log(error);
        if (error.response) {
            const response_details = {
                status: "error",
                response: error.response.data
            }
            return {
                type: actionType,
                payload: response_details
            };
        }

    }
}


export const putDataAndReturnResponse = async (url, data, actionType) => {
    const token = `jwt ${store.getState().jwtToken}`;
    
    try {
        let response = await axios.post(url, data, {
            headers: { 'Authorization': token }
        });
        const response_details = {
            status: "success",
            response: response.data
        }
        return {
            type: actionType,
            payload: response_details
        };
    } catch (error) {
        const response_details = {
            status: "error",
            response: error.response.data
        }
        return {
            type: actionType,
            payload: response_details
        };
    }
}



export const patchDataAndReturnResponseCorrectly = async (url, data, actionType) => {
    const token = `jwt ${store.getState().jwtToken}`;
   
    try {
        let response = await axios.patch(url, data, {
            headers: { 'Authorization': token }
        });
        const response_details = {
            status: "success",
            response: response.data
        }
        return {
            type: actionType,
            payload: response_details
        };
    } catch (error) {
        const response_details = {
            status: "error",
            response: error.response.data
        }
        return {
            type: actionType,
            payload: response_details
        };
    }
}
