import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Chart } from "react-charts";

import DropDownResources from '../dropdowns/dropdown_resources';
import DropdownDaysOption from '../dropdowns/dropdown_days_options';

import { setModalStateTrendView, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { getTrendData } from '../../redux/actions/actions_resources';


class ModalTrendView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedResourceId: null,
            chartData: [],
            trendType: "" ,
            selectedInterval : 7
        }
    }

    componentDidMount() {
        // this.props.getMaintenanceTypes();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedResourceId) {
            if (this.state.selectedResourceId !== nextProps.selectedResourceId.resource_id) {
                this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id }, () => {
                    this.props.getTrendData(this.state.selectedInterval, this.state.selectedResourceId);
                });
            }
        }

        if(nextProps.selectedInterval !== this.state.selectedInterval){
            this.setState({selectedInterval: nextProps.selectedInterval} , () => {
                if(this.state.selectedResourceId)this.props.getTrendData(this.state.selectedInterval, this.state.selectedResourceId);
            });

        }

        if (nextProps.selectedTrendType) {
            switch (nextProps.selectedTrendType) {
                case "travelled_distance":
                    this.setState({ trendType: "Travelled Distance" });
                    break;
                case "average_speed":
                    this.setState({ trendType: "Average Speed" });
                    break;
                case "total_stops":
                    this.setState({ trendType: "Total Stops" });
                    break;
                case "total_overspeeding_alert":
                    this.setState({ trendType: "Total Overspeeding Alarm" });
                    break;
                case "total_power_cut_alerm":
                    this.setState({ trendType: "Total Power Cut Alarm" });
                    break;
                case "total_harsh_driving_alert":
                    this.setState({ trendType: "Total Harsh Driving Alarm" });
                    break;
                default:
            }
        }

        if (nextProps.trendData) {
            var data = []
            for (var i = 0; i < nextProps.trendData[nextProps.selectedTrendType].length; i++) {
                var date = nextProps.trendData[nextProps.selectedTrendType][i][1];
                var d = new Date(date);
                data.push([d, nextProps.trendData[nextProps.selectedTrendType][i][0]])
            }
            this.setState({ chartData: data });
        }
    }

    render() {
        return (
            <div className="modal">

                <div className="modal-trendview-container">

                    <div className="modal-title-cross">
                        <div>
                            {this.state.trendType} in last 7 days
                           </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateTrendView(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>

                    <div className="modal-trend-resource-date">
                        <DropDownResources page="normal" />
                        <DropdownDaysOption page="modal" />
                    </div>

                    <div
                        style={{
                            width: "auto",
                            height: "300px"
                        }}
                    >
                        <Chart
                            data={[{
                                label: " ",
                                data: this.state.chartData

                            }]}
                            series={{ type: 'line' }}
                            axes={[
                                { primary: true, type: "time", position: "bottom" },
                                { type: "linear", position: "left" }
                            ]}
                            //  primaryCursor
                            secondaryCursor
                            tooltip
                        />
                    </div>

                </div>
            </div>
        );
    }


}


function mapDispatchToProps(dispatch) {


    return bindActionCreators({ setLoadingSpinnerState, setModalStateTrendView, getTrendData }, dispatch);
}

function mapStateToProps({
    selectedResourceId, selectedTrendType, trendData ,selectedInterval

}) {
    return {
        selectedResourceId, selectedTrendType, trendData ,selectedInterval
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrendView);