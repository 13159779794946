import dingigl from "dingi-gl";
import { API_TOKEN, ENGLISH_STYLE } from "../../assets/constants/app_constants";
dingigl.apiToken = API_TOKEN;

export const setupMap = Component => {
  var focusPoint = {
    lng: 90.406915,
    lat: 23.852895,
    zoom: 13
  };
  const { lng, lat, zoom } = focusPoint;

  Component.map = new dingigl.Map({
    container: Component.mapContainer,
    style: ENGLISH_STYLE,
    zoom: zoom,
    maxZoom: 16,
    center: [lng, lat],
    attributionControl: false
  });

  Component.map.dragRotate.disable();
  Component.map.touchZoomRotate.disableRotation();
  //Component.maps.on("move", Component.mapMovementHandler);
  // Component.map.addControl(new dingigl.NavigationControl());

  // Component.map.addControl(new dingigl.GeolocateControl({
  //     positionOptions: {
  //         enableHighAccuracy: true
  //     },
  //     trackUserLocation: true,
  //     showUserLocation: true
  // }));

  try {
    Component.map.loadImage("/assets/car_icon.png", (error, car_icon) => {
      if (error) throw error;
      Component.map.addImage("car_icon", car_icon);
    });

    Component.map.loadImage(
      "/assets/car_icon_track.png",
      (error, car_icon_track) => {
        if (error) throw error;
        Component.map.addImage("car_icon_track", car_icon_track);
      }
    );

    Component.map.loadImage("/assets/car_active.png", (error, car_active) => {
      if (error) throw error;
      Component.map.addImage("car_active", car_active);
    });

    Component.map.loadImage(
      "/assets/car_inactive.png",
      (error, car_inactive) => {
        if (error) throw error;
        Component.map.addImage("car_inactive", car_inactive);
      }
    );

    Component.map.loadImage("/assets/car_hault.png", (error, car_hault) => {
      if (error) throw error;
      Component.map.addImage("car_hault", car_hault);
    });
    Component.map.loadImage("/assets/start.png", (error, start) => {
      if (error) throw error;
      Component.map.addImage("start", start);
    });

    Component.map.loadImage("/assets/stop.png", (error, stop) => {
      if (error) throw error;
      Component.map.addImage("stop", stop);
    });

    Component.map.loadImage("/assets/dot.png", (error, dot) => {
      if (error) throw error;
      Component.map.addImage("dot", dot);
    });
    Component.map.loadImage("/assets/up_arrow.png", (error, up_arrow) => {
      if (error) throw error;
      Component.map.addImage("up_arrow", up_arrow);
    });

    Component.map.loadImage("/assets/fuelstation.png", (error, fuelstation) => {
      if (error) throw error;
      Component.map.addImage("fuelstation", fuelstation);
    });
  } catch (error) {}
};

export const addPopupWithMarker = (Component, coOrdinatesArr, popUpTitle) => {
  if (!Component.state.marker) {
    var popup = new dingigl.Popup({ offset: 25 }).setText(popUpTitle);
    var marker = new dingigl.Marker().setLngLat(coOrdinatesArr).setPopup(popup);
    Component.setState({ marker }, () => marker.addTo(Component.map));
  }
};

export const flyToMarker = (Component, location, zoom) => {
  Component.map.flyTo({
    center: location,
    zoom: zoom
  });
};

export const zoomIn = Component => {
  Component.map.flyTo({
    zoom: Component.map.getZoom() + 1
  });
};

export const zoomOut = Component => {
  Component.map.flyTo({
    zoom: Component.map.getZoom() - 1
  });
};

export const showUsersOwnLocation = Component => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(position => {
      addPopupWithMarker(
        Component,
        [position.coords.longitude, position.coords.latitude],
        "Your Location"
      );
      flyToMarker(
        Component,
        [position.coords.longitude, position.coords.latitude],
        16
      );
    });
  }
};

export const getCoOrdinatesArrayFromFeatures = features => {
  var co_ordinates = [];
  for (var i = 0; i < features.length; i++) {
    co_ordinates.push(features[i].geometry.coordinates);
  }
  return co_ordinates;
};

export const removeLayerAndSource = (map, layerId) => {
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
    map.removeSource(layerId);
  }
};

export const calculateFitBounds = (Component, coOrdinatesArr) => {
  var [min_lon, max_lon, min_lat, max_lat] = [120.0, 0.0, 120.0, 0.0];
  coOrdinatesArr.forEach(function(item) {
    if (item[0] !== 0 && item[1] !== 0) {
      min_lon = Math.min(item[0], min_lon);
      max_lon = Math.max(item[0], max_lon);
      min_lat = Math.min(item[1], min_lat);
      max_lat = Math.max(item[1], max_lat);
    }
  });
  Component.setState({ min_lon, max_lon, min_lat, max_lat }, () =>
    fitBoundMap(Component)
  );
};

export const fitBoundMap = Component => {
  if (Component.state.min_lon !== 120) {
    var bbox = [
      [Component.state.min_lon, Component.state.min_lat],
      [Component.state.max_lon, Component.state.max_lat]
    ];
    Component.map.fitBounds(bbox, {
      padding: { top: 25, bottom: 25, left: 25, right: 25 }
    });
  }
};

export function generatePointsBetweenCoOrdinates(
  previousCoOrdinates,
  currentCoOrdinates,
  numberOfCoOrdinates = 35
) {
  var start = { lat: previousCoOrdinates[1], lng: previousCoOrdinates[0] };
  var end = { lat: currentCoOrdinates[1], lng: currentCoOrdinates[0] };
  var n = numberOfCoOrdinates;
  var fractionalCoordinates = [];
  for (var i = n - 1; i > 0; i--) {
    fractionalCoordinates.push([
      (start.lng * i) / n + (end.lng * (n - i)) / n,
      (start.lat * i) / n + (end.lat * (n - i)) / n
    ]);
  }
  return fractionalCoordinates;
}
