import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';



import { getMaintenanceEntrys, selectMaintenanceLog } from '../../redux/actions/actions_resources';
import { setLoadingSpinnerState, setModalStateMaintenanceLogView } from '../../redux/actions/actions_misc';
import { formatDateFromIsoDate } from '../../utils/functions';




class MaintenanceTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            maintenanceEntries: [],
            count: 0,
            selectedResourceId: null
        };
        this.onClickViewLog = this.onClickViewLog.bind(this);
    }


    componentDidMount() {
        //this.props.getMaintenanceEntrys();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.maintenanceEntries && this.state.maintenanceEntries !== nextProps.maintenanceEntries.results) {
            this.setState({
                count: nextProps.maintenanceEntries.count,
                maintenanceEntries: nextProps.maintenanceEntries.results
            });
        }
        if (nextProps.selectedResourceId && nextProps.selectedResourceId.resource_id !== this.state.selectedResourceId) {
            this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id }, () => this.props.getMaintenanceEntrys(this.state.selectedResourceId));
        }
    }

    onClickViewLog(index) {
        this.props.selectMaintenanceLog(this.state.maintenanceEntries[index]);
        this.props.setModalStateMaintenanceLogView(true);
    }



    renderTableData() {
        var arr = [];
        if (this.state.count === 0) {
            return (
                <tr >
                    <td className="table-item-no-data" colSpan={6}> No Data Found </td>
                </tr>
            )
        }
        for (var i = 0; i < this.state.count; i++) {
           
            var details = this.state.maintenanceEntries[i].details;
            var creationDate = this.state.maintenanceEntries[i].date_created;
            var nextDate = this.state.maintenanceEntries[i].next_date;
            var cost = this.state.maintenanceEntries[i].cost;
            arr.push(
                <tr key={i} className="table-head">
                    {/* <td style={{ display: "grid", gridTemplateColumns: "auto 1fr", gridColumnGap: "10px" }} className="text-black">
                        <img style={{ height: "30px", width: "15px", margin: "0px ,10px" }} src={car} />  {`   ${name}`}
                    </td> */}
                    <td className="text-blue" style={{borderRight: "1px solid #37474f1A"}}>
                        {this.state.maintenanceEntries[i].type_text}
                    </td>
                    <td>{formatDateFromIsoDate(creationDate)}</td>
                    <td className="text-red"><i  className="icon-editor-attach-money-bdt"/> {Math.floor(cost)} </td>
                    <td>{nextDate ? formatDateFromIsoDate(nextDate) : "not provided"}</td>
                    <td>{(details === "") ? "Not Available" : details}</td>
                    <td>
                    <i  style={{fontSize:"16px"}} id={i} onClick={(e) => this.onClickViewLog(e.target.id)} className="icon-social-visibility" />

                    </td>
                </tr>
            )
        }
        return arr;
    }


    render() {
        return (
            <div className="table-maintenance">
                <table className="table table-hover">
                    <thead >
                        <tr >
                            {/* <th>   Vehicle</th> */}
                            <th style={{borderRight: "1px solid #37474f1A"}}>   Type</th>
                            <th>   Date</th>
                            <th>   Cost</th>
                            <th>   Next Date </th>
                            <th>   Desciption </th>
                            <th>   </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setModalStateMaintenanceLogView, selectMaintenanceLog, getMaintenanceEntrys, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ maintenanceEntries, selectedResourceId , ownedResources }) {
    return { maintenanceEntries, selectedResourceId , ownedResources };
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceTable);