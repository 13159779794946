import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Switch from "react-switch";
import { setAlarmState, getCurrentAlarmStates, creteNewAlarm } from '../../redux/actions/actions_alarm';

class ToggleSwitch extends Component {

	constructor(props) {
		super(props);
		this.state = {
			status: 2,
			data: null,
			checked: false,
			alarmId: null,
			selectedResourceId: null ,
			orgId: null
		};
		this.handleChange = this.handleChange.bind(this);
	}


	componentDidMount() {
		this.setState({ alarmId: this.props.alarmId, selectedResourceId: this.props.selectedResourceId.resource_id })
	}

	handleChange(checked) {
		this.setState({ checked });
		var resource_id = this.props.selectedResourceId.resource_id;

		//make it resource specific


		if (this.state.data) {
			var newStatus = checked ? 1 : 2;
			this.props.setAlarmState(this.state.alarmId, newStatus, null, null, resource_id);
		} else {
			var newAlarmData = {
				"org": this.state.orgId,
				"alarm_type":this.state.alarmId,
				"severity": 1,
				"resource": this.state.selectedResourceId,
				"threshold": "0",
				"status": 1
			}
			this.props.creteNewAlarm(newAlarmData);
		}

	}


	componentWillReceiveProps(nextProps) {

		if (nextProps.settingAlarmResponse) {
			if(nextProps.settingAlarmResponse === 200){
				//this.props.getCurrentAlarmStates(this.state.alarm_id);
			}
		}

		if (nextProps.userDetails) {
			this.setState({ orgId: nextProps.userDetails.org });

		}

		if (nextProps.currentAlarmState) {
			if (nextProps.currentAlarmState.alarm_type_id === this.props.alarmId) {
				const currentStatus = nextProps.currentAlarmState.alarm_type_status;

				currentStatus === 1 ?
					this.setState({data: nextProps.currentAlarmState.alarm_type_data, checked: true}) :
					this.setState({data: nextProps.currentAlarmState.alarm_type_data, checked: false});

			}
		}
	}

	render() {


		return (

			<label className="toggle-label" htmlFor="normal-switch">
				<Switch
					checked={this.state.checked}
					onChange={this.handleChange}
					onColor="#a1c0c0"
					onHandleColor="#0079bf"
					handleDiameter={18}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={15}
					width={25}
					className="react-switch"
					id="material-switch"
				/>
			</label>
		);

	}
}



function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setAlarmState, getCurrentAlarmStates, creteNewAlarm }, dispatch);
}

function mapStateToProps({ userDetails, settingAlarmResponse, currentAlarmState, selectedResourceId }) {
	return { userDetails, settingAlarmResponse, currentAlarmState, selectedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleSwitch);