export function formatDate(date, intention) {


    var seconds = date.getSeconds();
    var minutes = date.getMinutes();
    var hour = date.getHours();
    var day = date.getUTCDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();


    switch (intention) {
        case "report_from":
            hour = 0;
            minutes = 0;
            seconds = 0;
            break;
        case "report_to":
            hour = 23;
            minutes = 59;
            seconds = 59;
            break;
        case "alarm":
            break;
        default:

    }


    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (hour < 10) {
        hour = `0${hour}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }


    var formatted = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
    return formatted;
}






//get bearing from two coordinates
function toRadians(degrees) {
    return degrees * Math.PI / 180;
};

function toDegrees(radians) {
    return radians * 180 / Math.PI;
}

export function getBearing(startLat, startLng, destLat, destLng) {
    startLat = toRadians(startLat);
    startLng = toRadians(startLng);
    destLat = toRadians(destLat);
    destLng = toRadians(destLng);

    var y = Math.sin(destLng - startLng) * Math.cos(destLat);
    var x = Math.cos(startLat) * Math.sin(destLat) -
        Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    var brng = Math.atan2(y, x);
    brng = toDegrees(brng);
    return (brng + 360) % 360;
}


//minimize array
export function reverseArraysOfArray(arr) {
    for (var i = 0; i < arr.length; i++)arr[i].reverse();
    return arr;
}