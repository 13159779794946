import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonAvatar from '../buttons/button_avatar';
import SearchResultList from '../search/search_result_list';
import SearchBar from '../search/search_bar';
import NotificationBar from './notification_bar';

class TopBar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			location: "",
			title: ""
		};
	}

	componentWillReceiveProps(next) {
		if (next.location) {
			this.setState({
				location: next.location,
				title: next.title
			});
		}
	}

	render() {

		if (this.props.topbarTitle === "DashBoard") {
			return (
				<div className="topbar-container">
					
					<div>
						<SearchBar location="home" />
						{this.props.trackedResourceId ? <div></div> : <SearchResultList />}
					</div>
					<div className="app-topbar-title"> <b>{this.props.topbarTitle}</b></div>
					<NotificationBar />
					<ButtonAvatar />
				</div>
			)
		} else {
			return (
				<div className="topbar-container">
					<div></div>
					<div className="app-topbar-title"> <b>{this.props.topbarTitle}</b></div>
					<NotificationBar />
					<ButtonAvatar />
				</div>
			)
		}



	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

function mapStateToProps({ topbarTitle }) {
	return { topbarTitle };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);