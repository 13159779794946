import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideNavBar from './misc/side_nav_bar';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';

//styles
import './pages/pages.scss';
import '../assets/styles/App.scss';
import './modals/modals.scss';
import './dropdowns/dropdowns.scss';
import './buttons/buttons.scss';
import './search/search.scss';
import './tables/tables.scss';
import './maps/maps.scss';
import './misc/misc.scss';
import '../assets/styles/constants.scss';

//pages
import HomePage from './pages/page_home';
import AlarmPage from './pages/page_alarm';
import AlarmSettings from './pages/page_settings';
import VehiclesPage from './pages/page_vehicles';
import PapersPage from './pages/page_papers';
import ReportPage from './pages/page_report';
import PageTrips from './pages/page_trips';
import LoginPage from './pages/page_login';
import BillingPage from './pages/page_billing';
import ProfilePage from './pages/page_profile';
import FuelPage from './pages/page_fuel';
import SupportPage from './pages/page_support';
import ModalLoading from './modals/modal_loading';
import ModalController from './modals/modal_controller';

import TopBar from './misc/topbar';



import { refreshToken, verifyToken } from '../redux/actions/actions_auth';


import * as ROUTES from '../assets/constants/routes';


class App extends Component {




	componentDidMount() {
		if (this.props.jwtToken) this.props.verifyToken();
		
	}

	componentWillReceiveProps(next) {
		if (next.jwtToken) {
			if (next.isTokenValid) {
				window.location.reload(true);
				this.props.refreshToken();
			}
		}

		console.log(window.location.href);
	}



	render() {
		if (!this.props.jwtToken) {
			return (
				<Router>
					<div className="App">
						<ModalLoading />
						<Switch>
							<Route exact path={ROUTES.DEFAULT} component={withRouter(HomePage)} />
							<Route exact path={ROUTES.SIGN_IN} component={withRouter(LoginPage)} />
						</Switch>
					</div>
				</Router>
			);
		} else {
			return (

				<Router>
					<div className="app-container">

						<div className="app-sidebar">
							<SideNavBar />					</div>
						<div className="app-top-mainbar">
							<div className="app-topbar">
								<TopBar />
							</div>
							<div className="app-mainbar">
								<ModalController />
								<ModalLoading />
								<Switch>
									<Route exact path={ROUTES.DEFAULT} component={withRouter(HomePage)} />
									<Route exact path={ROUTES.SIGN_IN} component={withRouter(LoginPage)} />
									<Route exact path={ROUTES.HOME} component={withRouter(HomePage)} />
									<Route exact path={ROUTES.ALARM} component={withRouter(AlarmPage)} />
									<Route exact path={ROUTES.SETTINGS} component={withRouter(AlarmSettings)} />
									<Route exact path={ROUTES.VEHICLES} component={withRouter(VehiclesPage)} />
									<Route exact path={ROUTES.PAPERS} component={withRouter(PapersPage)} />
									<Route exact path={ROUTES.REPORT} component={withRouter(ReportPage)} />
									<Route exact path={ROUTES.TRIPS} component={withRouter(PageTrips)} />
									<Route exact path={ROUTES.FUEL} component={withRouter(FuelPage)} />
									<Route exact path={ROUTES.BILLING} component={withRouter(BillingPage)} />
									<Route exact path={ROUTES.PROFILE} component={withRouter(ProfilePage)} />
									<Route exact path={ROUTES.SUPPORT} component={withRouter(SupportPage)} />

								</Switch>
							</div>
						</div>

					</div>


				</Router>

			);
		}




	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ refreshToken, verifyToken }, dispatch);
}


function mapStateToProps({ topbarTitle , jwtToken }) {
	return { topbarTitle ,jwtToken }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
