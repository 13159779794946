import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getMaintenanceTypes, selectMaintenanceEntryType } from '../../redux/actions/actions_resources';

import _ from "lodash";


class DropDownMaintenanceTypes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            selectedId: null
        };
        this.onTypeSelected = this.onTypeSelected.bind(this);
    }

    componentDidMount() {
        this.props.getMaintenanceTypes();
    }

    onTypeSelected(e) {
        this.props.selectMaintenanceEntryType(e.target.value);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.maintenanceEntryTypes) {
            this.setState({
                data: nextProps.maintenanceEntryTypes.results
            });
        }
    }

    renderOptions() {

        if (this.state.data) {
            return _.map(this.state.data, singleType => {
                return (
                    <option key={singleType.id} className="dropdown-modal-option" value={singleType.id}>{singleType.name}</option>
                );
            });
        }
    }


    render() {
        return (
            <div className="dropdown-modal-container">
                <select className="dropdown-modal-selection" onChange={this.onTypeSelected}>
                    {this.renderOptions()}
                </select>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectMaintenanceEntryType, getMaintenanceTypes }, dispatch);
}

function mapStateToProps({ maintenanceEntryTypes }) {
    return { maintenanceEntryTypes };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownMaintenanceTypes);