import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {setTripCount} from '../../redux/actions/actions_misc';

class DropDownTripOption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            selectedId: null
        };
        this.onTripCountChange = this.onTripCountChange.bind(this);
    }



    onTripCountChange(e) {
        this.props.setTripCount(e.target.value)
    }



    render() {
        return (
            <div className="dropdown-basic-container">
                <div className="dropdown-basic-icon-container">
                    <i className="icon-action-query-builder"/>
                </div>
                <div className="dropdown-basic-title-data">

                    <div className="dropdown-basic-title"> Show </div>
                    <div className="dropdown-basic-data">
                        <select className="dropdown-basic-selection" onChange={this.onTripCountChange}>
                            <option className="dropdown-basic-option" selected={this.state.security} value={5}>Last 5 Trips</option>
                            <option className="dropdown-basic-option" selected={this.state.emergency} value={15}>Last 15 Trips</option>
                            <option className="dropdown-basic-option" selected={this.state.emergency} value={10}> Sort By Time </option>
                            {/* <option className="dropdown-basic-option" selected={this.state.security} value="time">Filter by Time</option> */}
                        </select>
                    </div>

                </div>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTripCount }, dispatch);
}



export default connect(null, mapDispatchToProps)(DropDownTripOption);