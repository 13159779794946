import React from 'react';

import ReactDOM from 'react-dom';
import './index.scss';
import './components/pages/pages.scss';

import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './redux/store';
import ModalLoading from './components/modals/modal_loading';




ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={<ModalLoading />} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>

	, document.getElementById('root')
);

serviceWorker.unregister();
