import React, { Component } from 'react';
import dingigl from 'dingi-gl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';



import {
	getAllResources,
	seTrackedResourceId,
	getAllResourcesLocation,
	getSingleResourceLocation
} from '../../redux/actions/actions_resources';

import {
	searchFreeText, getNearbyFuelStations, getPathFromSourceDestination,
	seResourceIdForDetailsViewInMap, setModalStateVehicleDetails,
	setLoadingSpinnerState
} from '../../redux/actions/actions_misc';

import {
	calculateFitBounds, fitBoundMap,
	zoomIn, showUsersOwnLocation,
	zoomOut, flyToMarker, setupMap,
} from './map_service';


import {
	showAllVehicles,
	hideAllVehicles,
	showSingleTrackedVehicle,
	hideSingleTrackedVehicle,
	showNearbyFuelStations,
	removeNearbyFuelStations
} from './icon_manager';

import {
	showTrailingPath,
	hideTrailingPath,
	addNavigationPath,
	removeNavigationPath
} from './path_manager';


import { reverseArraysOfArray } from '../../utils/utils';

var dynamicCoOrdinatesArr = []

const mapStates = {
	ALL_VEHICLES_SHOWED: "ALL_VEHICLES_SHOWED",
	SINGLE_VEHICLE_TRACKED: "SINGLE_VEHICLE_TRACKED"
}

class MapHome extends Component {

	constructor(props) {
		super(props);
		this.state = {

			ownedResources: null,
			allResourcesLocation: null,
			nearbyFuelStations: [],
			singleResourceDetails: null,
			popup: null,

			popUpClassName: "details-image",

			trackedVehicleId: null,
			clickedVehicleId: null,
			marker: null,

			min_lon: 90.01,
			max_lon: 90.03,
			min_lat: 23.0,
			max_lat: 23.03,

			navigationPath: false,
			isNavigationPathShown: false,
			navigationFullPathCoOrdinates: [],

			mapState: mapStates.ALL_VEHICLES_SHOWED,
			isStyleLoaded: false,
			periodInterval: 20000

		};

		this.onVehicleClicked = this.onVehicleClicked.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);

	}


	componentWillUnmount() { clearInterval(this.interval) }

	componentDidMount() {
		setupMap(this);
		this.props.setLoadingSpinnerState(true, "home");
		this.props.getAllResources();
		// get data periodically
		this.interval = setInterval(() => { this.props.getAllResourcesLocation() }, this.state.periodInterval);
		this.map.on('style.load', () => { this.setState({ isStyleLoaded: true }, () => this.handleStateChangeOrDataUpdate()) });
	}



	handleStateChangeOrDataUpdate() {
		switch (this.state.mapState) {
			case mapStates.SINGLE_VEHICLE_TRACKED:
				if (this.isAllDataAndMapReady()) {
					var data = this.state.allResourcesLocation[this.state.trackedVehicleId];
					if (data) {
						var locationArr = [data.location.lon, data.location.lat];
						this.setState({ periodInterval: 5000 })
						hideAllVehicles(this);
						showTrailingPath(this, dynamicCoOrdinatesArr);
						dynamicCoOrdinatesArr.push(locationArr);
						showSingleTrackedVehicle(this, data);
						flyToMarker(this, locationArr, 15);
					}
				}
				break;
			case mapStates.ALL_VEHICLES_SHOWED:
				dynamicCoOrdinatesArr = []
				if (this.isAllDataAndMapReady()) {
					this.setState({ periodInterval: 20000 })
					showAllVehicles(this);
					hideSingleTrackedVehicle(this);
					hideTrailingPath(this);
					this.fitBoundMapWithUpdatedData();
				}
				break;
			default:
		}
	}

	isAllDataAndMapReady() {
		if (this.state.allResourcesLocation && this.state.ownedResources && this.state.isStyleLoaded) return true;
		return false;
	}


	onVehicleClicked(event) {
		const id = event.features["0"].properties.id;
		this.setState({ clickedVehicleId: id }, () => {
			this.props.setModalStateVehicleDetails(true);
			this.props.setLoadingSpinnerState(true);
			this.props.seResourceIdForDetailsViewInMap(id);
		});


		var location = event.features["0"].properties.location;
		location = JSON.parse(location);
		flyToMarker(this, location, 16);

		//will be needed in future for navigation and fuel stations
		//this.props.getNearbyFuelStations(location[1], location[0]);
		//this.props.searchFreeText('restu', location[1], location[0]);
	}

	onMouseEnter(event) {
		this.map.getCanvas().style.cursor = "pointer";
		var { title, id } = { ...event.features["0"].properties };
		var data = this.state.allResourcesLocation[id];
		if (data) this.addPopUpWithDetails(data.location, title);
	}

	onMouseLeave() {
		this.map.getCanvas().style.cursor = "";
		const popup = this.state.popup;
		if (popup) {
			popup.remove();
			this.setState({ popup: null });
		}
	}

	addPopUpWithDetails(location, title) {
		if (location && !this.state.popup) {
			let desc = '<div>' + `<button> ${title} </button>` + '</div>';
			var popup = new dingigl.Popup({ closeButton: false, closeOnClick: true }).setLngLat(location)
				.setHTML(desc)
				.addTo(this.map);
			this.setState({ popup: popup });
		}
	}



	componentWillReceiveProps(nextProps) {

		if (nextProps.allResourcesLocation && this.state.allResourcesLocation !== nextProps.allResourcesLocation) {
			this.props.setLoadingSpinnerState(false, "home");
			this.setState({ allResourcesLocation: nextProps.allResourcesLocation }, () => this.handleStateChangeOrDataUpdate());
		}

		if (nextProps.ownedResources && this.state.ownedResources !== nextProps.ownedResources) this.setState({ ownedResources: nextProps.ownedResources });
		if (this.state.trackedVehicleId !== nextProps.trackedResourceId) {
			(nextProps.trackedResourceId) ?
				this.setState({ mapState: mapStates.SINGLE_VEHICLE_TRACKED, trackedVehicleId: nextProps.trackedResourceId }, () => this.handleStateChangeOrDataUpdate()) :
				this.setState({ mapState: mapStates.ALL_VEHICLES_SHOWED, trackedVehicleId: nextProps.trackedResourceId }, () => this.handleStateChangeOrDataUpdate())
		}

		//not needed for now but will be needed in future

		// if (nextProps.visStatePathToResource && this.state.isNavigationPathShown !== nextProps.visStatePathToResource.visibility) this.setState({ isNavigationPathShown: nextProps.visStatePathToResource.visibility });
		// if (nextProps.nearbyFuelStations && this.state.nearbyFuelStations !== nextProps.nearbyFuelStations) this.setState({ nearbyFuelStations: nextProps.nearbyFuelStations })
		// if (nextProps.searchFreeTextResult) console.log(nextProps.searchFreeTextResult); 
		// if (nextProps.navigationPath && nextProps.navigationPath !== this.state.navigationPath) {this.setState({ navigationPath: nextProps.navigationPath }, () => this.addNavigationPathLayer());}

	}

	fitBoundMapWithUpdatedData() {
		var coOrdinatesArray = []
		_.map(this.state.allResourcesLocation, resourceLocation => {
			var location = resourceLocation.location;
			coOrdinatesArray.push([location.lon, location.lat])
		});
		calculateFitBounds(this, coOrdinatesArray);
	}


	//for navigation
	getOrRemoveNavigationPath() {
		if (this.state.isNavigationPathShown) this.getPathFromCurrentLocationToResource();
		else removeNavigationPath(this);
	}


	getPathFromCurrentLocationToResource() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(position => {
				var data = this.state.allResourcesLocation[this.state.clickedVehicleId];
				this.props.getPathFromSourceDestination(position.coords.latitude, position.coords.longitude, data.location.lat, data.location.lon);
			});
		}
	}


	addNavigationPathLayer() {
		let polyline = require('@mapbox/polyline');
		let location_co_ordinates = polyline.decode(this.state.navigationPath.routes[0].geometry, 6);
		calculateFitBounds(this, reverseArraysOfArray(location_co_ordinates));
		addNavigationPath(this, location_co_ordinates);
	}


	//for nearby fuel stations
	showOrRemoveNewarbyFuelStations() {
		if (this.state.trackedVehicleId) {
			showNearbyFuelStations(this);
			this.fitBoundMapWithFuelStations();
		}
		else removeNearbyFuelStations(this);
	}

	fitBoundMapWithFuelStations() {
		var locationArr = [];
		_.map(this.state.nearbyFuelStations, singleFuelStation => {
			if (singleFuelStation.location[0] !== 0 && singleFuelStation.location[1] !== 0) locationArr.push(singleFuelStation.location.reverse());
		})
		calculateFitBounds(this, locationArr);
	}




	render() {
		return (
			<div className="map-home">
				<div id="container" ref={el => (this.mapContainer = el)} className="map absolute top right left bottom" >
				</div>

				{this.props.modalStateVehicleDetails ? <div></div> : <button className="btn-fit-bound" onClick={() => fitBoundMap(this)}>  <i className="icon-maps-car-vibration" /> </button>}
				<div className="btn-group-cntrl-map">
					<div onClick={() => zoomIn(this)} className="btn-zoom-in"> <i className="icon-content-add" /></div>
					<div onClick={() => zoomOut(this)} className="btn-zoom-out"> <i className="icon-action-minimize" /></div>
					<div onClick={() => showUsersOwnLocation(this)} className="btn-my-location"> <i className="icon-maps-my-location" /></div>
				</div>
			</div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ searchFreeText, getNearbyFuelStations, getPathFromSourceDestination, seResourceIdForDetailsViewInMap, setLoadingSpinnerState, setModalStateVehicleDetails, seTrackedResourceId, getAllResourcesLocation, getAllResources, getSingleResourceLocation }, dispatch);
}

function mapStateToProps({ searchFreeTextResult, nearbyFuelStations, visStatePathToResource, navigationPath, modalStateVehicleDetails, ownedResources, allResourcesLocation, trackedResourceId, singleResourceLocation }) {
	return { searchFreeTextResult, nearbyFuelStations, visStatePathToResource, navigationPath, modalStateVehicleDetails, ownedResources, allResourcesLocation, trackedResourceId, singleResourceLocation };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapHome);
