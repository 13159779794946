import {
    SIGN_IN_USER,
    REFRESH_TOKEN,
    SIGN_OUT_USER,
    VERIFY_TOKEN,
    GET_ALL_BILLS,
    PAY_WITH_SSL,
    GET_USER_DETAILS,
    SET_USER_DETAILS,
    GET_ALL_SUPPORT_ENTRY,
    POST_SUPPORT_ENTRY,
    SAVE_CREDENTIALS,
    SET_CREDENTIAL_REMEMBERED_STATE

} from '../../assets/constants/action_types';


const signInResponse = (state = null, action) => {
    switch (action.type) {
        case SIGN_IN_USER:
            switch (action.payload.status) {

                case "error":
                    return {
                        status: false,
                        message: "Unable to Log in with Provided Credentials"
                    }
                case "success":
                    return {
                        status: true,
                        message: "Sign in with your credentials"
                    }
                default:
                    return state;
            }
        case REFRESH_TOKEN:
            switch (action.payload.status) {
                case "error":
                    return {
                        status: false,
                        message: "Access Token is not valid please sign in again"
                    }
                case "success":
                    return {
                        status: true,
                        message: "Sign in with your credentials"
                    }
                default:
                    return state;
            }
        case SIGN_OUT_USER:
            return state;
        default:
            return state;
    }
};


const jwtToken = (state = null, action) => {
    switch (action.type) {
        case SIGN_IN_USER:
            switch (action.payload.status) {
                case "success":
                    return action.payload.response.token;
                default:
                    return null;
            }
        case REFRESH_TOKEN:
            switch (action.payload.status) {
                case "success":
                    return action.payload.response.token;
                default:
                    return null;
            }
        case SIGN_OUT_USER:
            return action.payload;
        default:
            return state;
    }
};


const isTokenValid = (state = null, action) => {
    switch (action.type) {
        case VERIFY_TOKEN:
            switch (action.payload.status) {
                case "success":
                    return true;
                default:
                    return false;
            }
        case SIGN_IN_USER:
            switch (action.payload.status) {
                case "success":
                    return true;
                default:
                    return false;
            }
        case SIGN_OUT_USER:
            return false;
        default:
            return state;
    }
};


const signInCredential = (state = { email: "", password: "" }, action) => {
    switch (action.type) {
        case SAVE_CREDENTIALS:
            return action.payload
        default:
            return state
    }
}



const signInCredentialRememberState = (state = false, action) => {
    switch (action.type) {
        case SET_CREDENTIAL_REMEMBERED_STATE:
            return action.payload
        default:
            return state
    }
}


const allBills = (state = null, action) => {
    switch (action.type) {
        case GET_ALL_BILLS:
            return action.payload;
        default:
            return state;
    }
};




const createSSLResponse = (state = null, action) => {
    switch (action.type) {
        case PAY_WITH_SSL:
            return action.payload;
        default:
            return state;
    }
};


const userDetails = (state = null, action) => {
    switch (action.type) {
        case GET_USER_DETAILS:
            return action.payload;
        default:
            return state;
    }
};



const setUserDetailsResponse = (state = null, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return action.payload;
        default:
            return state;
    }
};

const allIssues = (state = null, action) => {
    switch (action.type) {
        case GET_ALL_SUPPORT_ENTRY:
            return action.payload;
        default:
            return null;
    }
}

const createSupportEntryResponse = (state = null, action) => {
    switch (action.type) {
        case POST_SUPPORT_ENTRY:
            return action.payload;
        default:
            return null;
    }
}

export {
    signInResponse,
    jwtToken,
    isTokenValid,
    signInCredential,
    signInCredentialRememberState,
    allBills,
    createSSLResponse,
    userDetails,
    setUserDetailsResponse,
    allIssues,
    createSupportEntryResponse
};