import {SET_MODAL_STATE_RADIUS_ALARM_SET ,SET_REPLAY_INDEX ,SET_SHARABLE_LOCATION_URL ,SET_MODAL_SHARELOCATION_STATE ,SET_TIME, SELECT_TRIP_COUNT ,SET_TOPBAR_TITLE ,SET_TRIP_REPLAY_STATE ,SEARCH_TEXT ,GET_NEARBY_FUEL_STATIONS ,SET_VISIBILITY_TO_RESOURCE_PATH ,GET_PATH_FROM_SRC_DEST ,SET_RESOURCE_ID_FOR_DETAILS_VIEW_MAP ,GET_REVERSE_GEOCODE_ADDRESS ,SET_MODAL_ISSUE_ENTRY_STATE ,SELECT_DATE_INTERVAL , SET_MODAL_TREND_VIEW_STATE , SET_MODAL_REFUEL_ENTRY_STATE ,SET_VEHICLE_PAPER_VIEW_MODAL_STATE ,SET_MAINTENANCE_LOG_VIEW_MODAL_STATE ,SET_MODAL_MAINTENANCE_ENTRY_STATE ,SET_MODAL_ALARM_REPLAY_STATE ,SELECT_DATE ,SET_MODAL_VEHICLE_STATE ,SET_SPINNER_STATE } from '../../assets/constants/action_types';

import {fetchDingiApiData}  from './index';




export function setLoadingSpinnerState(state , message){
    return {
        type: SET_SPINNER_STATE ,
        payload: {
            state : state , 
            message: message
        }
    };
}


export function setTopbarTitle(title){
    return {
        type: SET_TOPBAR_TITLE ,
        payload : title
    };
}


export function setSharableLocationUrl(url){
    return {
        type: SET_SHARABLE_LOCATION_URL ,
        payload : url
    };
}


export function setModalStateRadiusAlarmSet(state){
    return {
        type: SET_MODAL_STATE_RADIUS_ALARM_SET ,
        payload : state
    };
}


export function setModalStateShareLocation(state){
    return {
        type: SET_MODAL_SHARELOCATION_STATE ,
        payload : state
    };
}



export function setModalStateVehicleDetails(state){
    return {
        type: SET_MODAL_VEHICLE_STATE ,
        payload : state
    };
}

export function setModalStateAlarmReplay(state){
    return {
        type: SET_MODAL_ALARM_REPLAY_STATE ,
        payload : state
    };
}


export function setModalStateMaintenanceEntry(state){
    return {
        type: SET_MODAL_MAINTENANCE_ENTRY_STATE ,
        payload : state
    };
}


export function setModalStateMaintenanceLogView(state){
    return {
        type: SET_MAINTENANCE_LOG_VIEW_MODAL_STATE ,
        payload : state
    };
}

export function setModalStateVehiclePaperView(state){
    return {
        type: SET_VEHICLE_PAPER_VIEW_MODAL_STATE ,
        payload : state
    };
}



export function setModalStateRefuelEntry(state){
    console.log('inside actions   ' , state )
    return {
        type: SET_MODAL_REFUEL_ENTRY_STATE ,
        payload : state
    };
}

export function setModalStateIssueEntry(state){
    return {
        type: SET_MODAL_ISSUE_ENTRY_STATE ,
        payload : state
    };
}



export function setModalStateTrendView(state){
    return {
        type: SET_MODAL_TREND_VIEW_STATE ,
        payload : state
    };
}

export function setTime(time , intention){
    return {
        type: SET_TIME ,
        payload : {
            time , intention
        }
    };
}


export function selectDate(date , intention){
    return {
        type: SELECT_DATE ,
        payload : {
            date , intention
        }
    };
}

export function setTripCount(count){
    return {
        type: SELECT_TRIP_COUNT ,
        payload : count
    };
}


export function selectDateInterval(interval){
    return {
        type: SELECT_DATE_INTERVAL ,
        payload : interval
    };
}

export function seResourceIdForDetailsViewInMap(resourceId){
    return {
        type: SET_RESOURCE_ID_FOR_DETAILS_VIEW_MAP ,
        payload : resourceId
    };
}

export function setReplayStateInTripsFromTripDetails(state){
    return {
        type: SET_TRIP_REPLAY_STATE ,
        payload : state
    };
}

export function setReplayndex(index){
    return {
        type: SET_REPLAY_INDEX ,
        payload : index
    };
}



export function getReversedAddressFromLatLng(lat  , lng ){
    var url =  `https://api.dingi.live/maps/v2/reverse?lat=${lat}&lng=${lng}`;
    return fetchDingiApiData(url , GET_REVERSE_GEOCODE_ADDRESS)
}


export function getPathFromSourceDestination(slat , slng , dlat , dlng , language) {
    const url = `https://api.dingi.live/maps/v2/navigation/driving?steps=true&criteria=shortest&coordinates=${slng}a${slat}b${dlng}a${dlat}&language=${language}`;
    return  fetchDingiApiData(url , GET_PATH_FROM_SRC_DEST);   
}

export function setVisibilityNavigationPathToResource(visibility , resourceId){
    return {
        payload: {visibility , resourceId} ,
        type: SET_VISIBILITY_TO_RESOURCE_PATH
    }
}


export function getNearbyFuelStations( lat, lng,  language="en") {
    const url = `https://api.dingi.live/maps/v2/amenities/?amenity=gas_station&center_lat=${lat}&center_lng=${lng}&radius=10000&language=${language}`;
    return  fetchDingiApiData(url , GET_NEARBY_FUEL_STATIONS);
}


export function searchFreeText(text, center_lat , center_lng, language = "en"){
    const url = `https://api.dingi.live/maps/v2/search/?q=${text}&language=${language}&center_lat=${center_lat}&center_lng=${center_lng}`;
    return  fetchDingiApiData(url , SEARCH_TEXT);
}
