import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DropDownResources from "../dropdowns/dropdown_resources";
import ToggleSwitch from "../buttons/toggle_switch";
import DropDownSeverity from "../dropdowns/dropdown_severity";

import {
  getAvailableAlarmsForUser,
  getCurrentAlarmStates,
  setAlarmState
} from "../../redux/actions/actions_alarm";

import {
  setTopbarTitle,
  setLoadingSpinnerState,
  setModalStateRadiusAlarmSet
} from "../../redux/actions/actions_misc";

import _ from "lodash";

class AlarmSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableAlarmsForUser: null,
      resource_id: null,
      speedThreshold: 60
    };
  }

  componentDidMount() {
    this.props.setTopbarTitle("Settings");
  }

  onChangeThreshold = e => {
    this.setState({ speedThreshold: e.target.value }, () =>
      this.props.setAlarmState(1, null, this.state.speedThreshold, null, null)
    );
  };

  onRadiusAlarmClicked = () => {
    this.props.setModalStateRadiusAlarmSet(true);
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedResourceId &&
      this.state.resource_id !== nextProps.selectedResourceId.resource_id
    ) {
      const id = nextProps.selectedResourceId.resource_id;
      this.setState({ resource_id: id }, () => {
        this.props.setLoadingSpinnerState(true);
        this.props.getAvailableAlarmsForUser(id);
      });
    }

    if (
      this.state.availableAlarmsForUser !== nextProps.availableAlarmsForUser
    ) {
      this.setState(
        { availableAlarmsForUser: nextProps.availableAlarmsForUser },
        () => {
          this.props.setLoadingSpinnerState(false);
          _.map(nextProps.availableAlarmsForUser, singlealarm => {
            this.props.getCurrentAlarmStates(singlealarm.alarm_id);
          });
        }
      );
    }

    if (
      nextProps.currentAlarmState &&
      nextProps.currentAlarmState.alarm_type_id === 1
    )
      this.setState({
        speedThreshold: nextProps.currentAlarmState.alarm_type_data.threshold
      });
  }

  renderAlarmSettingGroupCards() {
    var singleAvailableAlarm = {};
    var arr = [];

    if (this.state.availableAlarmsForUser) {
      var len = this.state.availableAlarmsForUser.length;
      for (var i = 0; i < len; i++) {
        singleAvailableAlarm = this.state.availableAlarmsForUser[i];
        //arr.push(<AlarmSetingsGroup key={i} name={singleAvailableAlarm.alarm_name} alarmId={singleAvailableAlarm.alarm_id} threshold={this.state.speedThreshold} onThresholdChanged={this.onChangeThreshold} />);
        if (
          singleAvailableAlarm.alarm_id !== 10 &&
          singleAvailableAlarm.alarm_id !== 8
        ) {
          arr.push(
            <AlarmSetingsGroup
              showModalRadiusAlarmSet={this.onRadiusAlarmClicked}
              key={i}
              name={singleAvailableAlarm.alarm_name}
              alarmId={singleAvailableAlarm.alarm_id}
              threshold={this.state.speedThreshold}
              onThresholdChanged={this.onChangeThreshold}
            />
          );
        }
      }
    }

    return arr;
  }

  render() {
    return (
      <div className="settings-page-container">
        <div className="settings-page-topbar">
          <DropDownResources />
        </div>
        {this.state.resource_id ? (
          <div className="settings-page-mainbar">
            {this.renderAlarmSettingGroupCards()}
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

function AlarmSetingsGroup(props) {
  var opTionalCustomization = <div />;

  switch (props.alarmId) {
    case 1:
      opTionalCustomization = (
        <div className="alarm-settings-threshold-container">
          <div style={{ fontSize: "10px" }}> Speed Limit (Km/Hr) </div>
          <div>
            <input
              type="number"
              onChange={props.onThresholdChanged}
              className="settings-threshold-input"
              value={props.threshold}
            />
          </div>
        </div>
      );
      break;
    // case 9:
    // 	opTionalCustomization = <button onClick={props.showModalRadiusAlarmSet}> Change Areas</button>
    // 	break;
    default:
  }

  return (
    <div className="alarm-settings-group-container">
      <IconGenarator id={props.alarmId} />
      <div className="alarm-name-desc-container">
        <div className="alarm-settings-name"> {props.name} </div>
        <Description id={props.alarmId} />
      </div>

      {opTionalCustomization}

      <DropDownSeverity alarm_type_id={props.alarmId} />
      <ToggleSwitch alarmId={props.alarmId} />
    </div>
  );
}

function Description(props) {
  var description = "Will Triggger when Car is Overspeeding";

  switch (props.id) {
    case 1: //overspeed
      description = "Will Triggger when Car is Overspeeding";
      break;
    case 2: //sos
      description = "Will Triggger when Sos is Generated";
      break;
    case 3: //vibration
      description = "Will Triggger when Car Vibrates";
      break;
    case 4: //harsh accleration
      description = "Will Triggger when Harsh Accleration Occurs";
      break;
    case 5: //harsh break
      description = "Will Triggger when Harsh Break Occurs";
      break;
    case 6: //low battery
      description = "Will Triggger when Battery is Low";
      break;

    case 7: //out route
      description = "Will Triggger when Car is Out of Route";
      break;
    case 8: //out zone
      description = "Will Triggger when Car is Out of Zone";
      break;
    case 9: // out radius
      description = "Will Triggger when Car is Out of Radius";
      break;
    case 10: //Geofence Alarm
      description = "Will Triggger when Car is Out of Geofenced Area";
      break;
    case 11: //Engine On/Off Alarm
      description = "Will Triggger when Engine on/off Occurs";
      break;
    case 12: //displacement
      description = "Will Triggger when Car is Moved but Engine is Off";
      break;
    case 13: //power cut
      description = "Will Triggger when Someone Externally Disconnect the Wire";
      break;
    case 14: //door
      description = "Will Triggger when Door is Open or Close";
      break;
    case 15: //fuel
      description = "Will Triggger when Fuel Level is Low";
      break;
    case 16: //temparature
      description = "Will Triggger when Engine is Heated";
      break;
    case 17: //collision
      description = "Will Triggger when Collision Occurs";
      break;
    case 18: //face
      description = "Will Triggger when Unauthorized Face is Detected";
      break;
    case 19: //face
      description = "Will Triggger when Unauthorized Face is Detected";
      break;
    case 20: //paper
      description = "Will Triggger when Papers Date Expires";
      break;
    case 21: //fuel level
      description = "Will Triggger when Fuel Level Drops";
      break;
    case 22: //door status
      description = "Will Triggger when a Door Opens or Closes";
      break;
    case 23: //maintenance
      description = "Will Triggger when Maintenance Date Expires";
      break;
    default:
      description = "Will Triggger when Something Goes Wrong";
  }

  return <div className="alarm-settings-desc"> {description} </div>;
}

function IconGenarator(props) {
  var backgroundColor = "#0079bf";
  var icon = "icon-maps-car-vibration";

  switch (props.id) {
    case 1: //overspeed
      backgroundColor = "#0079bf";
      icon = "icon-navigation-arrow-forward";
      break;
    case 2: //sos
      backgroundColor = "#dc143c";
      icon = "icon-maps-track";
      break;
    case 3: //vibration
      backgroundColor = "#e0740e";
      icon = "icon-maps-car-vibration";
      break;
    case 4: //harsh accleration
      backgroundColor = "#098833";
      icon = "icon-maps-car-multi";
      break;
    case 5: //harsh break
      backgroundColor = "#37474f";
      icon = "icon-maps-car-vibration";
      break;
    case 6: //low battery
      backgroundColor = "#19b4b4";
      icon = "icon-action-bolt";
      break;

    case 7: //out route
      backgroundColor = "#0079bf";
      icon = "icon-action-range";
      break;
    case 8: //out zone
      backgroundColor = "#dc143c";
      icon = "icon-navigation-zone";
      break;
    case 9: // out radius
      backgroundColor = "#e0740e";
      icon = "icon-navigation-radius";
      break;
    case 10: //Geofence Alarm
      backgroundColor = "#098833";
      icon = "icon-maps-geofence";
      break;
    case 11: //Engine On/Off Alarm
      backgroundColor = "#37474f";
      icon = "icon-action-power-settings-new";
      break;
    case 12: //displacement
      backgroundColor = "#19b4b4";
      icon = "icon-action-pulse";
      break;
    case 13: //power cut
      backgroundColor = "#0079bf";
      icon = "icon-action-power-settings-new";
      break;
    case 14: //door
      backgroundColor = "#dc143c";
      icon = "icon-car-door";
      break;
    case 15: //fuel
      backgroundColor = "#e0740e";
      icon = "icon-maps-local-gas-station";
      break;
    case 16: //temparature
      backgroundColor = "#37474f";
      icon = "icon-content-waves";
      break;
    case 17: //collision
      backgroundColor = "#19b4b4";
      icon = "icon-car-collision";
      break;
    case 18: //face
      backgroundColor = "#0079bf";
      icon = "icon-maps-car-vibration";
      break;
    case 19: //paper
      backgroundColor = "#dc143c";
      icon = "icon-action-assignment";
      break;
    case 20: //paper
      backgroundColor = "#0079bf";
      icon = "icon-action-assignment";
      break;
    case 21:
      backgroundColor = "#37474f";
      icon = "icon-maps-car-vibration";
      break;
    case 22:
      backgroundColor = "#19b4b4";
      icon = "icon-maps-car-vibration";
      break;
    default:
      backgroundColor = "#dc143c";
      icon = "icon-maps-car-vibration";
  }

  var iconClassName = `alarm-settings-icon ${icon}`;
  return (
    <div
      style={{ background: backgroundColor }}
      className="alarm-settings-icon-container"
    >
      <i className={iconClassName} />
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setModalStateRadiusAlarmSet,
      setLoadingSpinnerState,
      setTopbarTitle,
      getAvailableAlarmsForUser,
      getCurrentAlarmStates,
      setAlarmState
    },
    dispatch
  );
}

function mapStateToProps({
  selectedResourceId,
  ownedResources,
  availableAlarmsForUser,
  currentAlarmState,
  jwtToken,
  settingAlarmResponse
}) {
  return {
    selectedResourceId,
    ownedResources,
    availableAlarmsForUser,
    currentAlarmState,
    jwtToken,
    settingAlarmResponse
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlarmSettings);
