import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTime, setTopbarTitle } from '../../redux/actions/actions_misc';
import MapTrips from '../maps/map_trips';
import ModalTripDetails from '../modals/modal_trip_details';

import DatePickerCreater from '../misc/date-picker';
import DropDownResources from '../dropdowns/dropdown_resources';
import DropDownTrips from '../dropdowns/dropdown_trip_options';
import Timekeeper from 'react-timekeeper';

import Popup from "reactjs-popup";



import 'react-datepicker/dist/react-datepicker.css';


class PageTrips extends Component {


	constructor(props) {
		super(props);
		this.state = {
			resource_id: null,
			date: null,
			tripCount: 5,
			show: false,
			timeFrom: "12:00 am",
			timeTo: "11:59 pm"
		};
	}


	componentDidMount() {
		this.props.setTopbarTitle("Trips");
	}

	componentWillReceiveProps(nextProps) {

		if (this.state.tripCount !== nextProps.tripCount) {
			this.setState({ tripCount: nextProps.tripCount }, () => {
				if (this.state.tripCount === "10") this.setState({ show: true });
				else this.setState({ show: false });
			})
		}
	}

	onFromTimeChange(newTime) {
		this.setState({ timeFrom: newTime.formatted }, () => this.props.setTime(newTime.formatted24, "trip_range_start"));
	}

	onToTimeChange(newTime) {
		this.setState({ timeTo: newTime.formatted }, () => this.props.setTime(newTime.formatted24, "trip_range_end"));
	}



	render() {
		return (



			<div className="trip-page-container">
				<div className="trip-page-topbar">
					<DropDownResources />
					<DatePickerCreater page="trips_page" />
					<DropDownTrips />


					{this.state.show ? <Popup trigger={<button style={{ lineHeight: 2 }}><StartTime time={this.state.timeFrom} name="From" /></button>} position="bottom center">

						<Timekeeper
							time={this.state.time}
							onDoneClick={this.onFromTimeChange.bind(this)}
						/>
					</Popup> : <div></div>}

					{this.state.show ? <Popup trigger={<button style={{ lineHeight: 2 }}><StartTime time={this.state.timeTo} name="To" /></button>} position="bottom center">
						<Timekeeper
							time={this.state.time}
							onDoneClick={this.onToTimeChange.bind(this)}
						/>
					</Popup> : <div></div>}

				</div>
				<div className="trip-page-mainbar">
					<MapTrips />
					<ModalTripDetails />
				</div>
			</div>

		)
	}
}


function StartTime(props) {
	return (
		<div className="dropdown-basic-container">
			<div className="dropdown-basic-icon-container">
				<i className="icon-action-query-builder" />
			</div>
			<div className="dropdown-basic-title-data">
				<div className="dropdown-basic-title"> {props.name} </div>
				<div className="dropdown-basic-data">
					{props.time}
				</div>
			</div>
		</div>

	)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setTime, setTopbarTitle  }, dispatch);
}

function mapStateToProps({  tripCount }) {
	return {  tripCount };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTrips);