import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTopbarTitle } from '../../redux/actions/actions_misc';
import { bindActionCreators } from 'redux';
import AlarmTable from '../tables/table_alarm';


import DatePickerCreater from '../misc/date-picker';
import DropDownResources from '../dropdowns/dropdown_resources'
import DropdownAlarmType from '../dropdowns/dropdown_alarmtype'



class AlarmPage extends Component {

	componentDidMount(){
		this.props.setTopbarTitle("Alarms");
	}

	render() {

		return (
			<div className="alarm-page-container">
				<div className="alarm-page-topbar">
					<DropDownResources />
					<DropdownAlarmType />
					<DatePickerCreater page="alarm_page" />
				</div>
				<div className="alarm-page-mainbar">
					<AlarmTable severity={1} />
				</div>
			</div>

		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setTopbarTitle}, dispatch);
}

function mapStateToProps({ alarms }) {
	return { alarms };
}

export default connect(mapStateToProps, mapDispatchToProps)(AlarmPage)
