export const DEFAULT = "/";

export const SIGN_IN = "/signin";

export const HOME = "/home";
export const ALARM = "/alarm";
export const VEHICLES = "/vehicles";
export const PAPERS = "/papers";
export const REPORT = "/report";
export const TRIPS = "/trips";
export const FUEL = "/fuel";
export const SETTINGS = "/settings";
export const DETAILS = "/details";
export const BILLING = "/billing";
export const PROFILE = "/profile";
export const SUPPORT = "/support";
export const TEST = "/test";

export const STREAM = "/stream";
