import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalStateRadiusAlarmSet, getReversedAddressFromLatLng, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { getPathSpecificAlarm } from '../../redux/actions/actions_alarm';


import { setupMap  } from '../maps/map_service';

class ModalRadiusAlarmSet extends Component {

    constructor(props) {
        super(props);
        this.state = {

            min_lon: 90.01,
            max_lon: 90.03,
            min_lat: 23.0,
            max_lat: 23.03
        };
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    componentDidMount() {setupMap(this)}

    handleClose() {this.props.setModalStateRadiusAlarmSet(false)}



    componentWillReceiveProps(nextProps) {

    }



    render() {
       
       
        return (
            <div className="modal">

                <div className="modal-radius-set">
                    <div className="modal-radius-set-topbar"> 
                    <div style={{ fontSize: "22px" }} className="input-search">
					<i className="icon-action-search"></i>
					<input
						className="form-controll"
						placeholder={this.state.placeHolder}
						name="search"
						value={this.state.term}
						id="srch-term"
						onChange={this.onInputChange}
						type="text" />
					<i className="icon-navigation-expand-more"></i>
				</div>
                        <button onClick={this.handleClose}>close</button> 
                    </div>
                    <div className="modal-radius-set-mainbar  map-modal-radius"> 
                        <div id="container" ref={el => (this.mapContainer = el)} className="map absolute top right left bottom" >
                            <button style={{top:"55%",left:"50%",position:"fixed",zIndex:"11"}}> pick </button>
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}




function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getReversedAddressFromLatLng, setLoadingSpinnerState, getPathSpecificAlarm, setModalStateRadiusAlarmSet }, dispatch);
}

function mapStateToProps({ reverseGeocodedAddress, modalState, specificAlarmDetail, specificAlarmPath, selectedResourceId }) {
    return { reverseGeocodedAddress, modalState, specificAlarmDetail, specificAlarmPath, selectedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalRadiusAlarmSet);

