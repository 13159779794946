import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSharableLocationUrl, setModalStateShareLocation, setVisibilityNavigationPathToResource, setLoadingSpinnerState, setModalStateVehicleDetails, getReversedAddressFromLatLng } from '../../redux/actions/actions_misc';
import { seTrackedResourceId, getAllResourcesLocation, getSingleResourceLocation } from '../../redux/actions/actions_resources';

import { getTimeAgoFromUtcTime } from '../../utils/functions';
import ReactSpeedometer from "react-d3-speedometer"


class ModalVehicle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            camera: "front",
            deviceType: "vehicle-details no-camera",
            trackingOrNot: false,
            pathShownOrNot: false,
            imageArray: [],
            selectedIndex: 0,
            trackedResourceId: null,
            reverseGeocodedAddress: "",
            allResourcesLocation: null,
            resourceIdForDetailsInMap: null
        };

        this.handleCloseBtnClick = this.handleCloseBtnClick.bind(this);
        this.handleTrackBtnClick = this.handleTrackBtnClick.bind(this);
        this.handleCameraChange = this.handleCameraChange.bind(this);
        this.handlePathShow = this.handlePathShow.bind(this);
    }



    componentDidMount() { this.props.seTrackedResourceId(null) }
    handleCloseBtnClick() { this.props.setModalStateVehicleDetails(false) }
    componentWillUnmount() { clearInterval(this.interval) }

    handleTrackBtnClick() {
        if (this.state.trackingOrNot) {
            this.setState({ trackingOrNot: false });
            this.props.seTrackedResourceId(null);
        } else {
            this.setState({ trackingOrNot: true });
            this.props.seTrackedResourceId(this.state.singleResourceLocation.id);
        }
    }


    //naviagation path show handling
    handlePathShow() {
        if (this.state.pathShownOrNot) {
            this.setState({ pathShownOrNot: false });
            this.props.setVisibilityNavigationPathToResource(false, this.props.trackedResourceId);
        } else {
            this.setState({ pathShownOrNot: true });
            this.props.setVisibilityNavigationPathToResource(true, this.props.trackedResourceId);
        }
    }

    handleCameraChange() {
        switch (this.state.camera) {
            case "front":
                this.setState({ camera: "back" });
                break;
            default:
                this.setState({ camera: "front" });
        }
    }


    componentWillReceiveProps(nextProps) {

        if (nextProps.singleResourceLocation && nextProps.singleResourceLocation !== this.state.singleResourceLocation) {
            this.setState({ singleResourceLocation: nextProps.singleResourceLocation, imageArray: nextProps.singleResourceLocation.resourceimage }, () => this.props.setLoadingSpinnerState(false));
        }

        if (nextProps.trackedResourceId && this.state.trackedResourceId !== nextProps.trackedResourceId) this.setState({ trackedResourceId: nextProps.trackedResourceId, trackingOrNot: true });
        else this.setState({ trackedResourceId: nextProps.trackedResourceId, trackingOrNot: false });

        if (nextProps.resourceIdForDetailsInMap && nextProps.resourceIdForDetailsInMap !== this.state.resourceIdForDetailsInMap) {
            this.setState({ resourceIdForDetailsInMap: nextProps.resourceIdForDetailsInMap }, () => this.getSingleResourceDataPeriodically())
        }

        if (nextProps.allResourcesLocation && this.state.allResourcesLocation !== nextProps.allResourcesLocation) {
            this.setState({ allResourcesLocation: nextProps.allResourcesLocation }, () => this.getReverseAddress());
        }

        if (nextProps.reverseGeocodedAddress && nextProps.reverseGeocodedAddress !== this.state.reverseGeocodedAddress) {
            this.setState({ reverseGeocodedAddress: nextProps.reverseGeocodedAddress });
        }

        
    }

    getSingleResourceDataPeriodically() {
        this.interval = setInterval(() => {
            this.props.getSingleResourceLocation(this.state.resourceIdForDetailsInMap);
            this.getReverseAddress();
        }, 5000);
    }



    getReverseAddress() {
        if (this.state.allResourcesLocation && this.state.resourceIdForDetailsInMap) {
            var data = this.state.allResourcesLocation[this.state.resourceIdForDetailsInMap];
            this.props.getReversedAddressFromLatLng(data.location.lat, data.location.lon);
        }
    }

    getSharableLink() {
        if (this.state.allResourcesLocation && this.state.resourceIdForDetailsInMap) {
            var data = this.state.allResourcesLocation[this.state.resourceIdForDetailsInMap];
            const url = `http://maps.dingi.tech/location_share/${data.location.lat}/${data.location.lon}/15.00`
            this.props.setSharableLocationUrl(url)
        }
    }

    onImageSwitchClicked() {
        this.setState({ selectedIndex: (this.state.selectedIndex + 1) % 2 })
    }

    render() {

        if (this.state.singleResourceLocation) {
            var data = this.state.singleResourceLocation;
            var hasImage = data.resourceimage.length > 0 ? true : false;
            var hasMultipleImage = data.resourceimage.length > 1 ? true : false;
            var hasFuelData = data.last_location.fuel_level ? true : false;

            var driver = data.driver ? data.driver : "Diver name not available";
            var license = data.license;
            var title = data.title;
            var vehicleName = data.vehicle_type;

            var distance = data.distance ? `${data.distance.toFixed(1)} Km` : "N/A";
            var fuelConsumed = data.fuel_consumed ? `${data.fuel_consumed.toFixed(1)} L` : "N/A";
            var fuelEfficiency = data.fuel_consumed ? `${(data.distance / data.fuel_consumed).toFixed(1)} Km/L` : "N/A";

            var engineStatus = data.last_location.engine ? "On" : "Off";;
            var engineTemparature = data.last_location.engine_temperature ? `${data.last_location.engine_temperature.toFixed(1)} °C` : "N/A";
            var fuelLevel = data.last_location.average_fuel_level ? `${data.last_location.average_fuel_level.toFixed(1)} L` : "N/A";
            var voltage = data.last_location.volt ? `${data.last_location.volt.toFixed(1)} V` : "N/A";
            var speed = `${data.last_location.speed.toFixed(1)} km/Hr`;

            var timestamp = data.last_location.timestamp;
            var timeAgo = getTimeAgoFromUtcTime(timestamp)


            return (
                <div className="modal-vehicle-details-container">

                    <div className="vehicle-details-topbar-container">
                        <div className="vehicle-details-title-cross">
                            <span className="vehicle-detais-title"> <b>{title}</b> </span>
                            <i onClick={() => this.handleCloseBtnClick()} className="icon-navigation-close" />
                        </div>

                        <div className="vehicle-details-driver-vehicle">
                            <div> <i className="icon-navigation-full-screen" /> {license}</div>
                            <div > <i className="iconperson icon-social-person-outline mini-person-icon" /> {driver}</div>
                            <div> <i className="iconperson icon-maps-car mini-person-icon" /> {vehicleName}</div>
                        </div>

                        <div className="vehicle-details-timeago">
                            {timeAgo} near {this.state.reverseGeocodedAddress}
                        </div>

                    </div>



                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gridColumnGap: "10px" }}>

                        {this.state.trackedResourceId ?
                            <button className="btn-exit-track" onClick={this.handleTrackBtnClick}> <i className="icon-navigation-close" aria-hidden="true"></i> Exit Tracking</button> :
                            <button className="btn-track" onClick={this.handleTrackBtnClick}> <i className="icon-maps-track" aria-hidden="true"></i> Track</button>
                        }

                        <button className="btn-track" onClick={() => { this.props.setModalStateShareLocation(true); this.getSharableLink() }}> <i className="icon-social-share" aria-hidden="true"></i> Share</button>


                    </div>




                    <div style={{
                        marginTop: "10px",
                        width: '100%',
                        height: '35%',
                        background: '#fff'
                    }}>

                        <div className="dashboard-item-title">
                            SPEED
                        </div>
                        <ReactSpeedometer
                            fluidWidth
                            currentValueText={speed}
                            value={data.last_location.speed.toFixed(1)}
                            needleColor="red"
                            startColor="blue"
                            height={200}
                            // width={300}
                            maxValue={120}
                            ringWidth={20}
                            segments={500}
                            maxSegmentLabels={5}
                            needleHeightRatio={0.7}
                            endColor="red"
                            needleTransitionDuration={1000}
                            needleTransition="easeElastic"
                            textColor="black"
                        />
                    </div>






                    {/* {this.state.pathShownOrNot ?
                        <button className="btn-exit-track" onClick={this.handlePathShow}> <i className="icon-navigation-close" aria-hidden="true"></i>Remove Path</button> :
                        <button className="btn-track" onClick={this.handlePathShow}> <i className="icon-maps-track" aria-hidden="true"></i> Show Path</button>
                    } */}


                    {hasFuelData ?
                        <div className="vehicle-details-title-over-data"> <b> Todays Summary  </b></div>
                        : <div></div>}
                    {hasFuelData ?
                        <div className="vehicle-details-info-container">
                            <SingleItemDashBoard name="DISTANCE" value={distance} icon="icon-maps-car" color="text-black" />
                            <SingleItemDashBoard name="FUEL CONSUMED " value={fuelConsumed} icon="icon-maps-local-gas-station" color="text-blue" />
                            <SingleItemDashBoard name="FUEL EFFICIENCY" value={fuelEfficiency} icon="icon-device-data-usage" color="text-deep-green" />

                        </div> : <div></div>}


                    {hasImage ? <div className="vehicle-details-title-over-data"> <b> Vehicle Images  </b></div> : <div></div>}
                    {hasImage ?
                        <div className="device-details-image-container">
                            <div> <img alt ="none" className="device-details-image" src={this.state.imageArray[this.state.selectedIndex].url} /> </div>
                            {hasMultipleImage ? <button onClick={() => this.onImageSwitchClicked()} className="device-details-switch-btn"> <i className="icon-navigation-faceid" /> Switch Camera</button> : <div></div>}
                        </div> : <div></div>}



                    <div className="vehicle-details-title-over-data"> <b> Vehicle Stats  </b></div>

                    <div className="vehicle-details-info-container">
                        {/* <SingleItemDashBoard name="SPEED" value={speed} icon="icon-action-schedule-start" color="text-black" /> */}
                        <SingleItemDashBoard name="REMAINING FUEL" value={fuelLevel} icon="icon-maps-local-gas-station" color="text-blue" />

                        <SingleItemDashBoard name="ENGINE STATUS" value={engineStatus} icon="icon-action-power-settings-new" color="text-red" />
                        <SingleItemDashBoard name="ENGINE TEMPARATURE" value={engineTemparature} icon="icon-content-waves" color="text-green" />
                        <SingleItemDashBoard name="VOLTAGE" value={voltage} icon="icon-action-bolt" color="text-orange" />
                    </div>

                    {/* <button className="btn-track" onClick={() => { this.props.setModalStateShareLocation(true); this.getSharableLink() }}> <i className="icon-social-share" aria-hidden="true"></i>Share Location</button> : */}


                </div>


            );
        } else {

            return <div></div>

        }

    }
}

function SingleItemDashBoard(props) {
    var tempClass = "dashboard-item-data " + props.color;
    return (
        <div className="dashboard-single-item-container">
            <div className="dashboard-item-title">
                {props.name}
            </div>

            <div className={tempClass}>
                <b> {props.value} <i className={props.icon} /> </b>
            </div>
        </div>
    )
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setSharableLocationUrl, setModalStateShareLocation, setVisibilityNavigationPathToResource, setLoadingSpinnerState, getReversedAddressFromLatLng, getAllResourcesLocation, getSingleResourceLocation, setModalStateVehicleDetails, seTrackedResourceId }, dispatch);
}

function mapStateToProps({ resourceIdForDetailsInMap, reverseGeocodedAddress, allResourcesLocation, singleResourceLocation, modalState, trackedResourceId }) {
    return { resourceIdForDetailsInMap, reverseGeocodedAddress, allResourcesLocation, singleResourceLocation, modalState, trackedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalVehicle);



