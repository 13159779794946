import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signInUser, saveSignInCredentials, setCredentialRememberState } from '../../redux/actions/actions_auth';
import { setLoadingSpinnerState } from '../../redux/actions/actions_misc';

import * as ROUTES from '../../assets/constants/routes';

import appIcon from '../../assets/icons/icon.png';
import Axios from 'axios';
import {BASE_URL } from '../../assets/constants/app_constants';

const NORMAL_LOGIN = "NORMAL_LOGIN"
const EMAIL_INPUT = "EMAIL_INPUT"
const EMAIL_INPUT_SUCCESS = "EMAIL_INPUT_SUCCESS"

class LoginPage extends Component {

	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			rememberPassWordState: false,
			errorMessage: "",
			viewState: NORMAL_LOGIN
		}
		this.onSubmitUserCredential = this.onSubmitUserCredential.bind(this);
		this.SubmitEmailForRecovery = this.SubmitEmailForRecovery.bind(this);
		this.onChangeEmail = this.onChangeEmail.bind(this);
		this.onChangePassword = this.onChangePassword.bind(this);
		this.onChangeRememberPass = this.onChangeRememberPass.bind(this);

	}

	componentDidMount() {
		if (this.props.jwtToken && this.props.isTokenValid) {
			this.props.history.push(ROUTES.DEFAULT);
		}
		if (this.props.signInCredentialRememberState) {
			//console.log('this.props.signIn ', this.props.signInCredential, this.props.signInCredentialRememberState);
			this.setState({ email: this.props.signInCredential.email, password: this.props.signInCredential.password });
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.signInResponse) {
			//console.log('sign in response is    ', nextProps.signInResponse);
			this.props.setLoadingSpinnerState(false, "sign in not succesfull");
			const signInMessage = nextProps.signInResponse.message;
			this.setState({ errorMessage: signInMessage });
		}

	}

	onChangeEmail(e) {
		this.setState({
			email: e.target.value
		}, () => this.props.saveSignInCredentials({ email: this.state.email, password: this.state.password }));
	}
	onChangePassword(e) {
		this.setState({
			password: e.target.value
		}, () => this.props.saveSignInCredentials({ email: this.state.email, password: this.state.password }));
	}

	onChangeRememberPass(e) {
		if (this.state.rememberPassWordState) {
			this.setState({ rememberPassWordState: false }, () => this.props.setCredentialRememberState(false));
		} else {
			this.setState({ rememberPassWordState: true }, () => this.props.setCredentialRememberState(true));
		}
	}

	onSubmitUserCredential(e) {
		e.preventDefault();
		//console.log('current data is  ', this.state.email, this.state.password);
		const authObject = {
			email: this.state.email,
			password: this.state.password
		}
		this.props.setLoadingSpinnerState(true, "Signing in the User ...");
		this.props.signInUser(authObject);
	}

	SubmitEmailForRecovery(e) {
		e.preventDefault();
		this.props.setLoadingSpinnerState(true);
		var url = `${BASE_URL}user/api/forgetpassword/?email=${this.state.email}`
		Axios.get(url).then((response) => {
			this.props.setLoadingSpinnerState(false);
			this.setState({ viewState: EMAIL_INPUT_SUCCESS });
		})
			.catch((e) => {
				console.error(e);
			})
			.finally((e) => {
				this.props.setLoadingSpinnerState(false);
				this.setState({ viewState: EMAIL_INPUT_SUCCESS });
			});

	}



	render() {

		if (this.state.viewState === NORMAL_LOGIN) {
			return (


				<div className="page-login-container" onSubmit={this.onSubmitUserCredential}>
					<div className="email container-login-form">
						<img className="sign-in-app-icon" src={appIcon} alt="nothing" />
						<h4 className="sign-in-title"> <b>FollowR</b> </h4>
						<div className="login-single-row">
							<span className="icon-login-form"><i className="icon-action-account-circle" > </i> </span>
							<input required className="input-text" type="text" placeholder="Email address" onChange={this.onChangeEmail} />
						</div>
						<div className="login-single-row">
							<span className="icon-login-form"><i className="icon-action-lock"> </i> </span>
							<input required className="input-text" type="password" placeholder="Password" onChange={this.onChangePassword} />
						</div>
						<div>
							{this.state.errorMessage}
						</div>
						<div className="remember-text">
							<input type="checkbox" name="remember" value="yes" onChange={this.onChangeRememberPass} />
							<b> Remember Password </b>
						</div>
						<div className="submit-container">
							<div onClick={() => this.setState({ viewState: EMAIL_INPUT })} className="remember-text">
								Forgot Password ?
							</div>
							<button onClick={ (e) => this.onSubmitUserCredential(e)}className="btn-signin" type="submit" ><i className="icon-navigation-arrow-forward"></i> Sign in</button>
						</div>
					</div>
				</div>
			)
		} else if (this.state.viewState === EMAIL_INPUT) {
			return (

				<form className="page-login-container" onSubmit={this.SubmitEmailForRecovery}>
					<div className="email container-login-form-forgot">
						<img className="sign-in-app-icon" src={appIcon} alt="nothing" />
						<h4 className="sign-in-title"> FollowR </h4>
						<div className="login-single-row">
							<span className="icon-login-form"><i className="icon-action-account-circle" > </i> </span>
							<input required className="input-text" type="text" placeholder="Email address" onChange={this.onChangeEmail} />
						</div>

						<div className="submit-container">
							<div onClick={() => this.setState({ viewState: NORMAL_LOGIN })} className="remember-text">
								Sign In Now?
							</div>
							<button className="btn-signin" type="submit" ><i className="icon-navigation-arrow-forward"></i>Send Recovery Link </button>
						</div>
					</div>
				</form>
			)
		} else {
			return (

				<form className="page-login-container" onSubmit={this.SubmitEmailForRecovery}>
					<div className="email container-login-form-forgot">
						<img className="sign-in-app-icon" src={appIcon} alt="nothing" />
						<h4 className="sign-in-title"> FollowR </h4>

						<b className="text-black" style={{textAlign:"center"}}>


							Your password recovery mail is sent .
							please check your inbox
						</b>

						<button style={{justifySelf:"center"}} className="btn-signin" onClick={() => this.setState({ viewState: NORMAL_LOGIN })} ><i className="icon-navigation-arrow-forward"></i> Sign In Now </button>


			
					</div>
				</form>

			)
		}


	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ saveSignInCredentials, setCredentialRememberState, signInUser, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ signInCredential, signInCredentialRememberState, jwtToken, isTokenValid, signInResponse }) {
	return { signInCredential, signInCredentialRememberState, jwtToken, isTokenValid, signInResponse };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);