import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModalAlarmReplay from './modal_alarm_replay';
import ModalMaintenceEntry from './modal_maintenance_entry';
import ModalMaintenanceLogView from './modal_maintenance_logview';
import ModalVehiclePaper from './modal_vehicle_paper';
import ModalFuelEntry from './modal_fuel_entry';
import ModalTrendView from './modal_trend_view';
import ModalIssueEntry from './modal_issue_entry';
import ModalShareLocation from './modal_share_location';
import ModalRadiusAlarmSet from './modal_radius_alarm_set';

class ModalController extends Component {



	render() {
		return (
			<div>
				{this.props.alarmReplayState ? <ModalAlarmReplay /> : <div></div>}
				{this.props.maintenanceEntryModalState ? <ModalMaintenceEntry /> : <div></div>}
				{this.props.maintenanceLogViewState ? <ModalMaintenanceLogView /> : <div></div>}
				{this.props.paperDetailsViewState ? <ModalVehiclePaper paperName={this.props.selectedPaper} /> : <div></div>}
				{this.props.refuelEntryModal ? <ModalFuelEntry /> : <div></div>}
				{this.props.trendViewModal ? <ModalTrendView /> : <div></div>}
				{this.props.issueEntryModal ? <ModalIssueEntry /> : <div></div>}
				{this.props.modalStateShareLocation ? <ModalShareLocation /> : <div></div>}
				{this.props.modalStateRadiusAlarmSet ? <ModalRadiusAlarmSet /> : <div></div>}
			</div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

function mapStateToProps({  
	selectedPaper, 
	modalStateRadiusAlarmSet,
	 alarmReplayState
	, maintenanceEntryModalState
	, maintenanceLogViewState
	, paperDetailsViewState
	, refuelEntryModal
	, trendViewModal
	, issueEntryModal
	, modalStateShareLocation }) {
	return {
		  selectedPaper,
		  modalStateRadiusAlarmSet
		, alarmReplayState
		, maintenanceEntryModalState
		, maintenanceLogViewState
		, paperDetailsViewState
		, refuelEntryModal
		, trendViewModal
		, issueEntryModal
		, modalStateShareLocation
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalController);