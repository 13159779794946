import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllResources, selectResourceId } from '../../redux/actions/actions_resources';
import _ from "lodash";
import car from '../../assets/icons/car_icon.png'


class DropDownResources extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            selectedId: null
        };
        this.onVehicleSelected = this.onVehicleSelected.bind(this);
    }

    componentDidMount() {
        this.props.getAllResources();
    }

    onVehicleSelected(e) {
        this.props.selectResourceId(e.target.value, "settings");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ownedResources) {
            this.setState({
                data: nextProps.ownedResources
            });
        }
    }

    renderOptions() {


        //select a default resource 
        var arr = _.map(this.state.data, singleResource => {
            return singleResource;
        });
        if (arr.length > 0) this.props.selectResourceId(arr[0].id, "settings");

        if (this.state.data) {
            return _.map(this.state.data, singleResource => {
                return (
                    <option key={singleResource.id} className="dropdown-severity-option" value={singleResource.id}>{singleResource.title}</option>
                );
            });
        }
    }


    render() {


        if (this.props.page === "modal") {
            return (
                <div className="dropdown-modal-container">
                    <select className="dropdown-modal-selection" onChange={this.onVehicleSelected}>
                        {this.renderOptions()}
                    </select>
                </div>
            )
        } else {
            return (
                <div className="dropdown-basic-container">
                    <div className="dropdown-basic-icon-container">
                        {/* <i className="icon-maps-car" /> */}
                        <img alt ="none" style={{height:"36px"}} src={car}/>
                    </div>
                    <div className="dropdown-basic-title-data">

                        <div className="dropdown-basic-title"> Vehicle </div>
                        <div className="dropdown-basic-data">
                            <select className="dropdown-basic-selection" onChange={this.onVehicleSelected}>
                                {this.renderOptions()}
                            </select>
                        </div>
                    </div>
                </div>
            )

        }


    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectResourceId, getAllResources }, dispatch);
}

function mapStateToProps({ ownedResources }) {
    return { ownedResources };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropDownResources);