import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { getAllIssues } from '../../redux/actions/actions_auth';
import { getDaysAgo } from '../../utils/functions'



class SupportlTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allIssues: [],
            count: 0
        };
    }

    componentDidMount() {
        this.props.setLoadingSpinnerState(true);
        this.props.getAllIssues();
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.allIssues) {
            this.props.setLoadingSpinnerState(false);
            this.setState({
                count: nextProps.allIssues.count,
                allIssues: nextProps.allIssues.results
            });
        }

    }


    renderTableData() {

        if (this.state.count === 0) {
            return (
                <tr >
                    <td className="table-item-no-data" colSpan={6}> No Data Found </td>
                </tr>
            )
        }
        var arr = []
        for (var i = 0; i < this.state.count; i++) {
            arr.push(
                <tr key={i} className="table-head">
                    <td component="th" >
                        {this.state.allIssues[i].title}
                    </td>
                    <td align="center"> {getDaysAgo(this.state.allIssues[i].last_updated)} days ago</td>
                    <td align="center">{this.state.allIssues[i].description}</td>

                    <td align="center">
                        <span className={this.getClassName(this.state.allIssues[i].status)}>
                            {this.getStatus(this.state.allIssues[i].status)}
                        </span>
                    </td>
                </tr>
            )
        }
        return arr;
    }



    getStatus(type) {
        switch (type) {
            case 1: return "Open Issue"
            case 2: return "Re-Opened Issue"
            case 3: return "Resolved"
            case 4: return "Closed"
            case 5: return "Duplicate"
            default: return "Open Issue"
        }
    }

    getClassName(type) {
        switch (type) {
            case 1: return "issue-open"
            case 2: return "issue-reopen"
            case 3: return "issue-resolved"
            case 4: return "issue-closed"
            case 5: return "issue-duplicate"
            default: return "issue-open"
        }
    }

    getIntegerFromString(num) {
        var temp = "";
        for (var i = 0; i < num.length; i++) {
            if (num[i] === '.') break;
            else temp = temp + num[i];
        } return temp + " ";
    }

    render() {
        return (
            <div className="table-support">
                <table className="table table-hover">
                    <thead >
                        <tr >
                            <th>  Subject    </th>
                            <th>  Updated on </th>
                            <th>  Description      </th>
                            <th>  Status     </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAllIssues, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ allIssues }) {
    return { allIssues };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportlTable);
