import _ from 'lodash';


//icon names
const TRIP_START_ICON = "start";
const TRIP_END_ICON = "stop";
const DOT_ICON = "dot";
const TRIP_ARROW_ICON = "up_arrow";
const FUEL_STATION__ICON = "fuelstation";



//layer ids
const ALL_VEHICLES = "ALL_VEHICLES";
const ALL_FUEL_STATIONS = "ALL_FUEL_STATIONS";
const TRACKED_VEHICLE = "TRACKED_VEHICLE";
const LAYER_REPORT_DAY_POINTS = "LAYER_REPORT_DAY_POINTS";
const LAYER_DIRECTION_POINTS = "LAYER_DIRECTION_POINTS";
const LAYER_TRIP_START_ICON = "LAYER_TRIP_START_ICON"
const LAYER_TRIP_END_ICON = "LAYER_TRIP_END_ICON"

const getIconSize = (iconType) => {
    switch (iconType) {
        case TRIP_START_ICON:
            return 0.4;
        case TRIP_END_ICON:
            return 0.4;
        case TRIP_ARROW_ICON:
            return 0.5;
        case DOT_ICON:
            return 1.0;
        case FUEL_STATION__ICON:
            return 0.1;
        default:
            return 0.4;
    }
}



const getIconLayout = (iconSize = 0.4) => {
    return {
        "icon-image": "{icon}",
        "icon-size": iconSize,
        "icon-rotate": {
            "type": "identity",
            "property": "bearing"
        },
        "icon-anchor": "top",
        "text-size": 12,
        "text-allow-overlap": true,
        "icon-allow-overlap": true,
        "icon-pitch-alignment": "map",
        // "icon-ignore-placement": false,
        "text-offset": [0, -0.5]
    };
};




const getIconLayerFromIdAndLayerData = (layerId, layerData, layerIconSize) => {
    return {
        id: layerId,
        type: "symbol",
        source: {
            type: "geojson",
            data: layerData
        },
        layout: getIconLayout(layerIconSize)
    }
}

const generateIconFeaturesArrayFromLocationAndData = (locationArr, dataArr) => {
    var temp = []
    for (var i = 0; i < locationArr.length; i++) {
        temp.push({
            type: "Feature",
            properties: {
                id: dataArr[i].id,
                icon: dataArr[i].icon,
                bearing: dataArr[i].bearing,
                title: dataArr[i].title,
                location: locationArr[i],
            },
            geometry: {
                type: "Point",
                coordinates: locationArr[i]
            }
        });
    }
    return temp;
}

const getIconLayerDataFromLocationAndData = (locationArr, dataArr) => {
    return {
        "type": "FeatureCollection",
        "features": generateIconFeaturesArrayFromLocationAndData(locationArr, dataArr)
    }
}


//data arr contains the additional info about the icon like bearing title icon
//dataArr = [ {bearing: , title:  , icon: , id: } ]
const addIcons = (map, layerId, locationArr, dataArr) => {

    //taking first element of the array assuming all icon will be of same size
    var iconSize = getIconSize(dataArr[0].icon);

    var iconLayerData = getIconLayerDataFromLocationAndData(locationArr, dataArr);
    var iconsLayer = getIconLayerFromIdAndLayerData(layerId, iconLayerData, iconSize);



    if (map.getLayer(layerId)) map.getSource(layerId).setData(iconLayerData);
    else map.addLayer(iconsLayer);

}


const removeLayerAndSource = (map, layerId) => {
    if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
        map.removeSource(layerId);
    }
}


const determineCarIconToShow = (speed, engine) => {
    if (engine === false) return "car_inactive"
    else if (engine === true && speed === 0) return "car_hault"
    else if (engine === true && speed > 0) return "car_active"
    else return "car_icon"
}

//home alarmreplay 

export const showAllVehicles = (Component) => {

    var allResourcesLocation = Component.state.allResourcesLocation;
    var ownedResources = Component.state.ownedResources;

    var locationArr = [];
    var dataArr = [];

    _.map(allResourcesLocation, singleResourceLocation => {

        var { id, bearing, location } = { ...singleResourceLocation }
        var title = ownedResources ? ownedResources[id] ? ownedResources[id].title : "car" : "car";
        var icon = determineCarIconToShow(singleResourceLocation.speed, singleResourceLocation.engine);
        var tempLocationArr = [location.lon, location.lat];

        if(tempLocationArr[0] !== 0 && tempLocationArr[1] !== 0){
            locationArr.push(tempLocationArr);
            dataArr.push({ id, bearing, title, icon })
        }
        

    })

    if (dataArr.length > 0) addIcons(Component.map, ALL_VEHICLES, locationArr, dataArr);

    Component.map.on("click", ALL_VEHICLES, Component.onVehicleClicked);
    Component.map.on("mouseenter", ALL_VEHICLES, Component.onMouseEnter);
    Component.map.on("mouseleave", ALL_VEHICLES, Component.onMouseLeave);


}


export const hideAllVehicles = (Component) => {
    removeLayerAndSource(Component.map, ALL_VEHICLES);
}


export const showSingleTrackedVehicle = (Component, data) => {
    var locationArr = [[data.location.lon, data.location.lat]]
    var dataArr = [{
        icon: "car_icon_track",
        bearing: data.bearing
    }]
    addIcons(Component.map, TRACKED_VEHICLE, locationArr, dataArr);
}


export const showSingleTrackedVehicleFromFeature = (Component, feature) => {
   // console.log('show   ' , feature);
    var locationArr = [feature.geometry.coordinates]
    var dataArr = [{
        icon: "car_icon_track",
        //bearing: feature.properties.bearing
        bearing: 0
    }]
   // console.log(locationArr , dataArr)
    if(Component.state.isStyleLoaded)addIcons(Component.map, TRACKED_VEHICLE, locationArr, dataArr);
}




export const hideSingleTrackedVehicle = (Component) => {
    removeLayerAndSource(Component.map, TRACKED_VEHICLE);
}


export const showNearbyFuelStations = (Component) => {

    var fuelStations = Component.state.nearbyFuelStations;
    var locationArr = [];
    var dataArr = [];
    _.map(fuelStations, singleFuelStation => {
        var {id } = {...singleFuelStation};
        var icon = "fuelstation";       
        var title = singleFuelStation.name;
        if(singleFuelStation.location[0] !== 0 && singleFuelStation.location[1] !== 0){
            locationArr.push(singleFuelStation.location.reverse());
            dataArr.push({ id, title, icon })
        }
    })
    //console.log(locationArr , dataArr);

    if (dataArr.length > 0) addIcons(Component.map, ALL_FUEL_STATIONS, locationArr, dataArr);
}

export const removeNearbyFuelStations = (Component) => {
    //console.log('removing fuel stations')
    removeLayerAndSource(Component.map, ALL_FUEL_STATIONS);
}


//report
export const showReportPoints = (Component) => {

    var historyLocations = Component.state.historyLocations.features;

    var locationArr = [];
    var dataArr = [];

    _.map(historyLocations, singleHistoryLocation => {

        var tempLocationArr = singleHistoryLocation.geometry.coordinates;
        var title = singleHistoryLocation.properties.timestamp;
        var icon = DOT_ICON;
        locationArr.push(tempLocationArr);
        dataArr.push({ title, icon })
    })

    if (dataArr.length > 0) addIcons(Component.map, LAYER_REPORT_DAY_POINTS, locationArr, dataArr);

}


export const hideReportPoints = (Component) => {
    removeLayerAndSource(Component.map, LAYER_REPORT_DAY_POINTS);
}




//trips

export const showDirectionIcons = (Component, features) => {
    var locationArr = [];
    var dataArr = [];

    _.map(features, singleFeature => {
        var tempLocationArr = singleFeature.geometry.coordinates;
        var bearing = singleFeature.properties.bearing;
        //var bearing = 0;
        var title = singleFeature.properties.timestamp;
        var icon = "up_arrow";
        locationArr.push(tempLocationArr);
        dataArr.push({ title, bearing, icon })
    })
    if (dataArr.length > 0) addIcons(Component.map, LAYER_DIRECTION_POINTS, locationArr, dataArr);
}


export const hideDirectionIcons = (Component) => {
    removeLayerAndSource(Component.map, LAYER_DIRECTION_POINTS);
}



export const showTripStartIcon = (Component, location) => {
    var locationArr = [location];
    var dataArr = [{ icon: TRIP_START_ICON , bearing : 180 }]
    addIcons(Component.map, LAYER_TRIP_START_ICON, locationArr, dataArr);
}

export const hideTripStartIcon = (Component) => {
    removeLayerAndSource(Component.map, LAYER_TRIP_START_ICON);
}

export const showTripEndIcon = (Component, location) => {
    var locationArr = [location];
    var dataArr = [{ icon: TRIP_END_ICON }]
    addIcons(Component.map, LAYER_TRIP_END_ICON, locationArr, dataArr);
}

export const hideTripEndIcon = (Component) => {
    removeLayerAndSource(Component.map, LAYER_TRIP_END_ICON);
}