const LAYER_LINE_ANIMATION = "LAYER_LINE_ANIMATION"
const LAYER_NAVIGATION = "LAYER_NAVIGATION"




const getLineDataFromCoOrdinates = (locationArr) => {
    return {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": locationArr
            }
        }]
    }
}

const getLineLayerFromLineData = (layerId, lineData , color) => {
    return {
        'id': layerId,
        'type': 'line',
        'source': {
            'type': 'geojson',
            'data': lineData
        },
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': color,
            'line-width': 3,
            'line-opacity': .8
        }
    }
}


const addLineLayer = (map ,layerId,  coOrdinatesArray  , color) => {
    var lineData = getLineDataFromCoOrdinates(coOrdinatesArray);
    var lineLayer = getLineLayerFromLineData(layerId, lineData , color);
    if(map.getLayer(layerId))map.getSource(layerId).setData(lineData);
    else map.addLayer(lineLayer);
}


//track

export const showTrailingPath = (Component, coOrdinatesArray) => {
    addLineLayer(Component.map ,LAYER_LINE_ANIMATION ,coOrdinatesArray  , '#ed6498'  );
}


export const hideTrailingPath = (Component) => {
    removeLayerAndSource(Component.map , LAYER_LINE_ANIMATION);
}


//trip

export const addTripPath = (Component ,  layerId , coOrdinatesArray , color) => {
    if(Component.map.getLayer(layerId))showTripPath(Component , layerId);
    else addLineLayer(Component.map ,  layerId , coOrdinatesArray , color)
}

export const removeTripPath = (Component ,  layerId ) => {
    removeLayerAndSource(Component.map , layerId);
}

export const hideTripPath = (Component ,  layerId ) => {
    if(Component.map.getLayer(layerId))Component.map.setLayoutProperty(layerId, 'visibility', 'none');
}


export const showTripPath = (Component ,  layerId ) => {
    if(Component.map.getLayer(layerId))Component.map.setLayoutProperty(layerId, 'visibility', 'visible');
}


//navigation in home
export const addNavigationPath = (Component, coOrdinatesArray) => {
    addLineLayer(Component.map ,LAYER_NAVIGATION ,coOrdinatesArray  , '#ed6498'  );
}


export const removeNavigationPath = (Component) => {
    removeLayerAndSource(Component.map , LAYER_NAVIGATION);
}





const removeLayerAndSource = (map, layerId) => {
    if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
        map.removeSource(layerId);
    }
}



