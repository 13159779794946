import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAlarmState } from "../../redux/actions/actions_alarm";

class DropDownSeverity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 2,
      data: null,

      color: "red",
      major: "",
      minor: "",
      security: "",
      emergency: ""
    };
    this.onSeverityChange = this.onSeverityChange.bind(this);
  }

  onSeverityChange(e) {
    var severity = 1;
    switch (e.target.value) {
      case "emergency":
        severity = 1;

        break;
      case "security":
        severity = 2;

        break;
      case "major":
        severity = 3;

        break;
      case "minor":
        severity = 4;

        break;
      default:
        severity = 1;
    }
    var resource_id = this.props.selectedResourceId.resource_id;
    this.props.setAlarmState(
      this.state.alarm_id,
      null,
      null,
      severity,
      resource_id
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.alarm_type_id) {
      this.setState({
        alarm_id: this.props.alarm_type_id
      });
    }

    if (nextProps.currentAlarmState) {
      if (
        nextProps.currentAlarmState.alarm_type_id === this.props.alarm_type_id
      ) {
        this.setState({
          status: nextProps.currentAlarmState.alarm_type_status,
          data: nextProps.currentAlarmState.alarm_type_data
        });

        var type = nextProps.currentAlarmState.alarm_type_data.severity_text;
        switch (type) {
          case "Major":
            this.setState({ major: "selected", color: "yellow" });
            break;
          case "Minor":
            this.setState({ minor: "selected", color: "green" });
            break;
          case "Security":
            this.setState({ security: "selected", color: "red" });
            break;

          default:
            this.setState({ emergency: "selected", color: "red" });
        }
      }
    }
  }

  render() {
    if (this.state.status === 1) {
      return (
        <div className="dropdown-severity-container">
          <div
            className="severity-status-color"
            style={{ background: this.state.color }}
          />
          <select
            className="dropdown-severity-select"
            onChange={this.onSeverityChange}
          >
            <option
              className="dropdown-severity-option"
              selected={this.state.emergency}
              value="emergency"
            >
              {" "}
              Emergency{" "}
            </option>
            <option
              className="dropdown-severity-option"
              selected={this.state.security}
              value="security"
            >
              Security
            </option>
            <option
              className="dropdown-severity-option"
              selected={this.state.major}
              value="major"
            >
              Major
            </option>
            <option
              className="dropdown-severity-option"
              selected={this.state.minor}
              value="minor"
            >
              Minor
            </option>
          </select>
        </div>
      );
    } else {
      return <div className="dropdown-severity-container" />;
    }
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setAlarmState }, dispatch);
}

function mapStateToProps({ currentAlarmState, selectedResourceId }) {
  return { currentAlarmState, selectedResourceId };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownSeverity);
