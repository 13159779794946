import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { seTrackedResourceId, getSingleResourceLocation, clearSearchResources } from '../../redux/actions/actions_resources';

import { bindActionCreators } from 'redux';

import SearchResultListItemVehicle from './search_result_list_item_vehicle'; 

class SearchResultList extends Component {



    renderSearchResultData() {
        var list_length = Object.keys(this.props.searchOwnedVehiclesResult).length;

        if (list_length > 0) {
            return _.map(this.props.searchOwnedVehiclesResult, searchResult => {
                return (
                    <SearchResultListItemVehicle key={searchResult.tracker_uuid} data={searchResult} />
                );
            });
        }
    }



    
    render() {
        var list_length = Object.keys(this.props.searchOwnedVehiclesResult).length;
        if (list_length > 0) {
            return (
                <div className="search-result-list">
                    <ul>
                        {this.renderSearchResultData()}
                    </ul>
                </div>
            )
        } else {
            return (
                <div className="search-result-list">

                </div>
            )
        }

    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ seTrackedResourceId, getSingleResourceLocation, clearSearchResources }, dispatch);
}

function mapStateToProps({ searchOwnedVehiclesResult }) {
    return { searchOwnedVehiclesResult };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultList);