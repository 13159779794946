import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { store } from '../../redux/store';

import { getApiReadyDateFromMomentDate } from '../../utils/functions';

import { setLoadingSpinnerState, setModalStateVehiclePaperView , setModalStateShareLocation } from '../../redux/actions/actions_misc';

import DatePickerCreater from '../misc/date-picker';
import axios from 'axios';

import PlaceHolder from '../../assets/icons/placeholder.svg';


class ModalVehiclePaper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            selectedFilePath: null,
            pageState: "view",
            date: null,
            img: null
        }
    }

    onChangeHandler = event => {
        this.setState({
            selectedFile: event.target.files[0],
            img: URL.createObjectURL(event.target.files[0]),
            loaded: 0,
        });
    }

    handleUploadCLick = () => {
        var paperName = "fitness_paper";
        var bodyFormData = new FormData();
        if (this.props.selectedDate) {
            var date = getApiReadyDateFromMomentDate(this.props.selectedDate.date);
            switch (this.props.selectedPaper.paperName) {
                case "Fitness":
                    paperName = "fitness_paper"
                    bodyFormData.set('fitness_date', date);
                    break;
                case "Insurance":
                    paperName = "insurance_paper"
                    //dateName = { "insurance_date": date }
                    bodyFormData.set('insurance_date', date);
                    break;
                case "Registration":
                    paperName = "registration_paper_front";
                    //dateName = { reg_date: date }
                    bodyFormData.set('reg_date', date);
                    break;
                default:
                    paperName = "tax_token_paper";
                    //dateName = { tax_token_date: date }
                    bodyFormData.set('tax_token_date', date);
                    break;
            }

        }

        const token = `jwt ${store.getState().jwtToken}`;

        if (this.state.pageState === "view") {
            this.setState({ pageState: "edit" })
        } else {
            this.setState({ pageState: "view" })
            const data = new FormData()
            var vehicleId = this.props.ownedResources[this.props.selectedResourceId.resource_id].vehicle_id;
            this.props.setLoadingSpinnerState(true);
            if (this.state.img) {
                data.append(paperName, this.state.selectedFile);
                axios.patch(`https://followr.dingi.tech/instance/api/vehicles/${vehicleId}/`, data, {
                    headers: { 'Authorization': token }
                }).then(res => {
                    //this.props.setModalStateVehiclePaperView(false);
                })
            } else {
                console.log('it has no image')
            }
            axios.patch(`https://followr.dingi.tech/instance/api/vehicles/${vehicleId}/`, bodyFormData, {
                headers: { 'Authorization': token }
            }).then(res => {
                this.props.setModalStateVehiclePaperView(false);
                this.props.setLoadingSpinnerState(false);
            })

        }
    }



    componentDidMount() {
        this.setState({ pageState: this.props.selectedPaper.mode });
    }

    componentWillReceiveProps(nextProps) {
        var date = null;
        var img = null;
        if (nextProps.selectedPaper) {
            switch (nextProps.selectedPaper.paperName) {
                case "Fitness":
                    date = nextProps.allVehicles[nextProps.selectedResourceId.resource_id].fitness_date;
                    img = this.props.allVehicles[this.props.selectedResourceId.resource_id].fitness_paper;
                    break;
                case "Insurance":
                    date = nextProps.allVehicles[nextProps.selectedResourceId.resource_id].insurance_date;
                    img = nextProps.allVehicles[nextProps.selectedResourceId.resource_id].insurance_paper;
                    break;
                case "Registration":
                    date = nextProps.allVehicles[nextProps.selectedResourceId.resource_id].reg_date;
                    img = nextProps.allVehicles[nextProps.selectedResourceId.resource_id].registration_paper_front;
                    break;
                default:
                    img = this.props.allVehicles[this.props.selectedResourceId.resource_id].tax_token_paper;
                    date = nextProps.allVehicles[nextProps.selectedResourceId.resource_id].tax_token_date;
                    break;
            }

            this.setState({ date, img });
        }
    }

    render() {

        return (


            <div className="modal display-block">

                <div className="modal-vehiclepaper-container">
                    <div className="modal-title-cross">
                        <div className="modal-title">
                            {this.props.selectedPaper.paperName} Details Update
                           </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateVehiclePaperView(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>


                    {this.state.pageState === "view" ? <div className="dropdown-with-title">
                        <span className="title-over-dropdown">  Expiry Date</span>
                        <div className="dropdown-with-icon-in-modal">
                            <i className="icon-action-event icon-dropdown-box" />
                            <div className="date-text-paper-update"> {this.state.date}</div>
                        </div>
                    </div>
                        : <div className="dropdown-with-title">
                            <span className="title-over-dropdown">Expiry Date</span>
                            <div className="dropdown-with-icon-in-modal">
                                <i className="icon-action-event icon-dropdown-box" />
                                <DatePickerCreater page="modal" />
                            </div>
                        </div>}

                    <div className="dropdown-with-title">
                        <span className="title-over-dropdown"> <i className="icon-file-attachment" /> Paper Image</span>
                        <div className="image-with-upload-btn">

                            {this.state.img ? < img alt ="none" className="vehicle-paper-image" src={this.state.img} /> : <img alt ="none" className="vehicle-paper-image" src={PlaceHolder} />}
                            {this.state.pageState === "view" ? <div></div>
                                : <input className="btn-choose-file" type="file" name="file" onChange={this.onChangeHandler} />
                            }
                        </div>

                    </div>

                    {this.state.pageState === "view" ? <div className="btn-icon-txt-blue-filled" onClick={this.handleUploadCLick}>
                        <i className="icon-image-edit" />
                        <button> Update</button>
                    </div>
                        : <div className="btn-icon-txt-blue-filled" onClick={this.handleUploadCLick}>
                            <i className="icon-content-add" />
                            <button> Save</button>
                        </div>}

                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoadingSpinnerState, setModalStateVehiclePaperView  , setModalStateShareLocation}, dispatch);
}

function mapStateToProps({
    selectedMaintenanceLog,
    selectedPaper,
    allVehicles,
    selectedResourceId,
    selectedDate,
    ownedResources

}) {
    return {
        selectedMaintenanceLog,
        selectedPaper,
        allVehicles,
        selectedResourceId,
        selectedDate,
        ownedResources
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalVehiclePaper);