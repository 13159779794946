import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { setModalStateIssueEntry, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { createIssueEntry } from '../../redux/actions/actions_auth';



class ModalMaintenanceEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            type: null
        }
        this.submitData = this.submitData.bind(this);
    }

    submitData() {
        if (this.validateData()) {
            var issueData = {
                title: this.state.title,
                description: this.state.description,
                status: 1,
                priority: 3
            }
            this.props.setLoadingSpinnerState(true);
            this.props.createIssueEntry(issueData);
        }
    }


    validateData() {
        if (this.state.title === "") {
            return false;
        } else if (!this.state.description === "") {
            return false;
        }else {
            return true;
        }
    }


    componentWillReceiveProps(nextProps) {


        if (nextProps.createSupportEntryResponse) {
            if (nextProps.createSupportEntryResponse.status === "success") {
                this.setState({
                    description: "",
                    title: ""
                });
            }
            this.props.setLoadingSpinnerState(false);
            this.props.setModalStateIssueEntry(false);
        }
    }

    render() {
        return (
            <div className="modal">
                <div className="modal-fuel-container">
                    <div className="modal-title-cross">
                        <div className="modal-title">
                            New Issue
                        </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateIssueEntry(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>


                    <form>
                        <span className="title-over-dropdown">  *If you face any issue please describe</span>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-action-label icon-text-input-box" />
                            <input className="text-shadow" placeholder="Issue Title *" type="text" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />
                        </div>
                        <div className="text-with-icon-in-modal-large">
                            <i className="icon-content-sort icon-text-input-box-large" />
                            <textarea rows="5"  className="text-shadow" placeholder="Description " type="text" value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })} />
                        </div>

                    </form>


                    <div className="btn-icon-txt-blue-filled" onClick={this.submitData}>
                        <i className="icon-action-send btn-icon" />
                        <div> Submit </div>
                    </div>


                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoadingSpinnerState, createIssueEntry, setModalStateIssueEntry }, dispatch);
}

function mapStateToProps({
    createSupportEntryResponse,
    selectedDate,
    selectedResourceId,


}) {
    return {

        createSupportEntryResponse,
        selectedDate,
        selectedResourceId,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMaintenanceEntry);