export const BASE_URL = "https://followr.dingi.tech/";
//export const BASE_URL = "http://followr.dingi.tech:8080/";
//export const BASE_URL = "https://testfollowr.dingi.tech/";

export const BANGLA_STYLE =
  "https://tilesbd.dingi.live/styles/Combined/style.json";
export const ENGLISH_STYLE =
  "https://tilesbd.dingi.live/styles/Combined/style.json";
//export const API_TOKEN     =  "EjFUMTUMKFcnJ2VzRnL39Cd2ixtHScJ2p0C1vhP0";
export const API_TOKEN = "1234rtyu";
