import {
    SELECT_TRIP_ID,
    GET_TRIPS_SINGLE_RESOURCE,
    GET_TREND_DATA,
    SET_TREND_TYPE,
    GET_FUEL_LOG,
    CREATE_FUEL_ENTRY,
    SELECT_MAINTENANCE_LOG,
    SELECT_PAPER,
    SELECT_MAINTENANCE_TYPE_ENTRY,
    CREATE_MAINTENANCE_ENTRY,
    GET_MAINTENANCE_ENTRYS,
    CREATE_MAINTENANCE_TYPE_ENTRY,
    GET_MAINTENANCE_ENTRY_TYPES,
    SET_MAP_TRACKER,
    GET_SINGLE_RESOURCE_LOCATION,
    GET_ALL_RESOURCES_LOCATION,
    SEARCH_ALL_RESOURCES,
    SELECT_RESOURCE_ID,
    GET_ALL_RESOURCES,
    GET_HISTORY_SINGLE_RESOURCE ,
    GET_ALL_VEHICLES ,
    SELECT_VEHICLE_ID, 
    GET_NORMAL_DETAILS_VEHICLE, 
    GET_PAPER_DETAILS_VEHICLE, 
    GET_MAINTENANCE_DETAILS_VEHICLE, 
    GET_REFUELING_DETAILS_VEHICLE
} from '../../assets/constants/action_types';
import { BASE_URL } from '../../assets/constants/app_constants';
import { fetchAndReturnData, putDataAndReturnResponse } from '.';
import { formateDateTimeForTrip, getApiReadyDateFromMomentDateNormal } from '../../utils/functions';
import moment from "moment";

export function getAllResources() {
    const url = `${BASE_URL}resource/api/resources/`;
    return fetchAndReturnData(url, GET_ALL_RESOURCES);
}

export function getAllResourcesLocation() {
    const url = `${BASE_URL}resource/api/locations/`;
    return fetchAndReturnData(url, GET_ALL_RESOURCES_LOCATION);
}

export function searchAllResources(term) {
    const url = `${BASE_URL}resource/api/resources/?search=${term}`;
    return fetchAndReturnData(url, SEARCH_ALL_RESOURCES);
}

export function clearSearchResources() {
    return {
        payload: {},
        type: SEARCH_ALL_RESOURCES
    };
}

export function getSingleResourceLocation(id) {
    const url = `${BASE_URL}resource/api/resources/${id}/`;
    return fetchAndReturnData(url, GET_SINGLE_RESOURCE_LOCATION);
}

export function getSingleResourceHistory(resourceId, date) {
    var formattedDate = moment(date).format("DD-MM-YYYY");
    const url = `${BASE_URL}resource/api/report?after_date=${formattedDate}&resource_id=${resourceId}`;
    return fetchAndReturnData(url, GET_HISTORY_SINGLE_RESOURCE);
}

export function getSingleResourceTrips(resourceId, date, fromTime, toTime, tripCount) {
    var url = `${BASE_URL}resource/api/trip/?resource_id=${resourceId}`
    
    if (tripCount) url = `${url}&trip_limit=${tripCount}`;
    
    var apiFromTime = formateDateTimeForTrip(date, fromTime);
    url = `${url}&from=${apiFromTime}`;

    var apiToTime = formateDateTimeForTrip(date, toTime);
    url = `${url}&to=${apiToTime}`;

    return fetchAndReturnData(url, GET_TRIPS_SINGLE_RESOURCE);
}


export function selectResourceId(id, intention) {
    return {
        type: SELECT_RESOURCE_ID,
        payload: {
            resource_id: id,
            intention
        }
    };
}

export function selectTripId(id) {
    return {
        type: SELECT_TRIP_ID,
        payload: id
    }
}

export function seTrackedResourceId(mode) {
    return {
        type: SET_MAP_TRACKER,
        payload: mode
    }
}

//maintenance entry

export function createMaintenanceEntryType(name, period_days) {
    const url = `${BASE_URL}resource/api/maintenancetype/`;
    return putDataAndReturnResponse(url, { name, period_days }, CREATE_MAINTENANCE_TYPE_ENTRY)
}



export function getMaintenanceTypes() {
    const url = `${BASE_URL}resource/api/maintenancetype/`;
    return fetchAndReturnData(url, GET_MAINTENANCE_ENTRY_TYPES)
}


export function createMaintenanceEntry(data) {
    const url = `${BASE_URL}resource/api/maintenance/`;
    return putDataAndReturnResponse(url, data, CREATE_MAINTENANCE_ENTRY);
}


export function createFuelEntry(data) {
    const url = `${BASE_URL}resource/api/fuellog/`;
    return putDataAndReturnResponse(url, data, CREATE_FUEL_ENTRY)
}





export function getMaintenanceEntrys(resourceId) {
    const url = `${BASE_URL}resource/api/maintenance/?search=${resourceId}`;
    console.log('get maintenance entry url  ', url);
    return fetchAndReturnData(url, GET_MAINTENANCE_ENTRYS);
}

export function getTrendData(period, resourceId) {
    var today = new Date();
    var last = new Date(today.getTime() - (period * 24 * 60 * 60 * 1000));
    var to = getApiReadyDateFromMomentDateNormal(today);
    var from = getApiReadyDateFromMomentDateNormal(last);
    const url = `${BASE_URL}resource/api/trend?resource_id=${resourceId}&from_date=${from}&end_date=${to}`;
    return fetchAndReturnData(url, GET_TREND_DATA)
}

export function getFuelLogs(res_id) {
    const url = `${BASE_URL}resource/api/fuellog/?search=${res_id}`;
    console.log(url);
    return fetchAndReturnData(url, GET_FUEL_LOG)
}


export function selectMaintenanceEntryType(maintenanceEntryType) {
    return {
        type: SELECT_MAINTENANCE_TYPE_ENTRY,
        payload: maintenanceEntryType
    }
}


export function selectMaintenanceLog(data) {
    return {
        type: SELECT_MAINTENANCE_LOG,
        payload: data
    }
}


export function selectPaper(data) {
    return {
        type: SELECT_PAPER,
        payload: data
    }
}

export function setTrendType(type) {
    return {
        type: SET_TREND_TYPE,
        payload: type
    }
}



//

export function getAllVehicles() {
    const url = `${BASE_URL}instance/api/vehicles`;
    return fetchAndReturnData(url, GET_ALL_VEHICLES);
}

export function getNormalDetailsSingleVehicle(resource_id) {
    const url = `${BASE_URL}resource/api/resources/${resource_id}/`;
    return fetchAndReturnData(url, GET_NORMAL_DETAILS_VEHICLE);
}


export function getPaperDetailsSingleVehicle(resource_id) {
    const url = `${BASE_URL}instance/api/vehicles/${resource_id}/`;
    return fetchAndReturnData(url, GET_PAPER_DETAILS_VEHICLE);
}


export function getMaintenanceDetailsSingleReource(resource_id) {
    const url = `${BASE_URL}resource/api/maintenance/?resource=${resource_id}`;
    return fetchAndReturnData(url, GET_MAINTENANCE_DETAILS_VEHICLE);
}


export function getRefuelingDetailsSingleReource(resource_id) {
    const url = `${BASE_URL}resource/api/fuel_logs/?resource=${resource_id}`;
    return fetchAndReturnData(url, GET_REFUELING_DETAILS_VEHICLE);
}


export function selectVehicleId(id) {
    return {
        type: SELECT_VEHICLE_ID,
        payload: id
    };
}









