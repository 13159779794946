import React, { Component } from "react";
import "../../assets/styles/side_nav_bar.css";
import { Link } from "react-router-dom";
import * as ROUTES from "../../assets/constants/routes";
import appIcon from "../../assets/icons/icon.png";

export default class SideNavBar extends Component {
  render() {
    return (
      <div className="sidebar">
        <div className="side-bar-icon-container-logo">
          <Link to={ROUTES.DEFAULT}>
            <img className="side-bar-app-icon" src={appIcon} alt="nothing" />
          </Link>
        </div>
        <div className="side-menus">
          <Link to={ROUTES.DEFAULT} title="Home">
            <i className="icon-action-home" />
          </Link>

          <Link to={ROUTES.ALARM} title="Alarm">
            <i className="icon-social-notifications-none" />
          </Link>

          <Link to={ROUTES.SETTINGS} title="Settings">
            <i className="icon-action-settings" />
          </Link>

          <Link to={ROUTES.VEHICLES} title="Vehicles">
            <i className="icon-maps-car-multi" />
          </Link>

          <Link to={ROUTES.PAPERS} title="Papers">
            <i className="icon-action-assignment" />
          </Link>

          <Link to={ROUTES.REPORT} title="Report">
            <i className="icon-editor-bar-chart" />
          </Link>

          <Link to={ROUTES.TRIPS} title="Trips">
            <i className="icon-action-pulse" />
          </Link>

          <Link to={ROUTES.FUEL} title="Fuel">
            <i className="icon-maps-local-gas-station" />
          </Link>

          <Link to={ROUTES.BILLING} title="Billing">
            <i className="icon-maps-local-bank" />
          </Link>
        </div>
      </div>
    );
  }
}
