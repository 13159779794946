//resource
export const SEARCH_ALL_RESOURCES                  = 'SEARCH_ALL_RESOURCES';
export const GET_ALL_RESOURCES                     = 'GET_OWNED_RESOURCES';
export const GET_ALL_RESOURCES_LOCATION                = 'GET_ALL_RESOURCES_LOCATION';
export const GET_SINGLE_RESOURCE_LOCATION          = 'GET_SINGLE_RESOURCE_LOCATION';
export const GET_HISTORY_SINGLE_RESOURCE            = "GET_HISTORY_SINGLE_RESOURCE";
export const GET_TRIPS_SINGLE_RESOURCE            = "GET_TRIPS_SINGLE_RESOURCE";
export const SET_MAP_TRACKER                         = 'SET_MAP_TRACKER';
        
        
//alarms        
export const GET_ALARMS                              = 'GET_ALARMS';
export const GET_SERVER_TIME                         = 'GET_SERVER_TIME';
export const GET_CURRENT_ALARM                       = 'GET_CURRENT_ALARM';
export const GET_SPECIFIC_ALARM_DETAIL               = 'GET_SPECIFIC_ALARM_DETAIL';
export const GET_SPECIFIC_ALARM_PATH                 = 'GET_SPECIFIC_ALARM_PATH';
        
        
        
//settings
export const GET_AVAILABLE_ALARMS_FOR_USER           = 'GET_AVAILABLE_ALARMS_FOR_USER';
export const GET_CURRENT_ALARM_SETTINGS              = 'GET_CURRENT_ALARM_SETTINGS';
export const GET_ALL_VEHICLES                        = 'GET_ALL_VEHICLES';
export const SET_SINGLE_ALARM_STATE                  = 'SET_SINGLE_ALARM_STATE';
export const CREATE_NEW_ALARM                        = 'CREATE_NEW_ALARM';
        
    
        

//vehicles        
export const GET_NORMAL_DETAILS_VEHICLE            = 'GET_NORMAL_DETAILS_VEHICLES';
export const GET_PAPER_DETAILS_VEHICLE             = 'GET_PAPER_DETAILS_VEHICLES';
export const GET_MAINTENANCE_DETAILS_VEHICLE       = 'GET_MAINTENANCE_DETAILS_VEHICLES';
export const GET_REFUELING_DETAILS_VEHICLE         = 'GET_REFUELING_DETAILS_VEHICLES';


//auth
export const SIGN_IN_USER = "SIGN_IN_USER";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SAVE_CREDENTIALS = "SAVE_CREDENTIALS"
export const SET_CREDENTIAL_REMEMBERED_STATE = "SET_CREDENTIAL_REMEMBERED_STATE"

//billing
export const GET_ALL_BILLS                           = "GET_ALL_BILLS";
export const PAY_WITH_SSL                           = "PAY_WITH_SSL";



//misc
export const SET_SPINNER_STATE                       = "SET_SPINNER_STATE";
export const SET_REPLAY_INDEX                        = "SET_REPLAY_INDEX";
export const SET_TOPBAR_TITLE                        = "SET_TOPBAR_TITLE";
export const SET_SHARABLE_LOCATION_URL               = "SET_SHARABLE_LOCATION_URL";
export const SET_MODAL_VEHICLE_STATE                 = "SET_MODAL_VEHICLE_STATE";
export const SET_MODAL_ALARM_REPLAY_STATE            = "SET_MODAL_ALARM_REPLAY_STATE";
export const SET_MODAL_MAINTENANCE_ENTRY_STATE       = 'SET_MODAL_MAINTENANCE_ENTRY_STATE';
export const SET_MODAL_REFUEL_ENTRY_STATE            = 'SET_MODAL_REFUEL_ENTRY_STATE';
export const SET_MODAL_SHARELOCATION_STATE           = 'SET_MODAL_SHARELOCATION_STATE';
export const SET_MODAL_STATE_RADIUS_ALARM_SET        = 'SET_MODAL_STATE_RADIUS_ALARM_SET';

export const SET_MODAL_TREND_VIEW_STATE              = 'SET_MODAL_TREND_VIEW_STATE';
export const SET_MODAL_ISSUE_ENTRY_STATE             = 'SET_MODAL_ISSUE_ENTRY_STATE';

export const SELECT_RESOURCE_ID                      = 'SELECT_RESOURCE_ID';
export const SELECT_ALARM_TYPE                       = 'SELECT_ALARM_TYPE';
export const SELECT_TRIP_COUNT                       = 'SELECT_TRIP_COUNT';
export const SELECT_VEHICLE_ID                       = 'SELECT_VEHICLE_ID';
export const SELECT_TRIP_ID                          = 'SELECT_TRIP_ID';
export const SELECT_DATE                             = 'SELECT_DATE';
export const SET_TIME                                = 'SET_TIME';

export const GET_REVERSE_GEOCODE_ADDRESS             = "GET_REVERSE_GEOCODE_ADDRESS";
export const GET_NEARBY_FUEL_STATIONS                = "GET_NEARBY_FUEL_STATIONS";
export const SEARCH_TEXT                             = "SEARCH_TEXT";
export const GET_PATH_FROM_SRC_DEST                  = "GET_PATH_FROM_SRC_DEST";
export const SET_VISIBILITY_TO_RESOURCE_PATH         = "SET_VISIBILITY_TO_RESOURCE_PATH";
export const SET_RESOURCE_ID_FOR_DETAILS_VIEW_MAP    = "SET_RESOURCE_ID_FOR_DETAILS_VIEW_MAP"
export const SET_TRIP_REPLAY_STATE                   = "SET_TRIP_REPLAY_STATE"


//profile
export const GET_USER_DETAILS                       = "GET_USER_DETAILS";
export const SET_USER_DETAILS                       = "SET_USER_DETAILS";

//support
export const GET_ALL_SUPPORT_ENTRY = "GET_ALL_SUPPORT_ENTRY"; 
export const POST_SUPPORT_ENTRY = "POST_SUPPORT_ENTRY"; 


//maintenance entry

export const SELECT_MAINTENANCE_TYPE_ENTRY          = "SELECT_MAINTENANCE_TYPE_ENTRY";
export const CREATE_MAINTENANCE_TYPE_ENTRY          = "CREATE_MAINTENANCE_TYPE_ENTRY";
export const GET_MAINTENANCE_ENTRY_TYPES            = "GET_MAINTENANCE_ENTRY_TYPES";
export const CREATE_MAINTENANCE_ENTRY               = "CREATE_MAINTENANCE_ENTRY";
export const CREATE_FUEL_ENTRY                      = "CREATE_FUEL_ENTRY";
export const GET_MAINTENANCE_ENTRYS                 = "GET_MAINTENANCE_ENTRYS";
export const GET_FUEL_LOG                           = "GET_FUEL_LOG";

export const SELECT_MAINTENANCE_LOG                 = "SELECT_MAINTENANCE_LOG"
export const SET_MAINTENANCE_LOG_VIEW_MODAL_STATE   = "SET_MAINTENANCE_LOG_VIEW_MODAL_STATE"
export const SET_VEHICLE_PAPER_VIEW_MODAL_STATE     = "SET_VEHICLE_PAPER_VIEW_MODAL_STATE"
export const SET_TREND_TYPE                         = "SET_TREND_TYPE"
export const SELECT_PAPER                           = "SELECT_PAPER"
export const GET_TREND_DATA                         = "GET_TREND_DATA"
export const SELECT_DATE_INTERVAL                   = "SELECT_DATE_INTERVAL"


