import { combineReducers } from "redux";




import {
    selectedVehicleDetails,
    selectedVehicleId,
    allResourcesLocation,
    allVehicles,
    historySingleResource,
    trackedResourceId,
    singleResourceLocation,
    ownedResources,
    searchOwnedVehiclesResult,
    selectedResourceId,
    maintenanceEntryTypes,
    createMaintenanceEntryTypeResponse,
    maintenanceEntries,
    createMaintenanceEntryResponse,
    selectedMaintenanceEntryType,
    selectedMaintenanceLog,
    selectedPaper,
    createFuelEntryResponse,
    fuelLogs,
    selectedTrendType,
    trendData ,
    tripsSingleResource ,
    selectedTripId

} from './reducer_resources';



import {
    alarms,
    specificAlarmDetail,
    specificAlarmPath,
    availableAlarmsForUser,
    currentAlarmState,
    settingAlarmResponse,
    serverTime ,
    selectedAlarmType

} from './reducer_alarms';


import {
    signInResponse,
    jwtToken,
    isTokenValid,
    signInCredential,
    signInCredentialRememberState,
    allBills,
    createSSLResponse,
    userDetails,
    setUserDetailsResponse,
    allIssues,
    createSupportEntryResponse
} from './reducer_auth';


import {
    selectedDate,
    topbarTitle ,
    spinnerState,
    selectedInterval ,
    reverseGeocodedAddress ,
    searchFreeTextResult,
    resourceIdForDetailsInMap ,
    navigationPath ,
    visStatePathToResource ,
    nearbyFuelStations ,
    tripReplayState ,
    tripCount ,
    selectedTime ,

    modalStateVehicleDetails
    , alarmReplayState
    , maintenanceEntryModalState
    , maintenanceLogViewState
    , paperDetailsViewState
    , refuelEntryModal
    , trendViewModal
    , issueEntryModal
    , modalStateShareLocation ,
    modalStateRadiusAlarmSet ,

    sharableLocationUrl ,
    selectedReplayIndex
} from './reducer_misc';

const rootReducer = combineReducers({

    trackedResourceId,
    searchOwnedVehiclesResult,
    ownedResources,
    allResourcesLocation,
    singleResourceLocation,
    selectedVehicleId,
    selectedVehicleDetails,
    historySingleResource,
    allVehicles,
    serverTime,
    selectedResourceId,

    alarms,
    specificAlarmDetail,
    specificAlarmPath,
    availableAlarmsForUser,
    currentAlarmState,
    settingAlarmResponse, 
    selectedAlarmType,

    selectedDate,
    topbarTitle,
   
    spinnerState,
    tripCount,

    tripReplayState,

    jwtToken,
    isTokenValid,
    signInResponse,
    signInCredential,
    signInCredentialRememberState,

    allBills,
    createSSLResponse,
    userDetails,
    setUserDetailsResponse,


    maintenanceEntryTypes,
    createMaintenanceEntryTypeResponse,
    maintenanceEntries,
    createMaintenanceEntryResponse,
    selectedMaintenanceEntryType,
    selectedMaintenanceLog,
    selectedPaper,
    createFuelEntryResponse,
    fuelLogs,
    selectedTrendType,
    trendData,
    selectedInterval,

    allIssues,
    createSupportEntryResponse ,

    reverseGeocodedAddress ,
    resourceIdForDetailsInMap ,

    navigationPath ,
    visStatePathToResource ,
    tripsSingleResource ,
    selectedTripId ,
    nearbyFuelStations,
    searchFreeTextResult ,
    selectedTime


    , modalStateVehicleDetails
    , modalStateRadiusAlarmSet
    , alarmReplayState
    , maintenanceEntryModalState
    , maintenanceLogViewState
    , paperDetailsViewState
    , refuelEntryModal
    , trendViewModal
    , issueEntryModal
    , modalStateShareLocation


    ,sharableLocationUrl,
    selectedReplayIndex
});

export default rootReducer;