import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectDateInterval } from '../../redux/actions/actions_misc';


class DropdownDaysOption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seven: '',
            fifteen: '',
            thirty: ''
        };
        this.daysChanged = this.daysChanged.bind(this);
    }

    daysChanged(e) {
        var days = 7;
        console.log('asdfa');
        switch (e.target.value) {
            case "seven":
                days = 7;
                break;
            case "fifteen":
                days = 15;
                break;
            case "thirty":
                days = 30;
                break;
            default:
        }
        this.props.selectDateInterval(days);
    }

    render() {
        return (

            <div className="dropdown-basic-container">
                <div className="dropdown-basic-icon-container">
                    <i className="icon-action-query-builder" />
                </div>
                <div className="dropdown-basic-title-data">

                    <div className="dropdown-basic-title"> Show </div>
                    <div className="dropdown-basic-data">
                        <select className="dropdown-basic-selection" onChange={this.daysChanged}>
                            <option className="dropdown-basic-option" selected={this.state.seven} value="seven">7 Days</option>
                            <option className="dropdown-basic-option" selected={this.state.fifteen} value="fifteen">15 Days</option>
                            {/* <option className="dropdown-basic-option" selected={this.state.thirty} value="thirty">30 Days</option> */}
                        </select>
                    </div>
                </div>
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectDateInterval }, dispatch);
}

function mapStateToProps({ currentAlarmState, selectedResourceId }) {
    return { currentAlarmState, selectedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownDaysOption);