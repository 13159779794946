import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FuelTable from '../tables/table_fuel';
import DropDownResources from '../dropdowns/dropdown_resources';
import { setTopbarTitle , setModalStateRefuelEntry } from '../../redux/actions/actions_misc';

class FuelPage extends Component {

    componentDidMount(){
        this.props.setTopbarTitle("Fuel");
    }

    render() {
        return (

            <div className="fuel-page-container">
                <div className="fuel-page-topbar">

                    <DropDownResources />
                    <div></div>
                    <div className="btn-icon-txt-blue-filled" onClick={() => this.props.setModalStateRefuelEntry(true)}>
                        <i className="icon-content-add" />
                        <div>ReFuel Entry</div>
                    </div>

                </div>
                <div className="fuel-page-mainbar">
                    <FuelTable />
                </div>
            </div>

        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTopbarTitle , setModalStateRefuelEntry }, dispatch);
}

function mapStateToProps({ ownedResources, allVehicles, selectedResourceId }) {
    return { ownedResources, allVehicles, selectedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelPage);