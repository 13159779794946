import React from 'react';
import '../../assets/styles/notification_bar.css';

import Popup from "reactjs-popup";


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAlarmsForNotification, getDetailsSpecificAlarm } from '../../redux/actions/actions_alarm';
import { setModalStateAlarmReplay } from '../../redux/actions/actions_misc';
import { getTimeAgoFromUtcTime } from '../../utils/functions';


import { Link } from "react-router-dom";
import * as ROUTES from '../../assets/constants/routes';



class NotificationBar extends React.Component {





    onReplayClicked(id) {
        this.props.getDetailsSpecificAlarm(id);
        this.props.setModalStateAlarmReplay(true);

    }

    componentDidMount() {
        this.props.getAlarmsForNotification(null, null, null , null);
    }

    renderNotifications() {
        if (this.props.alarms) {
            const results = this.props.alarms.results;
            if (results) {
                return results.map(singleData => (
                    <div onClick={() => this.onReplayClicked(singleData.id)} key={singleData.id} >
                        <SingleNotificationItem data={singleData} />
                    </div>
                ));
            }
            return <div></div>
        }
    }


    render() {
        return (
            <Popup  className="notification-container" trigger={<button style={{justifySelf:"right"}}  className="btn-notification"> <i className="icon-social-notifications-none" /> </button>} position="bottom left">
                {this.renderNotifications()}
                <Link to={ROUTES.ALARM}>
                    <button className="btn-noti-see-more">see more</button>
                </Link>
            </Popup>
        );
    }
}


function SingleNotificationItem(props) {
    var timeAgo = getTimeAgoFromUtcTime(props.data.iso_time)
    return (
        <div className="notification-item">

            <div   className="notification-item-icon">
                <i className="icon-maps-car-vibration" />
            </div>


            <div className="notification-item-details">

                <div className="notification-item-details-title">
                    {props.data.title}
                </div >

                <div className="text-red notification-item-details-driver">
                    <div>{props.data.type_text}</div>

                </div >

                <div className="text-blue">
                    <i className="icon-action-query-builder" /> {timeAgo}
                </div>

            </div>



        </div>
    )
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAlarmsForNotification, setModalStateAlarmReplay, getDetailsSpecificAlarm }, dispatch);
}

function mapStateToProps({ alarms }) {
    return { alarms };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar);
