import {
    GET_ALL_VEHICLES,
    GET_ALL_RESOURCES_LOCATION,
    GET_HISTORY_SINGLE_RESOURCE,
    SET_MAP_TRACKER,
    GET_SINGLE_RESOURCE_LOCATION,
    GET_ALL_RESOURCES,
    SEARCH_ALL_RESOURCES,
    GET_NORMAL_DETAILS_VEHICLE,
    GET_PAPER_DETAILS_VEHICLE,
    SELECT_VEHICLE_ID,
    SELECT_RESOURCE_ID,
    GET_MAINTENANCE_ENTRY_TYPES,
    CREATE_MAINTENANCE_TYPE_ENTRY,
    CREATE_MAINTENANCE_ENTRY,
    GET_MAINTENANCE_ENTRYS,
    SELECT_MAINTENANCE_TYPE_ENTRY,
    CREATE_FUEL_ENTRY,
    SELECT_MAINTENANCE_LOG,
    SELECT_PAPER,
    GET_FUEL_LOG ,
    SET_TREND_TYPE ,
    GET_TREND_DATA ,
    SELECT_TRIP_ID,
    GET_TRIPS_SINGLE_RESOURCE

} from '../../assets/constants/action_types';
import _ from 'lodash';


const allVehicles = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_VEHICLES:
            return _.mapKeys(action.payload.results, 'resource_id');
        default:
            return state;
    }
};

const ownedResources = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_RESOURCES:
            return _.mapKeys(action.payload.results, 'id');
        default:
            return state;
    }
};


const allResourcesLocation = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_RESOURCES_LOCATION:
            return _.mapKeys(action.payload, 'id');
        default:
            return state;
    }
};

const historySingleResource = (state = {
    report: {}, summary: {}
}, action) => {
    switch (action.type) {
        case GET_HISTORY_SINGLE_RESOURCE:
            return action.payload;
        default:
            return state;
    }
};

const tripsSingleResource = (state = null , action) => {
    switch(action.type){
        case GET_TRIPS_SINGLE_RESOURCE:
            return action.payload;
        default:
            return state;
    }
}


const singleResourceLocation = (state = null, action) => {
    switch (action.type) {
        case GET_SINGLE_RESOURCE_LOCATION:
            return action.payload;
        default:
            return state;
    }
};



const trackedResourceId = (state = null, action) => {
    switch (action.type) {
        case SET_MAP_TRACKER:
            return action.payload;
        default:
            return state;
    }
};


const searchOwnedVehiclesResult = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_ALL_RESOURCES:
            return _.mapKeys(action.payload.results, 'id');
        default:
            return state;
    }
};



const selectedVehicleDetails = (state = {
    type: 'none',
    data: null
}, action) => {
    switch (action.type) {
        case GET_NORMAL_DETAILS_VEHICLE:
            return {
                type: 'normal',
                data: action.payload
            };
        case GET_PAPER_DETAILS_VEHICLE:
            return {
                type: 'paper',
                data: action.payload
            };
        default:
            return state;
    }
};


const selectedVehicleId = (state = {}, action) => {
    switch (action.type) {
        case SELECT_VEHICLE_ID:
            return action.payload;
        default:
            return state;
    }
};


const selectedResourceId = (state = null, action) => {

    switch (action.type) {
        case SELECT_RESOURCE_ID:
            return action.payload;
        default:
            return state;
    }
};
const selectedTripId = (state = -1, action) => {

    switch (action.type) {
        case SELECT_TRIP_ID:
            return action.payload;
        default:
            return state;
    }
};

const maintenanceEntryTypes = (state = null, action) => {
    switch (action.type) {
        case GET_MAINTENANCE_ENTRY_TYPES:
            return action.payload;
        default:
            return state;
    }
};

const createMaintenanceEntryTypeResponse = (state = null, action) => {
    switch (action.type) {
        case CREATE_MAINTENANCE_TYPE_ENTRY:
            if (action.payload.status === "success") {
                return action.payload.response;
            } else {
                return state;
            }
        default:
            return state;
    }
};


const maintenanceEntries = (state = null, action) => {
    switch (action.type) {
        case GET_MAINTENANCE_ENTRYS:
            return action.payload;
        default:
            return state;
    }
};

const fuelLogs = (state = null, action) => {
    switch (action.type) {
        case GET_FUEL_LOG:
            return action.payload;
        default:
            return state;
    }
};




const createMaintenanceEntryResponse = (state = null, action) => {
    switch (action.type) {
        case CREATE_MAINTENANCE_ENTRY:
            console.log(action.payload);
            return action.payload.response;

        default:
            return state;
    }
};

const createFuelEntryResponse = (state = null, action) => {
    switch (action.type) {
        case CREATE_FUEL_ENTRY:
            console.log(action.payload);
            return action.payload;
        default:
            return state;
    }
};





const selectedMaintenanceEntryType = (state = null, action) => {
    switch (action.type) {
        case SELECT_MAINTENANCE_TYPE_ENTRY:
            return action.payload;
        default:
            return state;
    }
}


const selectedMaintenanceLog = (state = null, action) => {
    switch (action.type) {
        case SELECT_MAINTENANCE_LOG:
            console.log('actin ,.pauload ', action.payload);
            return action.payload;
        default:
            return state;
    }

}

const selectedPaper = (state = null, action) => {
    switch (action.type) {
        case SELECT_PAPER:
            return action.payload;
        default:
            return state;
    }
}


const selectedTrendType = (state = null, action) => {
    switch (action.type) {
        case SET_TREND_TYPE:
            console.log(action.payload);
            return action.payload;
        default:
            return state;
    }
}

const trendData = ( state = null , action) => {
    switch(action.type) {
        case GET_TREND_DATA :
            return action.payload;
        default:
            return state;
    }
}

export {
    allVehicles,
    allResourcesLocation,
    historySingleResource,
    trackedResourceId,
    singleResourceLocation,
    ownedResources,
    searchOwnedVehiclesResult,
    selectedVehicleDetails,
    selectedVehicleId,
    selectedResourceId,
    maintenanceEntryTypes,
    createMaintenanceEntryTypeResponse,
    maintenanceEntries,
    createMaintenanceEntryResponse,
    selectedMaintenanceEntryType,
    selectedMaintenanceLog,
    selectedPaper,
    createFuelEntryResponse,
    fuelLogs ,
    selectedTrendType ,
    trendData ,
    tripsSingleResource ,
    selectedTripId
};