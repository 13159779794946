import React, { Component } from 'react';
import dingigl from 'dingi-gl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { API_TOKEN } from '../../assets/constants/app_constants';

import { setupMap  , calculateFitBounds} from './map_service';
import { showReportPoints  } from './icon_manager';


dingigl.apiToken = API_TOKEN;
class MapReport extends Component {

	constructor(props) {
		super(props);
		this.state = {
			focusPoint: {
				lng: 90.406915,
				lat: 23.742895,
				zoom: 11
			},
			co_ordinates: [],
			count: 0,

			isLayerShowed: false,
			historyLocations: null,
			locationCoOrdinates: null ,

			min_lon: 90.01,
			max_lon: 90.03,
			min_lat: 23.0,
			max_lat: 23.03 ,

			replayPathLocationIndex: -1

		};
	}


	componentDidMount() {
		setupMap(this);
		this.map.on('style.load', () => {
			const waiting = () => {
				if (!this.map.isStyleLoaded()) {
					setTimeout(waiting, 200);
				} else {
					this.loadMyLayers();
				}
			};
			waiting();
		});
	}

	loadMyLayers() {
		if (this.state.historyLocations && !this.state.isLayerShowed ) {
			showReportPoints(this);
		}
	}




	componentWillReceiveProps(nextProps) {
		if (nextProps.historyLocations && nextProps.historyLocations !== this.state.historyLocations) {
			this.setState({
				historyLocations: nextProps.historyLocations, isLayerShowed: false
			}, () => { this.calculateMinLatLngAddPath(); })
		}
	}

	calculateMinLatLngAddPath() {
		const history = this.state.historyLocations;

	
		var locationCoOrdinates = [];
		for (var single in history.features) {
			var location = history.features[single].geometry;
			locationCoOrdinates.push(location.coordinates);
		}
		calculateFitBounds(this ,locationCoOrdinates);
		if(this.map.isStyleLoaded())this.loadMyLayers();
	}


	render() {
		return (
			<div className="map-report">
				<div id="container" ref={el => (this.mapContainer = el)} className="map absolute top right left bottom" />
			</div>
		)
	}
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

function mapStateToProps({ ownedResources, allResourcesLocation, trackedResourceId }) {
	return { ownedResources, allResourcesLocation, trackedResourceId };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapReport);
