import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { getFuelLogs, selectMaintenanceLog } from '../../redux/actions/actions_resources';
import { setLoadingSpinnerState, setModalStateMaintenanceLogView } from '../../redux/actions/actions_misc';
import { formatDateFromIsoDate } from '../../utils/functions';

import car from '../../assets/icons/car_icon.png'

class FuelTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fuelLogs: [],
            count: 0,
            selectedResourceId: null,
            ownedResources: null
        };
        this.onClickViewLog = this.onClickViewLog.bind(this);
    }

    componentDidMount() {
        this.props.setLoadingSpinnerState(true);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.fuelLogs) {
            this.setState({
                count: nextProps.fuelLogs.count,
                fuelLogs: nextProps.fuelLogs.results
            });
            this.props.setLoadingSpinnerState(false);
        }

        if (nextProps.selectedResourceId && this.state.selectedResourceId !== nextProps.selectedResourceId.resource_id) {
            this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id });
            this.props.getFuelLogs(nextProps.selectedResourceId.resource_id);
        }

        if (nextProps.ownedResources && this.state.ownedResources !== nextProps.ownedResources) {
            this.setState({ ownedResources: nextProps.ownedResources });
        }
    }

    onClickViewLog(index) {
        this.props.selectMaintenanceLog(this.state.maintenanceEntries[index]);
        this.props.setModalStateMaintenanceLogView(true);
    }



    renderTableData() {

        if (this.state.count === 0) {
            return (
                <tr >
                    <td className="table-item-no-data" colSpan={6}> No Data Found </td>
                </tr>
            )
        }
        var arr = []
        for (var i = 0; i < this.state.count; i++) {
            var name = (this.state.ownedResources && this.state.selectedResourceId) ? this.state.ownedResources[this.state.selectedResourceId].title : "Searching Name";

            arr.push(
                <tr key={i} className="table-head">
                    <td style={{ display: "grid", gridTemplateColumns: "auto 1fr", gridColumnGap: "10px", borderRight: "1px solid #37474f1A" }} className="text-blue">
                        <img style={{ height: "30px", width: "15px", margin: "0px ,10px" }} src={car} alt={"te"}/>  {`   ${name}`}
                    </td>
                    <td align="center">{this.getIntegerFromString(this.state.fuelLogs[i].meter_reading)} Km</td>
                    <td align="center">{formatDateFromIsoDate(this.state.fuelLogs[i].date_created)}</td>
                    <td className="text-green">{this.getFuelTypeName(this.state.fuelLogs[i].type)}</td>
                    <td align="center">{this.getIntegerFromString(this.state.fuelLogs[i].volume)} Litre</td>
                    <td align="center"><i className="icon-editor-attach-money-bdt" /> {this.getIntegerFromString(this.state.fuelLogs[i].cost)}</td>
                    <td className="text-red"><i className="icon-editor-attach-money-bdt" /> {this.getIntegerFromString(this.state.fuelLogs[i].total)} </td>
                </tr>
            )
        }
        return arr;
    }

    getFuelTypeName(type) {
        switch (type) {
            case 0: return "Octane"
            case 1: return "CNG"
            case 2: return "Diesel"
            case 3: return "Petrol"
            case 4: return "LPG"
            default: return "Octane"
        }
    }

    getIntegerFromString(num) {
        var temp = "";
        if (num) {
            for (var i = 0; i < num.length; i++) {
                if (num[i] === '.') break;
                else temp = temp + num[i];
            }
        }
        return temp + " ";
    }

    render() {
        return (
            <div className="table-fuel">
                <table className="table table-hover">
                    <thead >
                        <tr >
                            <th style={{ borderRight: "1px solid #37474f1A" }}>Vehicle Name    </th>
                            <th>Odometer</th>
                            <th>Date            </th>
                            <th>Type            </th>
                            <th>Volume          </th>
                            <th>Rate            </th>
                            <th>Total Cost      </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableData()}
                    </tbody>
                </table>
            </div>
        )
    }
}



function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setModalStateMaintenanceLogView, selectMaintenanceLog, getFuelLogs, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ fuelLogs, selectedResourceId, ownedResources }) {
    return { fuelLogs, selectedResourceId, ownedResources };
}

export default connect(mapStateToProps, mapDispatchToProps)(FuelTable);
