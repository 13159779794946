import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectAlarmType } from '../../redux/actions/actions_alarm';
import {getAvailableAlarmsForUser} from '../../redux/actions/actions_alarm';
import _ from 'lodash'

class DropdownAlarmType extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            availableAlarmsForUser: []
        };
        this.onTypeChanged = this.onTypeChanged.bind(this);
    }

    onTypeChanged(e) {
        this.props.selectAlarmType(e.target.value);
    }

    renderOptions(){
        var arr = []
        arr.push(<option key={"-1"} className="dropdown-basic-option" value={-1}>All</option>)
        _.map(this.state.availableAlarmsForUser, singlealarm => {
            arr.push(<option key={singlealarm.id} className="dropdown-basic-option" value={singlealarm.alarm_id}>{singlealarm.alarm_name}</option>)
        });
        return arr;
    } 


    componentWillReceiveProps(nextProps){
        if(nextProps.selectedResourceId.resource_id !== this.state.selectedResourceId){
            this.setState({selectedResourceId : nextProps.selectedResourceId.resource_id} , ()=> {
                this.props.getAvailableAlarmsForUser(this.state.selectedResourceId);
            })
        }


        if (this.state.availableAlarmsForUser !== nextProps.availableAlarmsForUser) {
			this.setState({ availableAlarmsForUser: nextProps.availableAlarmsForUser });
		}

    }



    render() {
        return (

            <div className="dropdown-basic-container">
                <div className="dropdown-basic-icon-container">
                    <i className="icon-content-filter-list" />
                </div>
                <div className="dropdown-basic-title-data">

                    <div className="dropdown-basic-title"> Alarm Category </div>
                    <div className="dropdown-basic-data">
                        <select className="dropdown-basic-selection" onChange={this.onTypeChanged}>
                           {this.renderOptions()}
                        </select>
                    </div>
                </div>
            </div>

        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({  getAvailableAlarmsForUser ,selectAlarmType }, dispatch);
}

function mapStateToProps({ selectedResourceId , availableAlarmsForUser }) {
    return { selectedResourceId  , availableAlarmsForUser};
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownAlarmType);






