import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signOutUser, getUserDetails } from '../../redux/actions/actions_auth';
import { withRouter } from 'react-router-dom';
import Popup from "reactjs-popup";


import Avatar from 'react-avatar';

import { Link } from "react-router-dom";
import * as ROUTES from '../../assets/constants/routes';



class ButtonAvatar extends Component {

	constructor(props) {
		super(props);
		this.state = {
			name: "Dingi"
		}
		this.onClickLogOut = this.onClickLogOut.bind(this);
	}



	onClickLogOut() {
		this.props.signOutUser();
		this.props.history.push(ROUTES.SIGN_IN)
	}

	componentDidMount() {
		if (!this.props.userDetails) {
			this.props.getUserDetails();
		} else {
			var first_name = this.props.userDetails.first_name;
			var second_name = this.props.userDetails.last_name;
			var name = first_name + " " + second_name;
			this.setState({ name: name });
		}
	}

	componentWillReceiveProps(next) {
		if (next.userDetails) {
			var first_name = next.userDetails.first_name;
			var second_name = next.userDetails.last_name;
			var name = first_name + " " + second_name;
			this.setState({ name: name });
		}
	}


	render() {
		return (

			<div style={{ alignSelf : "center" , justifySelf:"right" , marginRight:"5%"}}>

				<Popup trigger={<button className="btn-logout"> <ProfileButton name={this.state.name} /> </button>} position="bottom center">
					<div className="profile-topbar-container">
						<Link to={ROUTES.PROFILE}>
							<button className="button-logout"> profile </button>
						</Link>
						<Link to={ROUTES.SUPPORT}>
							<button className="button-logout"> support </button>
						</Link>
						<button className="button-logout" onClick={this.onClickLogOut}> Logout </button>

					</div>
				</Popup>

			</div>


		)
	}
}

function ProfileButton(props) {
	return (
		<div className="btn-profile">
			<span className="profile-name-top"> {props.name} </span>
			<Avatar name={props.name} size="45" round={false} style={{borderRadius: "4px"}} />
		</div>
	)
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ signOutUser, getUserDetails }, dispatch);
}

function mapStateToProps({ userDetails }) {
	return { userDetails };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ButtonAvatar));
