import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { seTrackedResourceId ,getSingleResourceLocation , clearSearchResources } from '../../redux/actions/actions_resources';

class SearchResultListItemVehicle extends Component {

    constructor(props) {
        super(props);
        this.onClickVehicleItem = this.onClickVehicleItem.bind(this);
    }

    onClickVehicleItem() {
        this.props.seTrackedResourceId(this.props.data.id);
        this.props.getSingleResourceLocation(this.props.data.id);
        this.props.clearSearchResources();
    }

    render() {
        return (
            <div>
                <li className="search-result-list-item-vehicle" onClick={this.onClickVehicleItem}>
                    <div className="search-vehicle-item-icon">
                        <i className="icon-maps-car"></i>
                    </div>
                    <div className="search-vehicle-item-details">
                        <div className="search-vehicle-item-title">
                            <b> {this.props.data.title} </b>
                        </div>
                        <div className="search-vehicle-item-driver">
                            <i className="icon-social-person-outline"></i> {this.props.data.driver ? this.props.data.driver : "driver not available"}
                        </div>
                    </div>
                </li>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ seTrackedResourceId, getSingleResourceLocation  ,clearSearchResources}, dispatch);
}


export default connect(null, mapDispatchToProps)(SearchResultListItemVehicle);