import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAllResources } from '../../redux/actions/actions_resources';
import { getAllVehicles } from '../../redux/actions/actions_resources';
import { setTopbarTitle ,setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import _ from 'lodash';

class VehiclesPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resources: null,
            vehicles: null
        };
    }


    componentDidMount() {
        this.props.setTopbarTitle("Vehicles");
        this.props.getAllResources();
        this.props.getAllVehicles();
        this.props.setLoadingSpinnerState(true, "getting vehicle details ");
    }

    componentWillReceiveProps(nextProps) {
        nextProps.ownedResources ? this.setState({ resources: nextProps.ownedResources }) : console.log('resources data not available yet')
        nextProps.allVehicles ? this.setState({ vehicles: nextProps.allVehicles }) : console.log('vehicles data not available yet')
    }


    renderVehicleCards() {
        if (this.state.vehicles && this.state.resources) {
            this.props.setLoadingSpinnerState(false, "vehicle details found");
            const length = Object.keys(this.state.resources).length;
            if (length > 0) {
                var arr = [];
                _.map(this.state.resources, singleResource => {
                    const resource_id = singleResource.id
                    arr.push(<VehicleCard
                        basic={this.state.resources[resource_id]}
                        paper={this.state.vehicles[resource_id]}
                        key={resource_id}
                    />)
                });
                return arr;
            } else {
                return (<div> No Vehicle Data Found</div>)
            }
        }
        return (
            <div>   </div>
        )
    }


    render() {
        return (



            <div className="vehicles-page-container">
                <div className="vehicles-page-topbar">
                </div>
                <div className="vehicles-page-mainbar">
                    {this.renderVehicleCards()}
                </div>
            </div>

        )
    }
}


function VehicleCard(props) {

    if (props.paper && props.basic) {
        return (
            <div className="vehicle-details-card-container">

                <div className="text-blue vehicle-deteils-card-title">
                    <i className="icon-maps-car-multi" />
                    <div>{props.basic.title}</div>
                </div>
                <hr />
                <div className="vehicle-deteils-card-basic">
                    <div className="text-black vehicle-details-single-item-info"> <i className="icon-social-person-outline" /> {props.basic.driver ? props.basic.driver : "No driver found"}</div>
                    <div className="text-red vehicle-details-single-item-info"> <i className="icon-navigation-full-screen" /> {props.basic.license}</div>
                    <div className="text-blue vehicle-details-single-item-info"> <i className="icon-maps-car" /> {props.basic.vehicle_type}</div>

                    {props.basic.subscription_status === "Enabled" ? <div className="text-green vehicle-details-single-item-info"> <i className="icon-action-bolt" /> {props.basic.subscription_status}</div>
                        : <div className="text-red vehicle-details-single-item-info"> <i className="icon-action-bolt" /> {props.basic.subscription_status}</div>
                    }
                </div>


            </div>
        )
    } else {
        return <div> Loading Details ...</div>
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTopbarTitle ,getAllResources, getAllVehicles, setLoadingSpinnerState }, dispatch);
}

function mapStateToProps({ ownedResources, allVehicles }) {
    return { ownedResources, allVehicles };
}

export default connect(mapStateToProps, mapDispatchToProps)(VehiclesPage);