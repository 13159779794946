import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { formatDateFromIsoDate } from '../../utils/functions';

import { setModalStateMaintenanceLogView, setLoadingSpinnerState } from '../../redux/actions/actions_misc';


class ModalMaintenanceLogView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedMaintenanceLog: {}

        }
    }


    componentDidMount() {
        // this.props.getMaintenanceTypes();
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedMaintenanceLog) {
            this.setState({ selectedMaintenanceLog: nextProps.selectedMaintenanceLog });
        }
    }

    render() {
        return (
            <div className="modal">

                <div className="modal-logview-container">

                    <div className="modal-title-cross">
                        <div>
                            {this.props.selectedMaintenanceLog.type_text} Entry
                           </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateMaintenanceLogView(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>


                    <div className="text-with-icon-in-modal">
                        <i className="icon-action-event icon-text-input-box" />
                        <div className="text-shadow" >{formatDateFromIsoDate(this.props.selectedMaintenanceLog.date_created)} </div>
                    </div>

                    <div className="text-with-icon-in-modal-large">
                        <i className="icon-content-sort icon-text-input-box" />
                        <div className="text-shadow"> {this.props.selectedMaintenanceLog.details}</div>
                    </div>

                    <div className="text-with-icon-in-modal">
                        <i className="icon-editor-attach-money-bdt icon-text-input-box" />
                        <div className="text-shadow">{this.props.selectedMaintenanceLog.cost} </div>
                    </div>
               
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoadingSpinnerState, setModalStateMaintenanceLogView }, dispatch);
}

function mapStateToProps({
    selectedMaintenanceLog

}) {
    return {
        selectedMaintenanceLog
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMaintenanceLogView);