import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from 'react-loader-spinner';

class ModalLoading extends Component {

	render() {

		var temp = this.props.spinnerState.state ?
			<div className="modal">
				<div className="modal-loader-container">
					<Loader
						type="TailSpin"
						color="#0079BF"
						height="80"
						width="80"
					/>
				</div>

			</div> :
			<div >
				
			</div>


		return temp;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

function mapStateToProps({ spinnerState }) {
	return { spinnerState };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalLoading);
