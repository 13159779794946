import {
    SELECT_ALARM_TYPE,
    SET_SINGLE_ALARM_STATE,
    GET_SPECIFIC_ALARM_DETAIL,
    GET_SPECIFIC_ALARM_PATH,
    GET_ALARMS,
    GET_AVAILABLE_ALARMS_FOR_USER,
    GET_CURRENT_ALARM_SETTINGS,
    GET_SERVER_TIME ,
    CREATE_NEW_ALARM

} from '../../assets/constants/action_types';


const alarms = (state = {}, action) => {
    switch (action.type) {
        case GET_ALARMS:
            return action.payload;
        default:
            return state;
    }
};

const selectedAlarmType = (state = null, action) => {
    switch (action.type) {
        case SELECT_ALARM_TYPE:
            return action.payload;
        default:
            return state;
    }
}

const availableAlarmsForUser = (state = null, action) => {
    switch (action.type) {
        case GET_AVAILABLE_ALARMS_FOR_USER:
            return action.payload;
        default:
            return state;
    }
};


const currentAlarmState = (state = {
    alarm_type_id: 0,
    alarm_type_status: 2,
    alarm_type_data: null
}, action) => {
    switch (action.type) {
        case GET_CURRENT_ALARM_SETTINGS:

            if (action.payload.data.count > 0) {
                //-- > active
                return {
                    alarm_type_id: action.payload.alarm_id,
                    alarm_type_status: action.payload.data.results[0].status,
                    alarm_type_data: action.payload.data.results[0]
                };
            }
            // else{
            //     // --> not active
            //     return {
            //         alarm_type_id: action.payload.alarm_id ,
            //         alarm_type_status: 2 ,
            //         alarm_type_data : null
            //     }
            // }
            return state;
        default:
            return state;
    }
};


const specificAlarmDetail = (state = null, action) => {
    switch (action.type) {
        case GET_SPECIFIC_ALARM_DETAIL:
            return action.payload;
        default:
            return state;
    }
};


const specificAlarmPath = (state = null, action) => {
    switch (action.type) {
        case GET_SPECIFIC_ALARM_PATH:
            return action.payload;
        default:
            return state;
    }
};


const settingAlarmResponse = (state = null, action) => {
    switch (action.type) {
        case SET_SINGLE_ALARM_STATE:
            return action.payload;
        case CREATE_NEW_ALARM:
            return action.payload;
        default:
            return state;
    }
};


const serverTime = (state = null, action) => {
    switch (action.type) {
        case GET_SERVER_TIME:
            return action.payload;
        default:
            return state;
    }
};

export {
    alarms,
    selectedAlarmType,
    specificAlarmDetail,
    specificAlarmPath,
    availableAlarmsForUser,
    currentAlarmState,
    settingAlarmResponse,
    serverTime
};