

const checkDateGap = (day, month, year) => {


    var today = new Date();
    var curDay = String(today.getDate()).padStart(2, '0');
    var curMonth = String(today.getMonth() + 1).padStart(2, '0');
    var curYear = today.getFullYear();


    var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    var firstDate = new Date(year, month, day);
    var secondDate = new Date(curYear, curMonth, curDay);
    var diffDays = Math.round(((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
    return diffDays;
}

const formatDate = (day, month, year) => {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Septembre', 'October', 'November', 'December'];
    return day + ' ' + monthNames[month - 1] + ' ' + year
}

const formatDateFromIsoDate = (date) => {
    date = new Date(date);
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var year = date.getFullYear();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Septembre', 'October', 'November', 'December'];
    return day + ' ' + monthNames[month - 1] + ' ' + year
}



const getApiReadyDateFromMomentDate = (date) => {
    var mon = date.getUTCMonth() + 1;
    var year = date.getUTCFullYear();
    var day = date.getUTCDate();
    var formattedDate = `${year}-${mon}-${day}`;
    return formattedDate;
}



const getApiReadyDateFromMomentDateNormal = (date) => {

    var mon = date.getUTCMonth() + 1;
    var year = date.getUTCFullYear();
    var day = date.getUTCDate();
    var formattedDate = `${day}-${mon}-${year}`;
    return formattedDate;
}


const getDaysAgo = (date) => {
    var today = new Date();
    var findDate = new Date(date);
    var oneDay = 24 * 60 * 60 * 1000;
    var diffDays = Math.round(((today.getTime() - findDate.getTime()) / (oneDay)));
    return diffDays;

}

const getTimeAgoFromUtcTime = (timestamp) => {

    var moment = require('moment');
    var local_date = moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm:ss');
    var timeAgo = moment(local_date).fromNow();
    return timeAgo;
}

export function formatDateForPath(date, intention) {


    var seconds = date.getSeconds();
    var minutes = date.getMinutes();
    var hour = date.getHours() - 6;

    var day = date.getUTCDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();


    switch (intention) {
        case "report_from":
            hour = 0;
            minutes = 0;
            seconds = 0;
            break;
        case "report_to":
            hour = 23;
            minutes = 59;
            seconds = 59;
            break;
        case "alarm":
            break;
        default:

    }




    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    if (minutes < 10) {
        minutes = `0${minutes}`;
    }
    if (hour < 10) {
        hour = `0${hour}`;
    }
    if (day < 10) {
        day = `0${day}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }


    var formatted = `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
    console.log('formatted   ', formatted);
    return formatted;
}


export function getTimeFromUtcDate(date) {
    var date = new Date(date);


    var hours = date.getHours() ;
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;


}


//create date fo have a range
export function formateDateTimeForTrip(date, time) {
    var day = date.getUTCDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    if (day < 10) {
        day = `0${day}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    if(time.length <8) time = `0${time}`

    var formatted = `${year}-${day}-${month}T${time}`;
   
    return formatted;
}



// the address is very long so we are removing last two words from address
function processAddress(address) {
    var temp = ""
    var tempArr = []
    var cnt = 0;
    for (var i = 0; i < address.length; i++) {
        if (address[i] === ",") {
            tempArr.push(temp);
            temp = "";
            cnt++;
        } else {
            temp = temp + address[i];
        }
    }
    tempArr.push(temp);
    var resultingAddress = tempArr[0]
    for (i = 2; i < cnt; i++)resultingAddress = resultingAddress + "  " + tempArr[i];
    return resultingAddress;
}




export {
    checkDateGap,
    formatDate,
    getApiReadyDateFromMomentDate,
    getApiReadyDateFromMomentDateNormal,
    getDaysAgo,
    formatDateFromIsoDate,
    getTimeAgoFromUtcTime,
    processAddress
    // getTimeFromUtcDate
} 