import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { seTrackedResourceId } from '../../redux/actions/actions_resources';

class ButtonExitTracker extends Component {

	constructor(props){
		super(props);
		this.state = {
			vehicle_name: '...' 
		};
		this.onClickButton = this.onClickButton.bind(this);
	}

	onClickButton(){
		this.props.seTrackedResourceId(null);	
    }
    
    componentWillReceiveProps(nextProps){
        if(nextProps.singleResourceLocation){
            this.setState({
                vehicle_name: nextProps.singleResourceLocation.title
            });
        }
    }

    render() {

        if(this.props.isVisible){
            return (
                <div className="btn-exit-tracker">
                    <button onClick = {this.onClickButton}> <i className="icon-navigation-arrow-backward"/> Tracking {this.state.vehicle_name} </button>
                </div>
            )
        }else{
            return (
                <div>
                    
                </div>
            )
        }
		
	}
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ seTrackedResourceId } , dispatch);
}

function mapStateToProps({ singleResourceLocation}){
    return {singleResourceLocation };
}

export default connect(mapStateToProps , mapDispatchToProps)(ButtonExitTracker);