import React, { Component } from 'react';
import dingigl from 'dingi-gl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { API_TOKEN } from '../../assets/constants/app_constants';
import _ from 'lodash';

import { colors, colorCount } from '../../assets/constants/colors';

import {
	setupMap,
	calculateFitBounds,
	getCoOrdinatesArrayFromFeatures,
	generatePointsBetweenCoOrdinates
} from './map_service';

import {
	showDirectionIcons, hideDirectionIcons,
	showTripStartIcon, hideTripStartIcon,
	showTripEndIcon, hideTripEndIcon,
	showSingleTrackedVehicleFromFeature, hideSingleTrackedVehicle
} from './icon_manager';
import { setReplayndex } from '../../redux/actions/actions_misc';

import { addTripPath, removeTripPath, hideTripPath, showTrailingPath } from './path_manager';

import { getBearing } from '../../utils/utils';

dingigl.apiToken = API_TOKEN;

const mapStates = {
	SINGLE_TRIP_SHOWED: "SINGLE_TRIP_SHOWED",
	ALL_TRIPS_SHOWED: "ALL_TRIPS_SHOWED",
	REPLAY_PLAYING: "REPLAY_PLAYING",
	REPLAY_PAUSED: "REPLAY_PAUSED",
	REPLAY_CANCELLED: "REPLAY_CANCELLED",
	TRIPS_LOADING: "TRIPS_LOADING",
	MAP_NOT_LOADED: "MAP_NOT_LOADED"

}
var dynamicCoOrdinatesArr = []
class MapTrips extends Component {

	constructor(props) {
		super(props);
		this.state = {

			min_lon: 120.0,
			max_lon: 10.0,
			min_lat: 90.0,
			max_lat: 10.0,

			isStyleLoaded: false,
			intervalId: null,

			replayPathLocationIndex: -1,
			selectedTripId: -1,

			tripsSingleResource: null,
			tripsByKey: {},
			tripReplayState: false,
			layersInMap: [],

			cntLocationPointsForSingleTrip: 0,

			componentState: mapStates.ALL_TRIPS_SHOWED

		};
		this.renderSpecificLayer = this.renderSpecificLayer.bind(this);
	}


	componentDidMount() {
		setupMap(this);
		this.map.on('style.load', () => {
			const waiting = () => {
				if (!this.map.isStyleLoaded()) setTimeout(waiting, 200);
				else this.setState({ isStyleLoaded: true }, () => { this.handleStateChange(); })
			};
			waiting();
		});
	}

	componentWillUnmount() { clearInterval(this.interval); clearInterval(this.state.intervalId); clearTimeout(this.timer); }



	handleStateChange() {
		switch (this.state.componentState) {
			case mapStates.SINGLE_TRIP_SHOWED:
				this.renderSpecificLayer(this, this.state.selectedTripId);
				this.moveVehicleToSpecificPathIndex(0);
				break;
			case mapStates.ALL_TRIPS_SHOWED:
				this.clearPreviousLayers();
				this.cancelReplay();
				if (this.state.isStyleLoaded) this.renderLayers(this);
				break;
			default:
		}
	}


	componentWillReceiveProps(nextProps) {


		if (nextProps.selectedTripId !== this.state.selectedTripId) {
			this.setState({ selectedTripId: nextProps.selectedTripId }, () => {
				(nextProps.selectedTripId === -1) ? this.setState({ componentState: mapStates.ALL_TRIPS_SHOWED }, () => this.handleStateChange()) :
					this.setState({ componentState: mapStates.SINGLE_TRIP_SHOWED }, () => this.handleStateChange())
			})
		}



		if (nextProps.tripsSingleResource && this.state.tripsSingleResource !== nextProps.tripsSingleResource) {
			this.setState({ tripsByKey: _.keyBy(nextProps.tripsSingleResource.results, 'id'), tripsSingleResource: nextProps.tripsSingleResource, componentState: mapStates.ALL_TRIPS_SHOWED }, () => {
				this.handleStateChange();
			})
		}


		//coming from the modal on the right
		if (nextProps.tripReplayState !== this.state.tripReplayState) {
			this.setState({ tripReplayState: nextProps.tripReplayState }, () => {
				if (this.state.tripReplayState) this.showReplay(nextProps.selectedTripId)
				else this.pauseReplay();
			});
		}

		if (nextProps.selectedReplayIndex !== this.state.replayPathLocationIndex) {
			this.setState({ replayPathLocationIndex: nextProps.selectedReplayIndex }, () => this.moveVehicleToSpecificPathIndex(this.state.replayPathLocationIndex));
		}

	}

	clearPreviousLayers() {

		for (var i = 0; i < this.state.layersInMap.length; i++) {
			var layerId = this.state.layersInMap[i];
			removeTripPath(this, layerId);
		}
		this.setState({ layersInMap: [], replayPathLocationIndex: -1 }, () => this.props.setReplayndex(-1));
		hideTripStartIcon(this);
		hideTripEndIcon(this);
		hideDirectionIcons(this);
	}

	renderLayers(Component) {

		var cumulativeCoordinatesArr = []
		var cumulativeLayersArray = []
		_.forEach(this.state.tripsByKey, function (value, key) {
			if (value.path) {
				var co_ordinates = getCoOrdinatesArrayFromFeatures(value.path.features);
				cumulativeCoordinatesArr = cumulativeCoordinatesArr.concat(co_ordinates);
				var id = value.id.toString();
				addTripPath(Component, id, co_ordinates, colors[id % colorCount]);
				cumulativeLayersArray.push(id);
				Component.map.setPitch(0);
			}
		});
		Component.setState({ layersInMap: cumulativeLayersArray })
		Component.map.setPitch(0);
		calculateFitBounds(Component, cumulativeCoordinatesArr);
	}



	//basically removing the unwanted layers
	renderSpecificLayer = (Component, layerId) => {
		_.forEach(this.state.tripsByKey, function (value, key) {
			if (layerId && value.id !== layerId) {
				hideTripPath(Component, value.id.toString());
			} else {

				var locationCoOrdinates = [];
				var arr = value.path.features;
				var cnt = value.path.features.length;
				Component.setState({ cntLocationPointsForSingleTrip: cnt })
				_.forEach(value.path.features, function (value, key) { locationCoOrdinates.push(value.geometry.coordinates); })

				//fitbound add direction icons and show start end icons
				var tempArr = Component.minimizeArray(arr)
				showDirectionIcons(Component, tempArr);
				showTripStartIcon(Component, locationCoOrdinates[0]);
				showTripEndIcon(Component, locationCoOrdinates[cnt - 1]);
				calculateFitBounds(Component, locationCoOrdinates);

			}
		});
	}

	//miniizing array because directions icons are too dense
	minimizeArray(arr) {
		var tempArr = []
		for (var i = 0; i < arr.length; i++) {
			if (i % 4 === 0) tempArr.push(arr[i])
		}
		return tempArr;
	}



	showReplay() {
		this.interval = setInterval(() => {
			this.setState({ replayPathLocationIndex: (this.state.replayPathLocationIndex + 1) % this.state.cntLocationPointsForSingleTrip }, () => {
				this.moveVehicleToSpecificPathIndex(this.state.replayPathLocationIndex);
				this.props.setReplayndex(this.state.replayPathLocationIndex)
			})
		}, 3000);
	}

	moveVehicleToSpecificPathIndex(index) {
		if (index > 0 && this.state.tripsByKey[this.state.selectedTripId]) {

			hideDirectionIcons(this);
			
			var previousFeature = this.state.tripsByKey[this.state.selectedTripId].path.features[index - 1];
			var currentFeature = this.state.tripsByKey[this.state.selectedTripId].path.features[index];
			var fractionalCoordinates = generatePointsBetweenCoOrdinates(previousFeature.geometry.coordinates, currentFeature.geometry.coordinates, 55);
			this.animateCarBetweenCoOrdinates(fractionalCoordinates, currentFeature.properties.bearing, 3000, 50);
		}
	}

	animateCarBetweenCoOrdinates(fractionalCoordinates, defaultBearing, fullAnimationDuration = 1500, singleMovementDuration) {
		var fractionalIndex = 0;
		var bearing = defaultBearing;
		let timerId = setInterval(() => {
			this.setState({ intervalId: timerId })

			var curCoOrdinates = fractionalCoordinates[fractionalIndex];
			var nextCoOrdinates = fractionalCoordinates[fractionalIndex + 1];
			var length = fractionalCoordinates.length;

			//use bearing to make the car aligned with the route line
			bearing = getBearing(fractionalCoordinates[0][1].toFixed(4), fractionalCoordinates[0][0].toFixed(4), fractionalCoordinates[length - 1][1].toFixed(4), fractionalCoordinates[length - 1][0].toFixed(4))

			if (curCoOrdinates && nextCoOrdinates) {

				var tempFeature = {
					geometry: { coordinates: curCoOrdinates },
					properties: { bearing: bearing }
				}

				dynamicCoOrdinatesArr.push(curCoOrdinates);
				//showTrailingPath(this, dynamicCoOrdinatesArr);

				this.animateMap(fractionalCoordinates[fractionalIndex], bearing);
				showSingleTrackedVehicleFromFeature(this, tempFeature);

			

				fractionalIndex++;
			} else {
				clearInterval(timerId);
			}
		}, singleMovementDuration);
		this.timer = setTimeout(() => { clearInterval(timerId); console.log('stop'); }, fullAnimationDuration);
	}

	animateMap(center, bearing) {
		this.map.setPitch(45);
		this.map.flyTo({
			center: center,
			bearing: bearing,
			zoom: 16,
			duration: 700,
			curve: 0.5,
			speed: 0.33,
			easing: function (t) { return t; }
		});
	
	}

	cancelReplay() {
		this.setState({ replayPathLocationIndex: -1 });
		clearInterval(this.interval);
		hideSingleTrackedVehicle(this);
		if (this.state.intervalId) clearInterval(this.state.intervalId);
	}

	pauseReplay() {
		clearInterval(this.interval);
		if (this.state.intervalId) clearInterval(this.state.intervalId);
	}


	render() {
		return (
			<div className="map-report">
				<div id="container" ref={el => (this.mapContainer = el)} className="map absolute top right left bottom" />
				{/* <div style={{ position: "fixed" }} className="map-report-time-container"> time </div> */}
			</div>
		)
	}
}


function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setReplayndex }, dispatch);
}

function mapStateToProps({ selectedReplayIndex, tripReplayState, selectedTripId, tripsSingleResource }) {
	return { selectedReplayIndex, tripReplayState, selectedTripId, tripsSingleResource };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapTrips);





// 1,Overspeeding Alarm
// 2,SOS
// 3,Vibration Alarm
// 4,Harsh Acceleration Alarm
// 5,Harsh Brake Alarm
// 6,Low Battery Voltage Alarm
// 7,Out of Route Alarm
// 8,Out of Zone Alarm
// 9,Out of Radius Alarm
// 10,Geofence Alarm
// 11,Engine On/Off Alarm
// 12,Displacement Alarm
// 13,External Power Cut Alarm
// 14,Door Open/Closed Alarm
// 15,Fuel Level Alarm
// 16,Temperature Alarm
// 17,Collision Alarm
// 18,Face Recognition
// 19,Face Recognition
// 20,Paper Management
// 21,Fuel Level Alarm
// 22,Door Status Alarm
// 23,Maintenance Alarm