import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setModalStateRefuelEntry, setLoadingSpinnerState } from '../../redux/actions/actions_misc';
import { createFuelEntry } from '../../redux/actions/actions_resources';
import '../../assets/styles/input_boxes.scss';
import DropDownResources from '../dropdowns/dropdown_resources';


class ModalFuelEntry extends Component {

    constructor(props) {
        super(props);
        this.state = {
            volume: "0",
            rate: "0",
            total: "0",
            odometer: "0",
            selectedResourceId: null,
            selectedFuelType: 0 ,
            shouldShow: true,
        }
        this.submitData = this.submitData.bind(this);
        this.onSeverityChange = this.onSeverityChange.bind(this);
    }

    submitData() {

        var fuelData = {
            type: this.state.selectedFuelType,
            volume: this.state.volume,
            cost: this.state.rate,
            total: this.state.total,
            meter_reading: this.state.odometer,
            resource: this.state.selectedResourceId
        }

        this.props.setLoadingSpinnerState(true);
        this.props.createFuelEntry(fuelData);
    }

    componentDidMount() {
        this.setState({createEntryResponse : false});
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedResourceId) {
            this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id });
        }

        if (nextProps.createFuelEntryResponse) {
            
            this.props.setLoadingSpinnerState(false);
            this.props.setModalStateRefuelEntry(false);
            
            if (nextProps.createFuelEntryResponse.status === "success") console.log('success   ', nextProps.createFuelEntryResponse.response)
            else console.log('error   ', nextProps.createFuelEntryResponse.response)
        }
    }


    onSeverityChange(e) {
        this.setState({ selectedFuelType: e.target.value })
    }



    render() {
        return (
            <div className="modal display-block">
                <div className="modal-fuel-container">
                    <div className="modal-title-cross">
                        <div className="modal-title">
                            Refuel Entry
                           </div>
                        <div className="modal-cross" onClick={() => this.props.setModalStateRefuelEntry(false)}>
                            <i className="icon-navigation-close" />
                        </div>
                    </div>

                    <div className="dropdown-with-title">
                        <span className="title-over-dropdown"> Select Vehicle</span>
                        <div className="dropdown-with-icon-in-modal">
                            <i className="icon-maps-car icon-dropdown-box" />
                            <DropDownResources page="modal" />
                        </div>
                    </div>

                    <div className="dropdown-with-title">
                        <span className="title-over-dropdown">  Fuel Type</span>
                        <div className="dropdown-with-icon-in-modal">
                            <i className="icon-maps-local-gas-station icon-dropdown-box" />
                            <div className="dropdown-modal-container">
                                <select className="dropdown-modal-selection" onChange={this.onSeverityChange}>
                                    <option className="dropdown-modal-option" selected={this.state.octane} value={0}>Octane</option>
                                    <option className="dropdown-modal-option" selected={this.state.cng} value={1}>CNG</option>
                                    <option className="dropdown-modal-option" selected={this.state.cng} value={2}>Diesel</option>
                                    <option className="dropdown-modal-option" selected={this.state.petrol} value={3}>Petrol</option>
                                    <option className="dropdown-modal-option" selected={this.state.cng} value={4}>LPG</option>
                                </select >
                            </div >
                        </div>
                    </div>




                    <form>
                        <div className="title-over-input">  Volume </div>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-action-collapse icon-text-input-box" />
                            <input className="text-shadow" placeholder="Volume" type="number" value={this.state.volume} onChange={(e) => this.setState({ volume: e.target.value })} />
                        </div>

                        <div className="title-over-input">  Rate </div>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-editor-attach-money-bdt icon-text-input-box" />
                            <input className="text-shadow" placeholder="Rate" type="number" value={this.state.rate} onChange={(e) => this.setState({ rate: e.target.value })} />
                        </div>

                        <div className="title-over-input">  Total Cost </div>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-av-playlist-add icon-text-input-box" />
                            <input className="text-shadow" placeholder="Total Cost" type="number" value={this.state.total} onChange={(e) => this.setState({ total: e.target.value })} />
                        </div>

                        <div className="title-over-input">  Odometer Reading </div>
                        <div className="text-with-icon-in-modal">
                            <i className="icon-action-label icon-text-input-box" />
                            <input className="text-shadow" placeholder="Odometer Reading" type="number" value={this.state.odometer} onChange={(e) => this.setState({ odometer: e.target.value })} />
                        </div>

                    </form>


                    <div className="btn-icon-txt-blue-filled" onClick={this.submitData}>
                        <i className="icon-content-add btn-icon" />
                        <div> ADD</div>
                    </div>

        

                </div >
            </div >

        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoadingSpinnerState, setModalStateRefuelEntry, createFuelEntry }, dispatch);
}

function mapStateToProps({
    selectedResourceId,
    createFuelEntryResponse

}) {
    return {
        selectedResourceId,
        createFuelEntryResponse
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFuelEntry);