import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getUserDetails,
  updateUserDetails
} from "../../redux/actions/actions_auth";
import {
  setTopbarTitle,
  setLoadingSpinnerState
} from "../../redux/actions/actions_misc";

// import TextField from '@material-ui/core/TextField';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      address: "",
      status: "NOT ACTIVE",
      id: "",

      region: null,
      street_number: null,
      house_number: null,
      shouldFetch: false,
      success: false
    };
    this.onSaveClicked = this.onSaveClicked.bind(this);
  }

  componentDidMount() {
    this.props.setTopbarTitle("Profile");
    this.props.setLoadingSpinnerState(true, "profile data saving");
    this.props.getUserDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userDetails) {
      this.props.setLoadingSpinnerState(false, "profile data saving");
      const data = nextProps.userDetails;
      this.setState({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        status: data.status,
        id: data.id,
        region: data.region,
        street_number: data.street_number,
        house_number: data.house_number
      });
    }

    if (this.state.shouldFetch) {
      this.props.getUserDetails();
      this.setState({
        shouldFetch: false
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSaveClicked() {
    this.props.setLoadingSpinnerState(true, "profile data saving");
    var data = {
      email: this.state.email,
      phone: this.state.phone
    };
    this.props.updateUserDetails(this.state.id, data);
    this.setState({ shouldFetch: true, success: true });
  }

  render() {
    return (
      <div className="profile-container">
        <div className="profile-form-container">
          <div className="profile-form">
            {this.state.success && (
              <p className="text-center app-primary-color">
                Profile updated successfully
              </p>
            )}
            <div className="title-over-input font-telenor-regular">
              {" "}
              First Name{" "}
            </div>
            <div className="text-with-icon-in-modal">
              <i className="icon-action-range icon-text-input-box" />
              <input
                className="text-shadow"
                value={this.state.first_name || ""}
                placeholder="First Name"
                type="text"
                name="first_name"
                onChange={this.handleChange}
              />
            </div>

            <div className="title-over-input font-telenor-regular">
              {" "}
              Last Name{" "}
            </div>
            <div className="text-with-icon-in-modal">
              <i className="icon-action-range icon-text-input-box" />
              <input
                className="text-shadow"
                value={this.state.last_name || ""}
                placeholder="Last Name"
                type="text"
                name="last_name"
                onChange={this.handleChange}
              />
            </div>

            <div className="title-over-input font-telenor-regular"> Phone </div>
            <div className="text-with-icon-in-modal">
              <i className="icon-communication-call icon-text-input-box" />
              <input
                className="text-shadow"
                placeholder="Phone Number"
                value={this.state.phone || ""}
                type="text"
                name="phone"
                onChange={this.handleChange}
              />
            </div>

            <div className="title-over-input font-telenor-regular"> Email </div>
            <div className="text-with-icon-in-modal">
              <i className="icon-content-waves icon-text-input-box" />
              <input
                className="text-shadow"
                placeholder="Email Address"
                value={this.state.email || ""}
                type="text"
                name="email"
                onChange={this.handleChange}
              />
            </div>

            <div className="save-btn" onClick={this.onSaveClicked}>
              <div>
                <i className="icon-navigation-check" />{" "}
              </div>
              <div className="save-text">SAVE</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTopbarTitle,
      getUserDetails,
      setLoadingSpinnerState,
      updateUserDetails
    },
    dispatch
  );
}

function mapStateToProps({ userDetails, setUserDetailsResponse }) {
  return { userDetails, setUserDetailsResponse };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfilePage);
