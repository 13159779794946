import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setLoadingSpinnerState, getReversedAddressFromLatLng, setReplayStateInTripsFromTripDetails
} from '../../redux/actions/actions_misc';
import { selectTripId, getSingleResourceTrips } from '../../redux/actions/actions_resources';
import { setReplayndex } from '../../redux/actions/actions_misc';
import { processAddress } from '../../utils/functions';
import _ from 'lodash';
import { getTimeFromUtcDate } from '../../utils/functions';
import { colors, colorCount } from '../../assets/constants/colors';
import { Slider, Direction } from 'react-player-controls'




const WHITE_SMOKE = '#eee'
const GRAY = '#878c88'
const GREEN = '#72d687'

// A colored bar that will represent the current value
const SliderBar = ({ value, style }) => (
    <div
        style={Object.assign({}, {
            position: 'absolute',
            background: GRAY,
            borderRadius: 4,
        }, {
                top: 0,
                bottom: 0,
                left: 0,
                width: `${value}%`,
            }, style)}
    />
)

// A handle to indicate the current value
const SliderHandle = ({ value, style }) => (
    <div
        style={Object.assign({}, {
            position: 'absolute',
            width: 16,
            height: 16,
            background: GREEN,
            borderRadius: '100%',
            transform: 'scale(1)',
            transition: 'transform 0.2s',
            '&:hover': {
                transform: 'scale(1.3)',
            }
        }, {
                top: 0,
                left: `${value}%`,
                marginTop: -4,
                marginLeft: -8,
            }, style)}
    />
)

const ProgressBar = ({ isEnabled, direction, value, ...props }) => (
    <Slider
        isEnabled={isEnabled}
        direction={direction}
        onChange={() => console.log('changed')}
        style={{
            width: "100%",
            height: 8,
            borderRadius: 4,
            background: WHITE_SMOKE,
            transition: 'width 0.1s',
            cursor: isEnabled === true ? 'pointer' : 'default',
        }}
        {...props}
    >
        <SliderBar value={value} style={{ background: isEnabled ? GREEN : GRAY }} />
        <SliderHandle value={value} style={{ background: isEnabled ? GREEN : GRAY }} />
    </Slider>
)




class ModalTripDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {

            tripsByKey: {},
            selectedTripId: -1,
            replayBtnState: "Play",
            replayBtnIcon: "icon-av-play",
            playBtnColor: "text-blue",
            selectedResourceId: null,
            selectedDate: null,
            tripCount: 5,
            fromTime: "00:00:00",
            toTime: "23:59:00",

            replayIndex: -1,
            sliderPosition: 0

        };

        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.handleIntent = this.handleIntent.bind(this);
        // this.handleIntentEnd = this.handleIntentEnd.bind(this);

    }



    handleClose() {
        this.setState({ selectedTripId: -1, replayBtnState: "Play", replayBtnIcon: "icon-av-play", playBtnColor: "text-blue" }, () => {
            this.props.setReplayStateInTripsFromTripDetails(false);
            this.props.selectTripId(-1);
        });
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.tripsSingleResource) {
            this.props.setLoadingSpinnerState(false);
            this.setState({ tripsByKey: _.keyBy(nextProps.tripsSingleResource.results, 'id') });
        }

        if (nextProps.selectedTripId && this.state.selectedTripId !== nextProps.selectedTripId) {
            this.props.setReplayStateInTripsFromTripDetails(false);
            if (nextProps.selectedTripId !== -1) this.setState({ selectedTripId: nextProps.selectedTripId })
            else this.setState({ selectedTripId: nextProps.selectedTripId })
        }

        if (nextProps.selectedResourceId && this.state.selectedResourceId !== nextProps.selectedResourceId.resource_id) {
            this.setState({ selectedResourceId: nextProps.selectedResourceId.resource_id }, () => this.getTrips())
        }


        if (nextProps.selectedDate && this.state.selectedDate !== nextProps.selectedDate.date) {
            this.setState({ selectedDate: nextProps.selectedDate.date }, () => this.getTrips())
        }


        if (this.state.tripCount !== nextProps.tripCount) {
            console.log(nextProps.tripCount);
            this.setState({ tripCount: nextProps.tripCount }, () => this.getTrips())
        }

        if (nextProps.selectedTime) {
            var selectedTime = `${nextProps.selectedTime.time}:00`;
            var intention = nextProps.selectedTime.intention;

            if (intention === "trip_range_start" && selectedTime !== this.state.fromTime) {
                this.setState({ fromTime: selectedTime, tripCount: null }, () => this.getTrips())
            }
            if (intention === "trip_range_end" && selectedTime !== this.state.toTime) {
                this.setState({ toTime: selectedTime, tripCount: null }, () => this.getTrips())
            }
        }

        if (nextProps.selectedReplayIndex !== this.state.replayIndex) {
            this.setState({ replayIndex: nextProps.selectedReplayIndex }, () => this.getSliderPositionFromIndex());
        }
    }




    getTrips() {
        if (this.state.selectedResourceId) {
            this.handleClose();
            this.props.setLoadingSpinnerState(true);
            if (this.state.tripCount === "10") {
                this.props.getSingleResourceTrips(this.state.selectedResourceId, this.state.selectedDate, this.state.fromTime, this.state.toTime, null);
            } else {
                this.props.getSingleResourceTrips(this.state.selectedResourceId, this.state.selectedDate, this.state.fromTime, this.state.toTime, this.state.tripCount);
            }

        }
    }

    renderTrips(props) {
        var arr = []
        _.forEach(this.state.tripsByKey, function (value, key) {
            arr.push(<SingleTripSummary key={value.id} data={value} functions={props} />)
        });
        return arr
    }


    toggleReplayState() {
        (this.state.replayBtnState === "Play") ? this.setState({ replayBtnState: "Pause", replayBtnIcon: "icon-av-pause", playBtnColor: "text-red" }, () => this.props.setReplayStateInTripsFromTripDetails(true))
            : this.setState({ replayBtnState: "Play", replayBtnIcon: "icon-av-play", playBtnColor: "text-blue" }, () => this.props.setReplayStateInTripsFromTripDetails(false))
    }

    getSliderPositionFromIndex() {
        if (this.state.replayIndex !== -1) {
            var length = this.state.tripsByKey[this.props.selectedTripId].path.features.length;
            var sliderPosition = this.state.replayIndex / length;
            this.setState({ sliderPosition })
        }else{
            this.setState({ sliderPosition : 0})
        }
    }

    getIndexFromSLiderPosition(sliderPosition) {
        var length = this.state.tripsByKey[this.props.selectedTripId].path.features.length;
        var index = Math.ceil(sliderPosition * length);
        return index;
    }


    onChange(value) {
        var index = this.getIndexFromSLiderPosition(value);
        this.props.setReplayndex(index - 1);
    }



    render() {

        var val = ""
        if (this.state.tripsByKey && this.state.selectedTripId !== -1 && this.state.replayIndex !== -1 && this.state.tripsByKey[this.props.selectedTripId].path.features[this.state.replayIndex]) {
            val = this.state.tripsByKey[this.props.selectedTripId].path.features[this.state.replayIndex].properties.timestamp.substring(10, 19);
        }

        if (this.state.selectedTripId === -1) {
            return (
                <div className="modal-trip-summary">
                    {this.renderTrips(this.props)}
                </div>
            )
        } else {
            var data = this.state.tripsByKey[this.state.selectedTripId];
            var resourceName = this.props.ownedResources[data.resource].title
            var efficiency = (data.total_fuel_used) ? efficiency = `${((data.travelled_distance / 1000) / data.total_fuel_used).toFixed(1)} Km/L` : "N/A";
            if (data) {
                return (
                    <div className="modal-trip-details">

                        <div className="trip-details-title-cross">

                            <span className="trip-detais-title"> <b>Trip Details</b> </span>
                            <i onClick={() => this.handleClose()} className="icon-navigation-close" />

                            <span className="trip-details-vehicle-name" >Trip Details for {resourceName}</span>
                        </div>

                        <div className="trip-details-srcdest-container">

                            <div className="trip-details-srcdest-single">
                                <i style={{ color: "#0079bf", fontSize: "15px" }} className="icon-navigation-radius icon-big" />
                                <span style={{ fontSize: "12px" }}> {processAddress(data.start_address)} </span>
                                <div className="trip-details-srcdest-time"> {getTimeFromUtcDate(data.start_time)} </div>
                            </div>

                            <div className="trip-details-srcdest-single">
                                <i style={{ color: "#0079bf", fontSize: "16px" }} className="icon-maps-place icon-big" />
                                <span style={{ fontSize: "12px" }}> {processAddress(data.end_address)} </span>
                                <div className="trip-details-srcdest-time" > {getTimeFromUtcDate(data.end_time)} </div>
                            </div>

                        </div>


                        <div className="trip-details-info-container">

                            <SingleItemTripDetails name="DISTANCE" value={`${(data.travelled_distance / 1000).toFixed(1)} Km`} icon="icon-maps-car" color="text-orange" />
                            <SingleItemTripDetails name="TRIP TIME" value={`${(data.trip_duration / 60).toFixed(1)} Mins`} icon="icon-action-schedule-start" color="text-magenta" />
                            <SingleItemTripDetails name="CONSUMED FUEL" value={`${parseFloat(data.total_fuel_used).toFixed(1)} L`} icon="icon-maps-local-gas-station" color="text-blue" />
                            <SingleItemTripDetails name="FUEL EFFICIENCY" value={efficiency} icon="icon-device-data-usage" color="text-deep-green" />
                            <SingleItemTripDetails name="AVG SPEED" value={`${parseFloat(data.average_speed).toFixed(1)} Km/Hr`} icon="icon-action-schedule-start" color="text-black" />
                            <SingleItemTripDetails name="DRIVING ALARM" value={`${data.alarm_count} Times`} icon="icon-action-info" color="text-red" />

                        </div>

                        <div style={{ textAlign: "center" }}>{val}</div>

                        <ProgressBar
                            isEnabled
                            direction={Direction.HORIZONTAL}
                            value={this.state.sliderPosition * 100}
                            onChange={this.onChange}
                        // onIntent={this.handleIntent}
                        // onIntentEnd={this.handleIntentEnd}
                        />


                        <div className={`${this.state.playBtnColor} btn-replay-play-pause`} onClick={() => this.toggleReplayState()}> <i className={this.state.replayBtnIcon} /> {this.state.replayBtnState} </div>


                    </div>

                )
            }
            return <div></div>

        }

    }
}

function SingleTripSummary(props) {

    return (
        <div className="trip-summary-single" onClick={() => props.functions.selectTripId(props.data.id)}>

            <div className="single-trip-single-row-major">
                <i style={{ color: colors[props.data.id % colorCount] }} className="icon-navigation-radius icon-big" />
                <span className="report-single-trip-summary-srcdest"> {processAddress(props.data.start_address)}</span>
                <div style={{ color: `${colors[props.data.id % colorCount]}`, background: `${colors[props.data.id % colorCount]}1A` }} className="trip-srcdest-time"> {getTimeFromUtcDate(props.data.start_time)}</div>
            </div>

            <div className="single-trip-single-row-minor">
                <i className="icon-navigation-expand-more" />
                <div className="report-single-trip-minor-details"> <i className="icon-action-range" /> Distance {(props.data.travelled_distance / 1000).toFixed(1)} KM</div>
                <div>  </div>
            </div>

            <div className="single-trip-single-row-minor">
                <i className="icon-navigation-expand-more" />
                <div className="report-single-trip-minor-details"> <i className="icon-action-schedule-start" /> Trip Time {(props.data.trip_duration / 60).toFixed(1)} minutes </div>
                <div>  </div>
            </div>


            <div className="single-trip-single-row-minor">
                <i className="icon-navigation-expand-more" />
                <div className="report-single-trip-minor-details"> <i className="icon-alert-error-outline" /> Driving Alarm <span className="text-orange"> <b>{props.data.alarm_count}</b> </span>  </div>
                <div>  </div>
            </div>


            <div className="single-trip-single-row-major">
                <i style={{ color: colors[props.data.id % colorCount] }} className="icon-maps-place  text-green icon-big" />
                <span className="report-single-trip-summary-srcdest"> {processAddress(props.data.end_address)}</span>
                <div style={{ color: `${colors[props.data.id % colorCount]}`, background: `${colors[props.data.id % colorCount]}1A` }} className="trip-srcdest-time"> {getTimeFromUtcDate(props.data.end_time)}</div>
            </div>

        </div>
    )
}

function SingleItemTripDetails(props) {
    var tempClass = "dashboard-item-data " + props.color;
    return (
        <div className="report-single-item-container">
            <div className="dashboard-item-title">
                {props.name}
            </div>

            <div className={tempClass}>
                <b> {props.value} <i className={props.icon} /> </b>
            </div>
        </div>
    )
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setReplayndex, setReplayStateInTripsFromTripDetails, selectTripId, getSingleResourceTrips, setLoadingSpinnerState, getReversedAddressFromLatLng }, dispatch);
}

function mapStateToProps({ selectedReplayIndex, selectedTime, tripCount, selectedDate, selectedResourceId, ownedResources, selectedTripId, tripsSingleResource, reverseGeocodedAddress, modalState }) {
    return { selectedReplayIndex, selectedTime, tripCount, selectedDate, selectedResourceId, ownedResources, selectedTripId, tripsSingleResource, reverseGeocodedAddress, modalState };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTripDetails);
