import React, { Component } from 'react';
import MapHome from '../maps/map_home';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ButtonExitTracker from '../buttons/button_exit_tracker';
import * as ROUTES from '../../assets/constants/routes';
import { setTopbarTitle, setLoadingSpinnerState, setModalStateVehicleDetails } from '../../redux/actions/actions_misc';
import { clearSearchResources, seTrackedResourceId } from '../../redux/actions/actions_resources';


import ModalVehicle from '../modals/modal_vehicle_details';


class HomePage extends Component {



	componentWillMount() {
		this.props.setTopbarTitle("DashBoard");
		this.props.seTrackedResourceId(null);
		this.props.clearSearchResources();
		this.props.setModalStateVehicleDetails(false);
	}

	componentDidMount() {
		if (!this.props.jwtToken) { //he is not logged in
			this.props.setLoadingSpinnerState(false, "Loading ...");
			this.props.history.push(ROUTES.SIGN_IN);
		} else {
			if (this.props.isTokenValid) { //token is valid
				this.props.setLoadingSpinnerState(false, "Loading ...");
			} else {
				this.props.history.push(ROUTES.SIGN_IN);
			}
		}
	}



	render() {

		var visibilityStatusTrackingButton = false;

		if (this.props.trackedResourceId && !this.props.modalStateVehicleDetails) visibilityStatusTrackingButton = true;

		return (
			<div className="home-page-container">

				{/* <div className="home-page-topbar">
					<SearchBar location="home" />
					{this.props.trackedResourceId ? <div></div> : <SearchResultList />}
				</div> */}
				<div className="home-page-mainbar">

					<MapHome />
					{this.props.modalStateVehicleDetails ? <ModalVehicle /> : <div></div>}
				</div>
				<ButtonExitTracker isVisible={visibilityStatusTrackingButton} />
			</div>
		)
	}
}

function mapStateToProps({ modalStateVehicleDetails ,trackedResourceId, jwtToken, isTokenValid }) {
	return { modalStateVehicleDetails ,trackedResourceId, jwtToken, isTokenValid }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setTopbarTitle, clearSearchResources, setLoadingSpinnerState, setModalStateVehicleDetails, seTrackedResourceId }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
