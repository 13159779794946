import {
    SET_REPLAY_INDEX,
    SET_TIME,
    SET_TOPBAR_TITLE,
    SELECT_TRIP_COUNT,
    SET_SPINNER_STATE,
    SEARCH_TEXT,
    SET_MODAL_SHARELOCATION_STATE,
    SET_MODAL_ALARM_REPLAY_STATE,
    SET_MODAL_VEHICLE_STATE,
    SELECT_DATE,
    SET_MODAL_MAINTENANCE_ENTRY_STATE,
    SET_MAINTENANCE_LOG_VIEW_MODAL_STATE,
    SET_VEHICLE_PAPER_VIEW_MODAL_STATE,
    SET_MODAL_REFUEL_ENTRY_STATE,
    SET_MODAL_TREND_VIEW_STATE,
    SELECT_DATE_INTERVAL,
    SET_MODAL_ISSUE_ENTRY_STATE,
    GET_REVERSE_GEOCODE_ADDRESS,
    SET_RESOURCE_ID_FOR_DETAILS_VIEW_MAP,
    GET_PATH_FROM_SRC_DEST,
    SET_VISIBILITY_TO_RESOURCE_PATH,
    GET_NEARBY_FUEL_STATIONS,
    SET_TRIP_REPLAY_STATE ,
    SET_SHARABLE_LOCATION_URL ,
    SET_MODAL_STATE_RADIUS_ALARM_SET

} from '../../assets/constants/action_types';




const selectedReplayIndex = (state = -1, action) => {
    switch (action.type) {
        case SET_REPLAY_INDEX:
            return action.payload;
        default:
            return state;
    }
}
const selectedDate = (state = { date: null }, action) => {
    switch (action.type) {
        case SELECT_DATE:
            return action.payload;
        default:
            return state;
    }
}
const selectedTime = (state = null, action) => {
    switch (action.type) {
        case SET_TIME:
            console.log(action.payload);
            return action.payload;
        default:
            return state;
    }
}


const tripCount = (state = 5, action) => {
    switch (action.type) {
        case SELECT_TRIP_COUNT:
            return action.payload;
        default:
            return state;
    }
}


const topbarTitle = (state = "DashBoard", action) => {
    switch (action.type) {
        case SET_TOPBAR_TITLE:
            return action.payload;
        default:
            return state;
    }
}
const sharableLocationUrl = (state = "", action) => {
    switch (action.type) {
        case SET_SHARABLE_LOCATION_URL:
            return action.payload;
        default:
            return state;
    }
}


const selectedInterval = (state = 7, action) => {
    switch (action.type) {
        case SELECT_DATE_INTERVAL:
            return action.payload;
        default:
            return state;
    }
}


const spinnerState = (state = {
    state: false,
    message: "Please wait ...."
}, action) => {
    switch (action.type) {
        case SET_SPINNER_STATE:
            return action.payload;
        default:
            return state;
    }
}

const modalStateVehicleDetails = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_VEHICLE_STATE:
            return action.payload;
        default:
            return state
    }
};

const modalStateRadiusAlarmSet = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_STATE_RADIUS_ALARM_SET:
            return action.payload;
        default:
            return state
    }
};

const alarmReplayState = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_ALARM_REPLAY_STATE:
            return action.payload;
        default:
            return state
    }
};

const maintenanceEntryModalState = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_MAINTENANCE_ENTRY_STATE:
            return action.payload;
        default:
            return state
    }
};

const maintenanceLogViewState = (state = false, action) => {
    switch (action.type) {
        case SET_MAINTENANCE_LOG_VIEW_MODAL_STATE:
            return action.payload;
        default:
            return state
    }
};

const paperDetailsViewState = (state = false, action) => {
    switch (action.type) {
        case SET_VEHICLE_PAPER_VIEW_MODAL_STATE:
            return action.payload;
        default:
            return state
    }
};

const refuelEntryModal = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_REFUEL_ENTRY_STATE:
            return action.payload;
        default:
            return state
    }
};

const trendViewModal = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_TREND_VIEW_STATE:
            return action.payload;
        default:
            return state
    }
};

const issueEntryModal = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_ISSUE_ENTRY_STATE:
            return action.payload;
        default:
            return state
    }
};

const modalStateShareLocation = (state = false, action) => {
    switch (action.type) {
        case SET_MODAL_SHARELOCATION_STATE:
            return action.payload;
        default:
            return state
    }
};









const reverseGeocodedAddress = (state = "", action) => {
    switch (action.type) {
        case GET_REVERSE_GEOCODE_ADDRESS:
            return action.payload.result.address
        default:
            return state;
    }
}
const nearbyFuelStations = (state = [], action) => {
    switch (action.type) {
        case GET_NEARBY_FUEL_STATIONS:
            return action.payload.result
        default:
            return state;
    }
}
const searchFreeTextResult = (state = [], action) => {
    switch (action.type) {
        case SEARCH_TEXT:
            return action.payload.result
        default:
            return state;
    }
}

const navigationPath = (state = null, action) => {
    switch (action.type) {
        case GET_PATH_FROM_SRC_DEST:
            return action.payload;
        default:
            return state;
    }
};

const resourceIdForDetailsInMap = (state = null, action) => {
    switch (action.type) {
        case SET_RESOURCE_ID_FOR_DETAILS_VIEW_MAP:
            return action.payload
        default:
            return state;
    }
}
const tripReplayState = (state = false, action) => {
    switch (action.type) {
        case SET_TRIP_REPLAY_STATE:
            return action.payload
        default:
            return state;
    }
}


const visStatePathToResource = (state = false, action) => {
    switch (action.type) {
        case SET_VISIBILITY_TO_RESOURCE_PATH:
            return action.payload;
        default:
            return state;
    }
}



export {
    topbarTitle,
    selectedTime,
    tripCount,
    selectedDate,
    spinnerState,
    searchFreeTextResult,
    nearbyFuelStations,
    selectedInterval,
    reverseGeocodedAddress,
    resourceIdForDetailsInMap,
    navigationPath,
    visStatePathToResource,
    tripReplayState,


    modalStateVehicleDetails ,
    modalStateRadiusAlarmSet
    , alarmReplayState
    , maintenanceEntryModalState
    , maintenanceLogViewState
    , paperDetailsViewState
    , refuelEntryModal
    , trendViewModal
    , issueEntryModal
    , modalStateShareLocation ,
    sharableLocationUrl ,
    selectedReplayIndex
};