import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SupportlTable from '../tables/table_support';
import { setTopbarTitle , setModalStateIssueEntry } from '../../redux/actions/actions_misc';
class SupportPage extends Component {

    componentDidMount(){
        this.props.setTopbarTitle("Support");
    }

    render() {
        return (

            <div className="support-page-container">
                <div className="support-page-topbar">
                    <div></div>
                    <div></div>
                    <div className="btn-icon-txt-blue-filled" onClick={() => this.props.setModalStateIssueEntry(true)}>
                        <i className="icon-content-add" />
                        <div> New Issue </div>
                    </div>
                </div>
                <div className="support-page-mainbar">
                    <SupportlTable />
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTopbarTitle , setModalStateIssueEntry  }, dispatch);
}


export default connect(null, mapDispatchToProps)(SupportPage);