import { putAuthDataAndReturnResponse, putDataAndReturnResponse, fetchAndReturnData, patchDataAndReturnResponseCorrectly } from '.';
import {
    GET_ALL_BILLS,
    SIGN_IN_USER,
    SIGN_OUT_USER,
    VERIFY_TOKEN,
    REFRESH_TOKEN,
    PAY_WITH_SSL,
    GET_USER_DETAILS,
    SET_USER_DETAILS,
    GET_ALL_SUPPORT_ENTRY,
    POST_SUPPORT_ENTRY,
    SAVE_CREDENTIALS,
SET_CREDENTIAL_REMEMBERED_STATE
} from '../../assets/constants/action_types';
import { store } from '../store';
import { BASE_URL } from '../../assets/constants/app_constants';



//auth
export function signInUser(data) {
    console.log('login data is  ', data);
    const url = `${BASE_URL}user/api/token/`;
    return putAuthDataAndReturnResponse(url, data, SIGN_IN_USER);
}

export function signOutUser() {
    return {
        type: SIGN_OUT_USER,
        payload: null
    };
}

export function verifyToken() {
    const url = `${BASE_URL}user/api/token/verify/`;
    const token = store.getState().jwtToken;
    const data = {
        token: token
    };
    return putAuthDataAndReturnResponse(url, data, VERIFY_TOKEN);
}

export function refreshToken() {
    const url = `${BASE_URL}user/api/token/refresh/`;
    const token = store.getState().jwtToken;
    const data = {
        token: token
    };
    return putAuthDataAndReturnResponse(url, data, REFRESH_TOKEN);
}


export function saveSignInCredentials(signInObject){
    return {
        payload : signInObject ,
        type: SAVE_CREDENTIALS
    }
}


export function setCredentialRememberState(state){
    return {
        payload : state ,
        type: SET_CREDENTIAL_REMEMBERED_STATE
    }
}



//billing

export function getAllBills() {
    const url = `${BASE_URL}billing/api/bill/`;
    return fetchAndReturnData(url, GET_ALL_BILLS);
}


export function createSslPayment(paymentData) {
    const url = `${BASE_URL}billing/createsslpayment/`;
    return putDataAndReturnResponse(url, paymentData, PAY_WITH_SSL);
}


//profile

export function getUserDetails() {
    const url = `${BASE_URL}user/api/user_details/`;
    return fetchAndReturnData(url, GET_USER_DETAILS);
}

export function updateUserDetails(id, data) {
    const url = `${BASE_URL}user/api/users/${id}/`;
    return patchDataAndReturnResponseCorrectly(url, data, SET_USER_DETAILS);
}


//report and support 

export function getAllIssues() {
    console.log('get all issues baby ');
    const url = `${BASE_URL}support/api/ticket/`;
    return fetchAndReturnData(url, GET_ALL_SUPPORT_ENTRY);
}

export function createIssueEntry(data) {
    console.log('entry of issue');
    const url = `${BASE_URL}support/api/ticket/`;
    return putDataAndReturnResponse(url, data, POST_SUPPORT_ENTRY);
}

